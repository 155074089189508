import { ModuleWithProviders, NgModule } from '@angular/core';
import {AttributeFamilyResource, AttributeMarketplaceResource, AttributeResource, AttributeValueResource, CarrierIconsResource,
CategoryResource, CityResource, CMSResource, CommercialEntityResource, CountryResource, CustomerTypeResource, CrossSellingProductResource,
LogisticResource, InscpxeeResource, OrderItemResource, OrderResource, PackageResource, PaymentIconsResource, PictoResource, BlockResource,
ProductMarketplaceStatusResource, ReviewerResource, RoleHierarchyResource, OrderManagerStatusResource, StatusResource, TabImageResource,
ManufacturerResource, MarketplaceResource, UpSellingProductResource, UserResource, WebsiteConfigurationResource, WysiwygResource, RoomResource,
IndicatorResource} from './';
import {EnumResource} from '@resources/enum.resource';

@NgModule()
export class ResourceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ResourceModule,
      providers: [
        AttributeResource,
        AttributeFamilyResource,
        AttributeMarketplaceResource,
        AttributeValueResource,
        CarrierIconsResource,
        CategoryResource,
        CityResource,
        CMSResource,
        CommercialEntityResource,
        CountryResource,
        CustomerTypeResource,
        EnumResource,
        CrossSellingProductResource,
        LogisticResource,
        InscpxeeResource,
        OrderItemResource,
        OrderResource,
        PackageResource,
        PaymentIconsResource,
        PictoResource,
        BlockResource,
        ProductMarketplaceStatusResource,
        ReviewerResource,
        RoleHierarchyResource,
        OrderManagerStatusResource,
        StatusResource,
        TabImageResource,
        ManufacturerResource,
        MarketplaceResource,
        UpSellingProductResource,
        UserResource,
        WebsiteConfigurationResource,
        WysiwygResource,
        IndicatorResource,
        RoomResource,
      ],
    };
  }
}
