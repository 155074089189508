import { Component, Inject, OnInit } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { CarrierFamilyResource, ICarrierFamily } from '@components/carrierFamily';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { SnackbarService } from '@components/snackbar';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { INPUT_NUMBER_PATTERN_DEC } from '@constants/form';
import { NumberHelper } from '@helpers/NumberHelper';
import { ShippingFeeResource } from '@components/shipping-fee/resources/shipping-fee.resource';
import { Subscription } from 'rxjs/Subscription';
import { ShippingFeeModel } from '@components/shipping-fee/models/shipping-fee.model';

@Component({
  selector: 'app-carrier-family-shipping-price',
  template: require('./carrier-family-shipping-price.component.html'),
  styles: [require('./carrier-family-shipping-price.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CarrierFamilyResource },
    ShippingFeeResource
  ],
})
export class CarrierFamilyShippingPriceComponent extends AbstractPageComponent implements OnInit {
  public form: FormGroup;

  public shippingFees: ShippingFeeModel[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private shippingFeeResource: ShippingFeeResource
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    const subscriber: Subscription = this.shippingFeeResource.cGet({ 'pagination': false }, { returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((shippingFees: ShippingFeeModel[]) => {
        subscriber.unsubscribe();

        this.shippingFees = shippingFees;

        const carrierFamilySubscriber: Subscription = this.resource.get(this.state.params.id)
          .takeUntil(this.destroyed$)
          .subscribe((carrierFamily: ICarrierFamily) => {
            carrierFamilySubscriber.unsubscribe();

            this.buildForm(carrierFamily);
          })
        ;
      })
    ;
  }

  public submit(event?: any): void {
    this.resource.update(this.state.params.id, this.prepareBody())
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

        if (event && event.redirect) {
          this.state.go(`${this.resource.routeName}.list`);

          return;
        }

        this.state.go(this.state.current, this.state.params, { reload: true });
      })
    ;
  }

  private buildForm(carrierFamily: ICarrierFamily): void {
    this.form = this.formBuilder.group({
      enableShippingPrice: [!!carrierFamily.shippingPrice],
      shippingPrice: [carrierFamily.shippingPrice, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      enableShippingFee: [!!carrierFamily.shippingFee],
      shippingFee: [carrierFamily.shippingFee]
    });
  }

  private prepareBody(): any {
    return {
      shippingPrice: this.form.value.enableShippingPrice ? NumberHelper.parseFloat(this.form.get('shippingPrice').value) : null,
      shippingFee: this.form.value.enableShippingFee ? this.form.get('shippingFee').value : null,
    };
  }
}
