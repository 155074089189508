import { ICMS, ICMSTranslations } from '@components/cms/models/cms.interface';
import { SessionHelper } from '@helpers/session.helper';
import {LOCALE_FR} from '@constants';

export class CMSListModel implements ICMS {
  '@id': string;
  id: string;
  translations: {
    [long_locale: string]: ICMSTranslations;
  };
  createdAt: string;
  updatedAt: string;
  name?: string;
  slug?: string;
  status?: string;
  hasTranslation: string;

  constructor(cms: ICMS) {
    this['@id'] = cms['@id'];
    this.id = cms.id;
    this.translations = cms.translations;
    this.createdAt = cms.createdAt;
    this.updatedAt = cms.updatedAt;
    this.name = this.getFallbackTranslation(cms, 'name').translation;
    this.slug = this.getFallbackTranslation(cms, 'slug').translation;
    this.status = cms.translations[SessionHelper.getLocale()]
      ? (cms.translations[SessionHelper.getLocale()].active ? 'PAGE.CMS.LIST.TABLE.CONTENT.ACTIVE' : 'PAGE.CMS.LIST.TABLE.CONTENT.INACTIVE')
      : 'PAGE.CMS.LIST.TABLE.CONTENT.INACTIVE';
    this.hasTranslation = this.getFallbackTranslation(cms, 'name').isFallback ? 'PAGE.CMS.LIST.TABLE.CONTENT.NO' : 'PAGE.CMS.LIST.TABLE.CONTENT.YES';
  }
  private getFallbackTranslation(cms: ICMS, type: string): {translation: string; isFallback: boolean} {
    const currentLocale = SessionHelper.getLocale();

    if (cms.translations[currentLocale]) {
      return {translation: cms.translations[currentLocale][type], isFallback: false};
    }

    if (cms.translations[LOCALE_FR]) {
      return {translation: cms.translations[LOCALE_FR][type], isFallback: true};
    }
    const firstExistingTranslation = Object.values(cms.translations)
      .find(translation => translation[type]);

    return firstExistingTranslation ? {translation: firstExistingTranslation[type], isFallback: true} : {translation: '', isFallback: false};
  }
}
