import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { DeliveryNoteItemModel } from '@components/delivery-note/models/delivery-note-item.model';

@Injectable()
@Resource({
    routeName: 'delivery_note_items',
    entryPoint: '/v2/delivery_note_items',
    translationKey: 'DELIVERY_NOTE_ITEMS',
    listModel: DeliveryNoteItemModel,
    formModel: DeliveryNoteItemModel,
    isHydra: true,
})
export class DeliveryNoteItemResource extends AbstractResource {
    constructor(
        http: HttpClient,
    ) {
        super(http);
    }
}
