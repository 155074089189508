import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { TabsModule } from 'ngx-bootstrap';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TaskManagerModule } from '@components';
import { RetailOrdersListComponent } from '@components/retail/orders/retail-orders-list.component';
import { RetailOrdersResultListComponent } from '@components/retail/orders/retail-orders-result-list.component';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {TooltipModule} from 'ngx-tooltip';

@NgModule({
  declarations: [
    RetailOrdersListComponent,
    RetailOrdersResultListComponent,
  ],
  entryComponents: [
    RetailOrdersListComponent,
    RetailOrdersResultListComponent,
  ],
  imports: [
    CommonModule,
    GenericModule,
    BsDatepickerModule.forRoot(),
    DropDownsModule,
    FormsModule,
    GenericFormModule,
    ReactiveFormsModule,
    SwitchModule,
    TabsModule.forRoot(),
    UploadModule,
    TaskManagerModule,
    DialogModule,
    TooltipModule,
  ],
  providers: [
  ],
  exports: [
  ]
})
export class RetailModule {}
