import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components/generic';
import { PipesModule } from '../../pipes/pipes.module';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { SharedModule } from '@progress/kendo-angular-dropdowns';
import { ResourceModule } from '@resources/resource.module';
import { ShippingFeeResource } from '@components/shipping-fee/resources/shipping-fee.resource';
import { ShippingFeeFormComponent } from '@components/shipping-fee/form/shipping-fee-form.component';
import { ShippingFeeListComponent } from '@components/shipping-fee/list/shipping-fee-list.component';

@NgModule({
  declarations: [
    ShippingFeeFormComponent,
    ShippingFeeListComponent
  ],
  entryComponents: [
    ShippingFeeFormComponent,
    ShippingFeeListComponent
  ],
  providers: [
    ShippingFeeResource
  ],
  imports: [
    CommonModule,
    GenericModule,
    DialogModule,
    SharedModule,
    WindowModule,
    GenericFormModule,
    PipesModule,
    ResourceModule
  ]
})
export class ShippingFeeModule {}
