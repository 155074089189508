import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { SessionHelper, CountryHelper, AttributeHelper, AttributeValueHelper } from '@helpers';
import { AbstractComponent } from '@components/generic/abstract.component';
import { ATTRIBUTE_UNIT, CODE_FR, LOCALE_FR, WHITE_COLOR_HEX } from '@constants';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { AttributeValueResource, AttributeFamilyResource, AttributeResource } from '@resources';
import { AuthService } from '@services';
import { IAttribute } from '@components/attribute/models/attribute.interface';
import { FormService } from '@services/form.service';

@Component({
  selector: 'app-attribute-form',
  template: require('./attribute-form.component.html'),
  providers: [AttributeHelper, AttributeValueHelper, FormService]
})
export class AttributeFormComponent extends AbstractComponent implements OnInit {

  public originalAttribute: any;
  public currentLocale: any;
  public locales: any;
  public applicationLocales: string[] = SessionHelper.get('APPLICATION_LOCALES');
  public availableLocalesForCurrentCountry: any;
  public attributeModel: any;
  public attributeUnitValues: any;
  public attributeForm: FormGroup;
  public attributeFamiliesForm: FormGroup;

  @Input() public attribute: any;
  @Input() public isEdit: string;

  @Output() public onSaveAttributeAction: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private attributeHelper: AttributeHelper,
    private attributeValueHelper: AttributeValueHelper,
    private attributeResource: AttributeResource,
    private attributeValueResource: AttributeValueResource,
    private attributeFamilyResource: AttributeFamilyResource,
    public fb: FormBuilder,
  ) {
    super($translate, authService, null, state);

    this.attributeForm = this.fb.group({
      id: [''],
      sortCode: ['', Validators.required],
      familyActive: [''],
      translations: this.fb.group({}),
    });
    this.attributeFamiliesForm = this.fb.group({
      attributeFamilies: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.currentLocale = SessionHelper.getLocale();
    this.availableLocalesForCurrentCountry = this.getAvailableLocales();
    this.attributeUnitValues = ATTRIBUTE_UNIT;
    this.attributeModel = this.initAttribute(this.applicationLocales, this.attribute); // remove attribute model

    if (!this.isEdit) {
      this.addNewAttributeFamily(this.applicationLocales);
    }
    this.originalAttribute = this.attribute;
  }

  /**
   * Inits the given attributes.
   */
  private initAttribute(locales: string[] = [], attribute: IAttribute): IAttribute {
    this.attributeForm.patchValue({ id: attribute.id });
    this.attributeForm.patchValue({ sortCode: attribute.sortCode });
    this.attributeForm.patchValue({ familyActive: attribute.familyActive });

    const translationsGroup = this.attributeForm.get(`translations`) as FormGroup;
    const formArray = this.attributeFamiliesForm.get('attributeFamilies') as FormArray;

    // # for each locales, add locale to attribute translations object
    locales.forEach((locale: any) => {
      let attributeTranslation: { [keys: string]: any } = {};

      if (attribute.translations && attribute.translations[locale]) {
        attributeTranslation = attribute.translations[locale];
      }

      translationsGroup.addControl(locale, this.fb.group({
        name: [{ value: attributeTranslation.name || '', disabled: this.isDefaultLocale(locale) }, Validators.required],
        toTranslate: [undefined !== attributeTranslation.toTranslate ? attributeTranslation.toTranslate : true],
        locale: [locale],
      })
      );
    });

    if (attribute.attributeFamilies) {
      // # for each attributeFamilies && for each locale, add locale to attributeFamilies[n].translations object
      attribute.attributeFamilies.forEach((attributeFamily: any, key: number) => {
        formArray.push(this.fb.group({
          id: [attributeFamily.id],
          translations: this.fb.group([]),
          value: [attributeFamily.value ? attributeFamily.value[0] : WHITE_COLOR_HEX, Validators.required],
        }));
        const group = formArray.at(key).get('translations') as FormGroup;

        locales.forEach((locale: any) => {
          let translation;
          if (attributeFamily.translations && attributeFamily.translations[locale]) {
            translation = attributeFamily.translations[locale];
            attributeFamily.translations[locale].locale = locale;
          } else {
            translation = {};
          }
          group.addControl(locale, this.fb.group({
            id: [ translation.id || ''],
            value: [{ value: translation.value || '', disabled: this.isDefaultLocale(locale) }],
            unit: [{ value: translation.unit, disabled: this.isDefaultLocale(locale) }],
            toTranslate: [undefined !== translation.toTranslate ? translation.toTranslate : true],
            locale: [locale]
          }));
        });
      });
    }

    return attribute;
  }

  /**
   * Gets available locales
   */
  public getAvailableLocales(): string[] {
    const countries = [SessionHelper.getCountry()];

    if (CODE_FR !== SessionHelper.getCountry().code && this.isEdit) {
      countries.unshift(CountryHelper.getCountryByCountryCode(CODE_FR));

      return CountryHelper.getLocales(countries);
    } else {
      return CountryHelper.getLocales(countries);
    }
  }

  /**
   * Adds attribute value.
   */
  public addNewAttributeFamily(locales: string[] = []): void {
    const formArray = this.attributeFamiliesForm.get('attributeFamilies') as FormArray;
    formArray.push(this.fb.group({
      translations: this.fb.group([]),
      value: [null]
    })
    );
    locales.forEach((locale: any) => {
      const group = formArray.at(formArray.controls.length - 1).get('translations') as FormGroup;
      group.addControl(locale, this.fb.group({
        value: [{ value: '', disabled: this.isDefaultLocale(locale) }],
        unit: [{ value: undefined, disabled: this.isDefaultLocale(locale) }],
        toTranslate: [true],
        locale: [locale]
      }));
    });
  }

  /**
   * Checks if form is valid and save it if so.
   */
  public isValid(locales: string[] = [], redirect: string): void {
    const attributeNames = locales.filter((locale: any) => {
      return this.attributeForm.value.translations[locale] &&
        this.attributeForm.value.translations[locale].name &&
        !this.isDefaultLocale(locale);
    });

    if (!attributeNames.length) {
      this.snackbar.alert(this.translate('PAGE.ATTRIBUTE.FORM.ALERTS.EMPTY.NAME'));

      return;
    }

    const newAttributeFamilies = this.attributeHelper.getNewAttributeFamilies(this.attributeFamiliesForm.getRawValue());
    newAttributeFamilies.map((attributeFamily: any) => {
      attributeFamily.translations[this.currentLocale].toTranslate = '' === attributeFamily.translations[this.currentLocale].value;
      this.attributeValueHelper.copyTranslationsFromLocale(attributeFamily, this.currentLocale);
    });

    const editedAttributeFamilies = this.attributeHelper
      .getEditedAttributeFamilies(this.attributeFamiliesForm.getRawValue(), this.originalAttribute);

    editedAttributeFamilies.map((attributeFamily) => {
      const originalAttributeFamilies = this.originalAttribute.attributeFamilies
        .find((item: any) => item.id === attributeFamily.id);
      this.attributeValueHelper.updateToTranslate(attributeFamily, originalAttributeFamilies);
    });

    // # Clean attribute
    this.attributeHelper.cleanObject(this.attributeForm.value);
    this.attributeHelper.updateToTranslate(this.attributeForm.value, this.originalAttribute);
    this.attributeHelper.copyTranslationsFromLocale(this.attributeForm.value, this.currentLocale);

    this.onSaveAttributeAction.emit({
      redirect: redirect,
      attribute: this.attributeForm.getRawValue(),
      newAttributeFamilies: newAttributeFamilies,
      editedAttributeFamilies: editedAttributeFamilies,
    });
  }

  /**
   * Deletes attribute value.
   */
  public deleteAttributeValue(attributeValueRowIndex: number): void {
    this.dialog.confirm(this.translate('PAGE.ATTRIBUTE.CONFIRM.VALUE.DELETE'))
      .then(() => {
        if (
          this.attributeModel.attributeValues[attributeValueRowIndex] &&
          this.attributeModel.attributeValues[attributeValueRowIndex][0] &&
          this.attributeModel.attributeValues[attributeValueRowIndex][0].id
        ) {
          this.attributeValueResource.remove(this.attributeModel.attributeValues[attributeValueRowIndex][0].id)
            .subscribe(() => {
              this.attributeModel.attributeFamilies.forEach((attributeFamily: any) => {
                if (
                  attributeFamily.attributeValues[0] &&
                  attributeFamily.attributeValues[0].id === this.attributeModel.attributeValues[attributeValueRowIndex][0].id
                ) {
                  this.attributeFamilyResource.remove(attributeFamily.id).subscribe();
                }
              });
              this.attributeModel.attributeValues.splice(attributeValueRowIndex, 1);
            });
        }
        const formArray = this.attributeFamiliesForm.get('attributeFamilies') as FormArray;

        formArray.removeAt(attributeValueRowIndex);
      });
  }

  public changeFamilyActive(): void {
    const checked = this.attributeForm.controls.familyActive.value;

    if (checked) {
      this.handleDialogForFamilyActive(checked, this.translate('PAGE.ATTRIBUTE.CONFIRM.GROUP'));

      return;
    }

    this.handleDialogForFamilyActive(checked, this.translate('PAGE.ATTRIBUTE.CONFIRM.UNGROUP'));
  }

  private handleDialogForFamilyActive(checked: boolean, message: string): void {
    let keepChecked = false;
    this.dialog.confirm(message)
      .then(() => {
        keepChecked = true;
      }).finally(() => {
      const originalValue = this.attribute.familyActive;
      keepChecked ? this.attribute.familyActive = checked : this.attribute.familyActive = !checked;
      this.attributeForm.controls.familyActive.setValue(this.attribute.familyActive);

      if (originalValue !== this.attribute.familyActive) {
        this.attributeResource.partialUpdate(this.attribute.id, {'familyActive': this.attribute.familyActive})
          .subscribe((res) => {}, (errorData) => {
            if (errorData.status === 400) {
              this.attribute.familyActive = !checked;
              this.attributeForm.controls.familyActive.setValue(this.attribute.familyActive);
            }
          }
        );
      }
    });
  }

  /**
   * Redirect to attribute family edition.
   */
  public editAttributeFamily(attributeValueRowIndex: number): void {
      if (
        this.attributeModel.attributeFamilies[attributeValueRowIndex] &&
        this.attributeModel.attributeFamilies[attributeValueRowIndex].id
      ) {
        this.state.go(`attribute-family.edit`, {
          id: this.attributeModel.attributeFamilies[attributeValueRowIndex].id
        }, {});
      }
  }

  /**
   * Disabled field for the locales at country FR.
   * If country FR is not current country.
   */
  public isDefaultLocale(locale: string): boolean {
    return LOCALE_FR === locale && CODE_FR !== SessionHelper.getCountry().code;
  }

  /**
   * Displays a popup to exit form.
   */
  public cancelAction(): void {
    this.dialog.confirm(this.translate('DIALOG.TEXT.DONT_SAVE'))
      .then(() => this.state.go('attribute.list'));
  }
}
