import { IHydraMember } from '@interfaces/hydra-resource.interface';
import * as moment from 'moment';
import { DATE_SHORT_FORMAT } from '@constants';

export class ContainerListModel {
  id: number;
  reference: string;
  containerNumber: string;
  date: string;
  sageIdentifier?: string;
  supplierLabel?: string;
  trackingLink?: string;
  eta?: string;
  etaDiff?: number;

  constructor(container: IHydraMember) {
    this.id = container.id;
    this.reference = container.reference;
    this.containerNumber = container.containerNumber;
    this.date = container.date ? moment(container.date).format(DATE_SHORT_FORMAT) : null;
    this.sageIdentifier = container.sageIdentifier;
    this.supplierLabel = container.supplier ? container.supplier.label : null;
    this.trackingLink = container.lastTracking ? this.createGoogleMapsUrl(container.lastTracking.latitude, container.lastTracking.longitude) : null;
    this.eta = container.lastTracking ? this.formatDate(container.lastTracking.eta) : null;
    this.etaDiff = container.lastTracking ? this.calculateDateDifference(container.estimatedTimeOfArrival, container.lastTracking.eta) : null;
  }
  private createGoogleMapsUrl(latitude: number, longitude: number): string {
    return `<a href="https://www.google.com/maps?q=${latitude},${longitude}&ll=${latitude},${longitude}&z=8" target="_blank"><i class="fa fa-ship"></i></a>`;
  }
  private formatDate(date: Date): string {
    return moment(date).format(DATE_SHORT_FORMAT);
  }

  private calculateDateDifference(dateEtaInitial: Date, dateEtaApi: Date): number {
    const diff = new Date(dateEtaApi).getTime() - new Date(dateEtaInitial).getTime();
    return Math.ceil(diff / (1000 * 60 * 60 * 24));
  }
}
