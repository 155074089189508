export default retailRoutes;

/** @ngInject */
function retailRoutes($stateProvider: any) {
  const commonStateData: {[key: string]: any} = {
    sidebarNav: 'retail',
    sidebarSubNav: 'orders',
    title: '',
    reloadChangeCountry: 'retail.orders.list',
  };

  $stateProvider
    .state('retail', {
      url: '/retail',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'PAGE.RETAIL.TITLE',
        stateName: 'retail.orders.list'
      },
      data: Object.assign(commonStateData, {
        showCountries: false
      })
    })
    .state('retail.order_list', {
      url: `/orders?
        page
        &search
        &indicatorFilters[]
        &countries[]
        &dateStart
        &dateEnd
        &marketplaces[]
        &warehouses[]
        &carriers[]
        &skus[]
        &skuParent
        &postcode
        &paymentType
        &hasComment
        &hasRefund
        &isSageExported
        &orderManagerStatuses[]
        &dateExportLogisticStart
        &dateExportLogisticEnd
        &dateExportSageStart
        &dateExportSageEnd
        &indicatorStatuses[]
        &collaborators
      `,
      views: {
        'page@layout': { template: '<app-retail-orders-list></app-retail-orders-list>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.RETAIL.ORDERS',
      })
    })
  ;
}
