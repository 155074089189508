export const INDEX_FOLLOW = 'index, follow';
export const NO_INDEX_FOLLOW = 'noindex, follow';
export const NO_INDEX_NO_FOLLOW = 'noindex, nofollow';
export const COMMERCIAL_OPERATION_CLASSICAL = {name: 'Classique', value: 'classical'};
export const COMMERCIAL_OPERATION_FLASH_SALE = {name: 'Ventes flash', value: 'flash sale'};
export const COMMERCIAL_OPERATION_SALE = {name: 'Soldes', value: 'sale'};
export const COMMERCIAL_OPERATION_BUNDLE = {name: 'Bundle', value: 'bundle'};
export const COMMERCIAL_OPERATION_CREDIT = {name: 'Avoir', value: 'credit'};
export const COMMERCIAL_OPERATION_NEW = {name: 'Nouveauté', value: 'new'};
export const COMMERCIAL_OPERATION_GOOD_DEALS = {name: 'Bons plans', value: 'good_deals'};
export const COMMERCIAL_OPERATION_RED_PRICE = {name: 'Prix rouge', value: 'red_price'};
export const BUSINESS_OBJECT_NORMAL = 'commercial-operation';
export const BUSINESS_OBJECT_BUNDLE = 'commercial-operation-bundle';
export const BUSINESS_OBJECT_CREDIT = 'commercial-operation-credit';
