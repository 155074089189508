import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import {AbstractResource} from '../resources/abstract.resource';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/',
  isHydra: true
})
export class EnumResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }


  getEnumCases(name: string): Observable<object> {
    return super.cGet(null, { entryPoint: '/v2/' + name, returnHydraMembers: true});
  }
}
