import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { IProductForm } from '@components/product/interfaces/product-form.interface';
import { AbstractComponent } from '@components/generic/abstract.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddressingResource } from '@resources/addressing.resource';
import { AddressingModel } from '@models/addressing.model';
import { MasterProductResource, MasterProductsWarehousesResource } from '@resources';
import { SessionHelper } from '@helpers';
import { SnackbarService } from '@components/snackbar';
import { HydraHelper } from '@helpers/HydraHelper';
import * as moment from 'moment';
import { DATE_FULL_FORMAT } from '@constants';
import { ArrivalReasonResource } from '@resources/arrival-reason.resource';
import { ArrivalResource } from '@resources/arrival.resource';
import { IWarehouses } from '@components/warehouses/models';

@Component({
  selector: 'app-product-logistic-form',
  template: require('./product-logistic-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ]
})
export class ProductLogisticFormComponent extends AbstractComponent implements OnInit {
  @Input() model: IProductForm;
  public addressingForm: FormGroup;
  public stockMovementForm: FormGroup;
  public addressings: AddressingModel[] = [];
  public stockMovements: any[] = [];
  public reasons: any[] = [];
  public savWarehouse: IWarehouses;
  public masterProductWarehouse: any;
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    resource: AbstractResource,
    private formBuilder: FormBuilder,
    private addressingResource: AddressingResource,
    private masterProductResource: MasterProductResource,
    private arrivalResource: ArrivalResource,
    private arrivalReasonResource: ArrivalReasonResource,
    private masterProductWarehouseResource: MasterProductsWarehousesResource
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.buildAddressingCreationForm();
    this.buildStockMovementForm();
    this.fetch();
  }

  buildAddressingCreationForm(): void {
    this.addressingForm = this.formBuilder.group({
      addressingBay: [null],
      addressingLane: [null],
      addressingLocation: [null],
      addressingLevel: [null],
      quantity: [null],
      addressingType: [null],
      stock: [null],
      reason: [null],
    });
  }

  buildStockMovementForm(): void {
    this.stockMovementForm = this.formBuilder.group({
      addressing: [null],
      quantity: [null],
      reason: [null],
    });
  }

  fetch(): void {
    this.savWarehouse = SessionHelper.getWarehouse('sav');

    this.addressingResource.getMany({
      byProduct: this.model.id
    }, {
      isHydra: true,
    }).subscribe((response: AddressingModel[]) => {
      this.addressings = response;
    });

    this.arrivalReasonResource.cGet({pagination: false}, {
      isHydra: true,
      returnHydraMembers: true,
      dontUseModel: true,
      blocking: false
    }).takeUntil(this.destroyed$).subscribe((response: any) => {
      this.reasons = response.map((reason: any): any => {
        return {
          iri: reason['@id'],
          reason: (reason.translations[this.currentLocale] || reason.translations['fr_FR']).reason,
        };
      });
    });

    this.masterProductWarehouseResource.cGet({
      masterProduct: this.model.masterProduct.id,
      warehouse: this.savWarehouse.id
    }, { isHydra: true, returnHydraMembers: true }).subscribe((response: any): void => {
      if (response[0]) {
        this.masterProductWarehouse = response[0];
        this.fetchStockMovements();
      } else {
        this.masterProductWarehouseResource.create({
          masterProduct: HydraHelper.buildIri(this.model.masterProduct.id, 'master_products'),
          warehouse: this.savWarehouse.id
        }).subscribe((mpw: any): void => {
          this.masterProductWarehouse = mpw;
          this.fetchStockMovements();
        });
      }
    });
  }

  fetchStockMovements(): void {
    this.arrivalResource.cGet({
      'masterProductsWarehouses.id': this.masterProductWarehouse.id,
      'exists[receivedQuantity]': true,
      'order[date]': 'desc',
      limit: 10
    }, { isHydra: true, returnHydraMembers: true }).subscribe((response: any): void => {
      this.stockMovements = response;
    });
  }

  deleteAddressing(addressingId: string): void {
    this.addressingResource.remove(addressingId)
      .takeUntil(this.destroyed$)
      .subscribe((): void => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        setTimeout((): void => { this.state.go(this.state.current, this.state.params, { reload: true }); }, 150);
      })
    ;
  }

  submitAddressing(): void {
    const body: any = {
      addressingBay: this.addressingForm.get('addressingBay').value,
      addressingLane: this.addressingForm.get('addressingLane').value,
      addressingLocation: this.addressingForm.get('addressingLocation').value,
      addressingLevel: this.addressingForm.get('addressingLevel').value,
      addressingType: this.addressingForm.get('addressingType').value.value,
      masterProductsWarehouse: HydraHelper.buildIri(this.masterProductWarehouse.id, 'master_products_warehouses'),
    };

    this.addressingResource.create(body).subscribe((response: AddressingModel) => {
      this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
      setTimeout((): void => { this.state.go(this.state.current, this.state.params, { reload: true }); }, 150);
    });
  }

  submitStockMovement(): void {
    const body: any = {
      masterProductWarehouse: HydraHelper.buildIri(this.masterProductWarehouse.id, 'master_products_warehouses'),
      quantity: +this.stockMovementForm.value.quantity,
      date: moment().startOf('day').format(DATE_FULL_FORMAT),
      reason: this.stockMovementForm.value.reason,
      addressing: HydraHelper.buildIri(this.stockMovementForm.value.addressing, 'addressings'),
      shippedQuantity: +this.stockMovementForm.value.quantity,
      receivedQuantity: +this.stockMovementForm.value.quantity,
    };

    this.resource.create(body, { entryPoint: '/v2/arrival_stock_movement' })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        setTimeout((): void => { this.state.go(this.state.current, this.state.params, { reload: true }); }, 150);
      })
    ;
  }
}
