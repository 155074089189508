import { Component, Inject } from '@angular/core';
import { AbstractResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { ShippingFeeResource } from '@components/shipping-fee/resources/shipping-fee.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { ShippingFeeListFiltersService } from '@components/shipping-fee/list/shipping-fee-list-filters.service';

@Component({
  selector: 'shipping-fee-list',
  template: require('./shipping-fee-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ShippingFeeResource },
    { provide: AbstractFiltersFieldsService, useClass: ShippingFeeListFiltersService }
  ],
})
export class ShippingFeeListComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, resource, state);
  }
}
