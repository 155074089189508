import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { OrderResource } from '@resources/order.resource';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { INPUT_NUMBER_PATTERN_DEC } from '@constants/form';
import {
  CODE_AT,
  CODE_BE,
  CODE_DE,
  CODE_ES,
  CODE_FR,
  CODE_GB,
  CODE_IT,
  CODE_MC,
  CODE_NL,
  CODE_PT,
  CODE_PL
} from '@constants/country';
import { SessionHelper } from '@helpers';

@Component({
  selector: 'app-order-addresses',
  template: require('./order-addresses.component.html'),
  providers: [
    { provide: AbstractResource, useClass: OrderResource },
  ],
})
export class OrderAddressesComponent extends AbstractComponent implements OnInit, OnChanges {

  public billingForm: FormGroup;
  public shippingForm: FormGroup;
  public isShowroom: boolean = false;

  public allCountries: any;
  public countries: { label: string, value: string }[];
  public billingAddressSameAsShipping: boolean = false;
  public privateData: boolean = false;

  @Input() private billingAddress: any;
  @Input() private shippingAddress: any;
  @Input() private marketplace: string;
  @Input() public collaborator: any;
  @Output() public onUpdate: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.buildCountries();

    if (this.marketplace === 'b2b') {
      this.buildB2BCountries();
    }

    this.countries = [...this.allCountries[SessionHelper.getCountry().code]];
    if (this.state.params && (this.state.params.marketplace === 'showroom' || this.state.params.marketplace === 'alicedeals')) {
      this.isShowroom = true;
    }
    this.buildForms();
  }

  private buildCountries(): void {
    this.allCountries = {
      [CODE_FR]: [
        { label: this.translate('COUNTRIES.FRANCE'), value: CODE_FR },
        { label: this.translate('COUNTRIES.BELGIUM'), value: CODE_BE },
        { label: this.translate('COUNTRIES.MONACO'), value: CODE_MC },
        { label: this.translate('COUNTRIES.NETHERLANDS'), value: CODE_NL },
        { label: this.translate('COUNTRIES.SPAIN'), value: CODE_ES },
        { label: this.translate('COUNTRIES.UNITED_KINGDOM'), value: CODE_GB },
        { label: this.translate('COUNTRIES.PORTUGAL'), value: CODE_PT },
        { label: this.translate('COUNTRIES.ITALY'), value: CODE_IT },
        { label: this.translate('COUNTRIES.GERMANY'), value: CODE_DE },
        { label: this.translate('COUNTRIES.AUSTRIA'), value: CODE_AT },
        { label: this.translate('COUNTRIES.POLSKA'), value: CODE_PL },
      ],
      [CODE_ES]: [
        { label: this.translate('COUNTRIES.SPAIN'), value: CODE_ES },
      ],
      [CODE_BE]: [
        { label: this.translate('COUNTRIES.BELGIUM'), value: CODE_BE },
      ],
      [CODE_GB]: [
        { label: this.translate('COUNTRIES.UNITED_KINGDOM'), value: CODE_GB },
      ],
      [CODE_NL]: [
        { label: this.translate('COUNTRIES.NETHERLANDS'), value: CODE_NL },
      ],
      [CODE_PT]: [
        { label: this.translate('COUNTRIES.PORTUGAL'), value: CODE_PT },
      ],
      [CODE_IT]: [
        { label: this.translate('COUNTRIES.ITALY'), value: CODE_IT },
      ],
      [CODE_DE]: [
        { label: this.translate('COUNTRIES.GERMANY'), value: CODE_DE },
        { label: this.translate('COUNTRIES.AUSTRIA'), value: CODE_AT },
      ],
      [CODE_PL]: [
        { label: this.translate('COUNTRIES.POLSKA'), value: CODE_PL },
      ]
    };
  }

  private buildB2BCountries(): void {
    const countryCodes = [
      { label: 'COUNTRIES.FRANCE', value: CODE_FR },
      { label: 'COUNTRIES.BELGIUM', value: CODE_BE },
      { label: 'COUNTRIES.MONACO', value: CODE_MC },
      { label: 'COUNTRIES.NETHERLANDS', value: CODE_NL },
      { label: 'COUNTRIES.SPAIN', value: CODE_ES },
      { label: 'COUNTRIES.UNITED_KINGDOM', value: CODE_GB },
      { label: 'COUNTRIES.PORTUGAL', value: CODE_PT },
      { label: 'COUNTRIES.ITALY', value: CODE_IT },
      { label: 'COUNTRIES.GERMANY', value: CODE_DE },
      { label: 'COUNTRIES.AUSTRIA', value: CODE_AT },
      { label: 'COUNTRIES.POLSKA', value: CODE_PL }
    ];

    this.allCountries = [CODE_FR, CODE_ES, CODE_BE, CODE_GB, CODE_NL, CODE_PT, CODE_IT, CODE_DE, CODE_PL].reduce((acc: any, code) => {
      acc[code] = countryCodes.map(country => ({
        label: this.translate(`${country.label}`),
        value: country.value
      }));
      return acc;
    }, {});
  }

  public ngOnChanges() {
    this.buildForms();
  }

  private buildForms(): void {
    if (this.billingAddress && this.shippingAddress) {
      this.buildBillingForm(this.billingAddress);
      this.buildShippingForm(this.shippingAddress);
    } else {
      const emptyAddress: any = {
        lastName: null,
        firstName: null,
        company: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        postcode: null,
        city: null,
        province: null,
        countryCode: this.isShowroom ? CODE_FR : null,
        email: null,
        phone1: null,
        phone2: null,
        vatNumber: null
      };
      this.buildBillingForm(emptyAddress);
      this.buildShippingForm(emptyAddress);
    }
  }

  private buildBillingForm(billingAddress: any) {
    const billingAddressFormBody: any = {
      lastName: [billingAddress.lastName],
      firstName: [billingAddress.firstName],
      company: [billingAddress.company],
      addressLine1: [billingAddress.addressLine1, Validators.required],
      addressLine2: [billingAddress.addressLine2],
      addressLine3: [billingAddress.addressLine3],
      postcode: [billingAddress.postcode, Validators.required],
      city: [billingAddress.city, Validators.required],
      province: [billingAddress.province],
      countryCode: [billingAddress.countryCode, Validators.required],
      email: [billingAddress.email, Validators.required],
      phone1: [billingAddress.phone1, [Validators.required]],
      phone2: [billingAddress.phone2],
      vatNumber: [billingAddress.vatNumber],
    };

    this.billingForm = this.formBuilder.group(billingAddressFormBody);

    this.billingForm.valueChanges.subscribe((response: any) => {
      this.emitFormValue();
      this.copyBillingOnShipping();
    });
  }

  private buildShippingForm(shippingAddress: any) {
    const shippingAddressFormBody: any = {
      lastName: [shippingAddress.lastName],
      firstName: [shippingAddress.firstName],
      company: [shippingAddress.company],
      addressLine1: [shippingAddress.addressLine1, Validators.required],
      addressLine2: [shippingAddress.addressLine2],
      addressLine3: [shippingAddress.addressLine3],
      postcode: [shippingAddress.postcode, Validators.required],
      city: [shippingAddress.city, Validators.required],
      province: [shippingAddress.province],
      countryCode: [shippingAddress.countryCode, Validators.required],
      email: [shippingAddress.email, Validators.required],
      phone1: [shippingAddress.phone1, [Validators.required]],
      phone2: [shippingAddress.phone2],
      vatNumber: [shippingAddress.vatNumber],
    };

    this.shippingForm = this.formBuilder.group(shippingAddressFormBody);
    this.shippingForm.addControl('deliveryInstructions', new FormControl(null));

    this.shippingForm.valueChanges.subscribe((response: any) => {
      this.emitFormValue();
    });
  }

  public emitFormValue() {
    this.onUpdate.emit({
      billingAddress: this.billingForm.value,
      shippingAddress: this.shippingForm.value,
      privateData: this.privateData,
      collaborator: this.collaborator
    });
  }

  public onCheckSameAddress() {
    this.billingAddressSameAsShipping = !this.billingAddressSameAsShipping;
    this.copyBillingOnShipping();
  }

  private copyBillingOnShipping() {
    if (this.billingAddressSameAsShipping) {
      this.buildShippingForm(this.billingForm.value);
      this.emitFormValue();
    }
  }
}
