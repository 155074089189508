import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components/generic';
import { PipesModule } from '../../pipes/pipes.module';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { SharedModule } from '@progress/kendo-angular-dropdowns';
import { ResourceModule } from '@resources/resource.module';
import { DeliveryNoteFormComponent } from '@components/delivery-note/form/delivery-note-form.component';
import { DeliveryNoteListComponent } from '@components/delivery-note/list/delivery-note-list.component';
import { DeliveryNoteResource } from '@components/delivery-note/resources/delivery-note.resource';
import { TooltipModule } from 'ngx-tooltip';

@NgModule({
  declarations: [
    DeliveryNoteFormComponent,
    DeliveryNoteListComponent,
  ],
  entryComponents: [
    DeliveryNoteFormComponent,
    DeliveryNoteListComponent,
  ],
  providers: [
    DeliveryNoteResource,
  ],
  imports: [
    CommonModule,
    GenericModule,
    DialogModule,
    SharedModule,
    WindowModule,
    GenericFormModule,
    PipesModule,
    ResourceModule,
    TooltipModule
  ]
})
export class DeliveryNoteModule {}
