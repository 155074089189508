import {Component, Inject, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractComponent} from '@components/generic/abstract.component';
import {AbstractResource} from '@resources/abstract.resource';
import {AuthService} from '@services/auth.service';
import {TabStripComponent} from '@progress/kendo-angular-layout';
import {OrderManagerRelationRessource} from '@components/order-manager/relation/order-manager-relation.ressource';
import {Observable} from 'rxjs/Observable';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IRelation} from '@components/order-manager/relation/order-manager-relation.interface';
import { SnackbarService } from '@components';
import {DATE_FULL_FORMAT, DATE_SHORT_FORMAT} from '@constants';
import {HydraHelper} from '@helpers/HydraHelper';
import {isArray, isNumber, isObject, isString} from 'lodash';
import {MarketplaceHelper, SessionHelper} from '@helpers';
import {
  FORM_VALUES,
  SOLUTION,
  STEP1,
  STEP2,
  DEFAULT,
  STEP3
} from '@components/order-manager/relation/FormValues.constant';
import moment = require('moment');
import {takeUntil} from 'rxjs/operators';
import {OrderManagerRelationModel} from '@components/order-manager/relation/order-manager-relation-model';
import {forkJoin} from 'rxjs/observable/forkJoin';

@Component({
  selector: 'app-order-manager-relation-form',
  template: require('./order-manager-relation-form.component.html'),
  styles: [require('./order-manager-relation-form.component.scss')],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: AbstractResource, useClass: OrderManagerRelationRessource}
  ]
})

export class OrderManagerRelationFormComponent extends AbstractComponent implements OnInit {
  @ViewChild('tabstrip') public tabstrip: TabStripComponent;
  @Input() public order: any;
  public model: IRelation;
  public form: FormGroup;
  public skus: Observable<object>;
  public models: any = [];
  public problemsStep1: any;
  public problemsStep2: any;
  public problemsStep3: any;
  public solutions: any[] = [];
  public customer: any;
  public carrier: string;
  public setVariables: any = {
    0: [
      'claimDate',
      'order',
      'orderItems',
      'carrier',
      'originType'
    ],
    1: [
      'type',
    ],
    2: [
      'reason'
    ],
    3: [
      'problems',
      '?problem_detail',
    ],
    4: [
      '?attachments'
    ],
    5: [
      'comment'
    ],
    6: [
      'solution'
    ]
  };
  public isOpen: any = {0: false, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true};
  public isEditable: any[] = [];
  protected readonly SOLUTION = SOLUTION;
  protected readonly SessionHelper = SessionHelper;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    public resource: AbstractResource,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit() {
    this.resource.getMany({order: this.order.id}, {}).subscribe((data: IRelation[]) => {
      this.models = data;
      this.model = null;
      if (this.state.params.ticketId) {
        this.models.forEach((relation: any) => {
          if (relation.id === Number(this.state.params.ticketId)) {
            this.model = relation;
          }
        });
      }
      this.skus = this.order['items'].map((item: any) => {
        return {
          '@id': item['@id'],
          'sku': item['product']['sku'],
          'superProduct': item['product']['superProduct'],
          'carrier': item['carrier']
        };
      });
      this.updateForm();
      this.checkAllSteps();
    });
  }

  private buildForm(): void {
    let fillFirstPart: any[] = [];
    if (this.form && this.form.get('claimDate').value.length > 0) {
      fillFirstPart = [this.form.get('claimDate').value
        , this.form.get('orderItems').value
        , this.form.get('carrier').value
        , this.form.get('originType').value
        , this.form.get('litigation').value
        , this.form.get('zendeskID').value
        , this.form.get('marketplace').value];
    }

    this.form = this.formBuilder.group({
      claimDate: [this.model && this.model.claimDate ? moment(this.model.claimDate).format(DATE_SHORT_FORMAT) : null],
      litigation: [this.model ? this.model.litigation : false],
      type: [this.model ? this.model.type : null],
      originType: [this.model ? this.model.origins : !this.order.marketplace.startsWith('site') ? [{type: 'marketplace'}] : []],
      order: [this.model ? this.model.order : this.order.id],
      orderItems: [this.model ? this.model.orderItems : []],
      carrier: [this.model ? this.model.carrier : null],
      marketplace: [this.model ? this.model.marketplace : null],
      zendeskID: [this.order.zendeskTicketId],
      reason: [this.model ? this.model.reason : null],
      additionnalReasons: [null],
      problems: [null],
      fake: [null],
      customer: [this.model ? this.model.customer : this.order.customer.id],
      problem_detail: [null],
      problem_detail1: [null],
      problem_detail2: [null],
      problem_detail3: [null],
      step3_problem: [null],
      comment: [this.model ? this.model.comment : null],
      steps: [this.model ? this.model.steps : []],
      attachments: [this.model ? this.model.attachments : []],
      solution: [null],
      advice: [this.model ? this.model.advice : null],
      status: [this.model ? this.model.status : null],
      satisfactionType: [this.model ? this.model.satisfactionType : null],
    });
    if (this.form && fillFirstPart.length > 0) {
      ['claimDate'
        , 'orderItems'
        , 'carrier'
        , 'originType'
        , 'litigation'
        , 'zendeskID'
        , 'marketplace'].forEach((item, index) => {
        this.form.get(item).patchValue(fillFirstPart[index]);
      });
    }

  }

  public isNotEmptyNumber(currentStep: number, init = false) {
    return this.setVariables[currentStep].every((item: string) => {
      const isNullable = item.indexOf('?') !== -1;
      if (isNullable) {
        return true;
      }
      const currentItem = isNullable ? item.replace('?', '') : item;
      if (this.form.get(currentItem).value === undefined || this.form.get(currentItem).value === null ||
        this.form.get(currentItem).value.length === 0 || this.form.get(currentItem).value === '') {
        return false;
      }

      return true;
    });
  }

  public openNextStep(currentStep: number, init = false): void {
    const result = this.isNotEmptyNumber(currentStep, init);
    if (result) {
      if (init) {
        this.isOpen[currentStep] = true;
      }
      if (!init) {
        if (!this.isEditable.includes(currentStep)) {
          this.isEditable.push(currentStep);
        }
      }
      if (this.isOpen[currentStep + 1] && !(this.setVariables[currentStep].length === 1 && this.setVariables[currentStep][0].indexOf('?') !== -1)) {
        this.isOpen[currentStep + 1] = false;
        if (!init && this.isEditable.includes(currentStep)) {
          this.isOpen[currentStep] = true;
        }
        if (!init && !this.isEditable.includes(currentStep + 1)) {
          this.isEditable.push(currentStep + 1);
        }
        if (this.setVariables[currentStep + 1].length === 1 && this.setVariables[currentStep + 1][0].indexOf('?') !== -1) {
          this.isOpen[currentStep + 2] = false;
          if (!init && !this.isEditable.includes(currentStep + 2)) {
            this.isEditable.push(currentStep + 2);
          }
        }
      }
      return;
    }
  }
  public checkAllSteps(): void {
    for (let i = 0; i < Object.keys(this.isOpen).length; i++) {
      this.openNextStep(i, true);
    }
  }

  updateForm() {
    this.buildForm();
    this.fillForm();
    if (this.model) {
      this.updateProblems(this.form.get('reason').value, SOLUTION);
    }
  }

  private fillForm() {
    if (this.model) {
      if (this.model.origins.filter((origin: any) => origin['type'] === 'zendesk').length > 0) {
        this.form.get('zendeskID').patchValue(
          this.model.origins.filter((origin: any) => origin['type'] === 'zendesk')[0]['zendeskID']
        );
      }
    }
    let step1Value;
    let step3Value;
    if (this.model) {
      if (this.model.steps) {
        const step1 = this.model.steps.find((step: any) => step['step'] === STEP1);
        const step2 = this.model.steps.find((step: any) => step['step'] === STEP2);
        const step3 = this.model.steps.find((step: any) => step['step'] === STEP3);
        if (step1 && step1.stepValue && (this.form.get('reason').value === 'supplier' || this.form.get('reason').value === 'other')) {
          step1Value = step1.stepValue.split(',');
          step1Value = step1Value.map((item: any) => ({name: item}));
        } else if (step1 && step1.stepValue) {
          step1Value = [{name: step1.stepValue}];
        }
        if (step1) {
          this.form.get('problems').patchValue(step1Value);
          this.updateProblems(this.model.reason.toUpperCase(), STEP2);
        }
        if (step2) {
          const formattedStep2 = String(step2.stepValue).includes(',') ? step2.stepValue.split(',') : [step2.stepValue];
          Object.entries(this.form.controls).filter((item: any) => {
            return String(item[0]).startsWith('problem_detail');
          }).forEach((item: any, index: number) => {
            if (formattedStep2[index]) {
              item[1].patchValue(formattedStep2[index]);
            }
          });
          if (this.form.get('problems').value && this.form.get('problems').value.find((item: any) => item.name === 'FAKE_PROBLEM')) {
            this.form.get('problem_detail').patchValue([this.form.get('problem_detail').value]);
          }
          this.updateProblems(this.model.reason.toUpperCase(), STEP2);
        }
        if (step3) {
          step3Value = [{name: step3.stepValue}];
          this.form.get('step3_problem').patchValue(step3Value[0]);
        }
      }

      this.updateProblems((this.form.get('reason').value).toUpperCase(), STEP1);
      this.form.get('solution').patchValue(this.model.solution);
    }
  }


  isType(string: string) {
    if (this.form && this.form.get('originType') && this.form.get('originType').value) {
      this.form.get('originType').value.some((item: any) => {
        if (item.type === 'marketplace') {
          this.form.get('marketplace').setValue(this.order.marketplace);
          this.openNextStep(0);
        }
      });
      return this.form.get('originType').value.some((item: any) => item.type === string);
    }
    return false;
  }

  isProblem(string: string) {
    if (this.form && this.form.get('problems') && this.form.get('problems').value) {
      return this.form.get('problems').value.some((item: any) => item.name === string);
    }
    return false;
  }

  hasProblem() {
    return this.form.get('problems').value !== null && this.form.get('problems').value.some((item: any) => item.name !== 'FAKE_PROBLEM');
  }

  hasStep3() {
    return this.form.get('problem_detail').value === 'PACKAGE_RETURNED_ERROR';
  }

  private prepareBody(): {
    reason: any;
    litigation: any;
    marketplace: null;
    origins: any;
    type: any;
    claimDate: any;
    orderItems: any;
    carrier: any;
    order: string;
    customer: string;
    agents: any;
    status: string;
    satisfactionType: string;
    updatedAt: string;
    problem_detail: string,
    comment: string,
    steps: any,
    attachments: any,
    solution: any,
    advice: string,
  } {
    let myorigin = [];
    const agentsHistory: any[] = [];
    let ticketStatus: any;
    const steps: any[] = [];
    let currentSteps = [];
    if (this.model) {
      const array2 = this.form.get('originType').value.map((item: any) => item.type);
      const arrayIDS = this.form.get('originType').value.map((item: any) => item['type']);
      const array1 = this.model.origins.map((origin: any) => origin.type);
      myorigin = array2.filter((item: any) => !array1.includes(item));
      myorigin = myorigin.map((item: any) => {
        return {type: item};
      });
      myorigin.filter((item: any) => item.type === 'marketplace').forEach((item: any) => {
        item.marketplace = this.form.get('marketplace').value ? this.form.get('marketplace').value['code'] : null;
      });
      myorigin = myorigin.concat(this.model.origins.filter(e => arrayIDS.includes(e['type'])));
      ticketStatus = this.form.get('status').value ? this.form.get('status').value : 'opened';
      currentSteps = this.model.steps.map((item: any) => {
        return {'id': item.id, 'step': item.step};
      });
    } else {
      myorigin = this.form.get('originType').value;
      ticketStatus = 'opened';
    }
    if (this.form.get('problems').value && this.form.get('problems').value.length > 0) {
      const currentStep1Id = currentSteps.filter((item: any) => item.step === STEP1 ? item.id : null);
      let problemsValues;
      if (this.form.get('reason').value === 'supplier') {
        problemsValues = this.form.get('problems').value.map((item: any) => item.name);
        problemsValues = problemsValues.join(',');
      } else {
        problemsValues = this.form.get('problems').value[0].name;
      }
      currentStep1Id.length > 0 ?
        steps.push({'id': currentStep1Id[0].id, 'stepValue': problemsValues}) :
        steps.push({'step': STEP1, 'stepValue': problemsValues});
    }

    if (this.form.get('problem_detail').value !== null && this.form.get('problem_detail').value.length > 0) {
      const currentStep2Id = currentSteps.filter((item: any) => item.step === STEP2 ? item.id : null);
      let problemsDetailValues;
      if (this.form.get('reason').value === 'supplier' || this.form.get('reason').value === 'other') {
        problemsDetailValues = this.getSupplierProblemDetails();
      } else if (this.form.get('reason').value === 'customer' && isArray(this.form.get('problem_detail').value)) {
        problemsDetailValues = this.form.get('problem_detail').value[0];
      } else {
        problemsDetailValues = this.form.get('problem_detail').value;
      }
      const fakeproblem = steps.find(step => step.stepValue === 'FAKE_PROBLEM');
      if (fakeproblem) {
        currentStep2Id.length > 0 ?
          steps.push({'id': currentStep2Id[0].id, 'stepValue': problemsDetailValues, 'problem': true}) :
          steps.push({'step': STEP2, 'stepValue': problemsDetailValues, 'problem': true});

      } else {
        currentStep2Id.length > 0 ?
          steps.push({'id': currentStep2Id[0].id, 'stepValue': problemsDetailValues, 'problem': false}) :
          steps.push({'step': STEP2, 'stepValue': problemsDetailValues, 'problem': false});
      }
    }

    if (this.form.get('step3_problem').value !== null && this.form.get('step3_problem').value.length > 0) {
      const currentStep3Id = currentSteps.filter((item: any) => item.step === STEP3 ? item.id : null);

      currentStep3Id.length > 0 ?
        steps.push({'id': currentStep3Id[0].id, 'stepValue': this.form.get('step3_problem').value, 'problem': false}) :
        steps.push({'step': STEP3, 'stepValue': this.form.get('step3_problem').value, 'problem': false});
    }

    myorigin.filter((item: any) => item.type === 'marketplace').forEach((item: any) => {
      item.marketplace = this.form.get('marketplace').value ? this.form.get('marketplace').value['code'] : null;
    });
    myorigin.filter((item: any) => item.type === 'zendesk').forEach((item: any) => {
      item.zendeskID = this.form.get('zendeskID').value ? this.form.get('zendeskID').value : this.order.zendeskTicketId;
    });
    agentsHistory.push({'agent': SessionHelper.getUser().username, 'currentStatus': 'opened'});

    return {
      order: HydraHelper.buildIri(this.order.id, 'orders'),
      orderItems: this.form.get('orderItems').value.map((item: any) => item['@id']),
      carrier: this.form.get('carrier').value,
      type: this.form.get('type').value,
      litigation: this.form.get('litigation').value,
      origins: myorigin ? myorigin : [],
      claimDate: moment(this.form.get('claimDate').value, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT),
      reason: this.form.get('reason').value ? this.form.get('reason').value : null,
      marketplace: null,
      customer: HydraHelper.buildIri(this.order.customer.id, 'customers'),
      agents: agentsHistory ? agentsHistory : [],
      status: ticketStatus,
      satisfactionType: null,
      updatedAt: this.model ? this.model.updatedAt : null,
      problem_detail: this.model ? this.model.problem_detail : null,
      comment: this.form.get('comment').value,
      steps: steps ? steps : [],
      attachments: this.form.get('attachments').value,
      solution: this.form.get('solution').value ? this.form.get('solution').value : '',
      advice: this.form.get('advice').value
    };
  }

  protected async submit(event?: any, redirect= false) {
    let response: any;

    try {
      const body: IRelation = this.prepareBody();
      const addedAttachments = this.form.get('attachments').value.filter((file: any) => !file.hasOwnProperty('id'));
      delete body.attachments;
      body.attachments = this.model ? this.model.attachments : [];
      if (this.state.params.ticketId) {
        response = await this.updateResource(this.state.params.ticketId, body, addedAttachments);
      } else {
        response = await this.createResource(body, addedAttachments);
      }
      if (!redirect) {
        this.updateForm();
      }

    } catch (e) {
      console.error('Error:', e);
      response.reject(e);
      return response;
    }
    return response;

  }

  private async updateResource(id: string, customBody: IRelation, addedAttachments: any): Promise<any> {
    await this.uploadFiles(addedAttachments, id, customBody).then((body: IRelation) => {
      return new Promise((resolve, reject) => {
        this.resource.update(id, body)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (res: any) => {
              this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
              this.model = res;
              resolve(res);
            },
            (error: any) => reject(error)
          );
      });
    });
  }

  private async uploadFiles(attachments: any, waliticket: any, body: IRelation): Promise<any> {
    return new Promise((resolve, reject) => {
      if (attachments.length > 0) {
        const observables = attachments
          .filter((file: any) => file.hasOwnProperty('rawFile'))
          .map((file: any) => {
            const formData = new FormData();
            formData.append('waliticket', String(waliticket));
            formData.append('file', file.rawFile, file.name);
            formData.append('fileName', file.name);

            return this.resource.uploadFile(formData, {
              entryPoint: '/v2/waliticket_attachments',
              blocking: false
            });
          });

        forkJoin(observables).subscribe(
          (responses: any[]) => {
            responses.forEach((response, index) => {
              this.snackbar.validate('Fichier ' + attachments[index].name + ' ajouté avec succès');
              const result = this.form.get('attachments').value.find((a: any) => a.name === response.fileName);
              if (result) {
                result.id = response.id;
              }
              body.attachments = this.form.get('attachments').value;
              this.model.attachments = this.form.get('attachments').value;
            });
            resolve(body);
          },
          (error: any) => reject(error)
        );
      } else {
        resolve(body);
      }
    });
  }

  private async createResource(body: IRelation, addedAttachments: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.resource.create(body).pipe(takeUntil(this.destroyed$))
        .subscribe(
          async (response: any) => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.model = new OrderManagerRelationModel(response);
            await this.uploadFiles(addedAttachments, response.id, body);
            this.state.params.ticketId = response.id;
            resolve(response);
          },
          (error: any) => reject(error)
        );
    });
  }
  setModel(type: string) {
    if (!this.model && this.state.params.ticketId) {
      this.models.forEach((relation: any) => {
        if (relation.id === Number(this.state.params.ticketId)) {
          this.model = relation;
        }
      });
    } else {
      this.model = null;
    }
    this.updateForm();
    this.form.get('type').patchValue(type);
  }

  getVariable(step: number) {
    return this.setVariables[step].map(function (x: any) {
      return x.replace('?', '');
    });
  }

  isStringOrNumber(string: any, translation = false): any {
    if (isString(string) || isNumber(string)) {
      if (translation) {
        return this.getDataTranslation(string).toUpperCase();
      }
      return String(string).replace(/\//g, '.').toUpperCase();
    }
    if (isArray(string)) {
      if (string[0] && string[0].hasOwnProperty('typeOfFile')) {
        const mycounter =  string.map((item) => {
          return this.isStringOrNumber(item).toUpperCase();
        });
        const counts = mycounter.reduce((acc, value) => (acc[value] = (acc[value] || 0) + 1, acc), {});
        return Object.entries(counts).map(([value, count]) => `${count} ${value}(S)`).join(' / ');
      }
      return string.map((item) => this.isStringOrNumber(item).toUpperCase()).join(' / ');
    } else if (isObject(string)) {
      if (string.hasOwnProperty('typeOfFile') || string.hasOwnProperty('name')
        || string.hasOwnProperty('type') || string.hasOwnProperty('sku') || string.hasOwnProperty('stepValue')) {
        // @ts-ignore
        return string['typeOfFile'] || this.getDataTranslation(string['name'])
          // @ts-ignore
          || string['type'] || string['sku'] || this.getDataTranslation(string['stepValue']).toUpperCase();
      }
    }
  }

  toggleEdition(step: number) {
    if (this.isEditable.includes(step)) {
      this.isEditable.splice(this.isEditable.indexOf(step), 1);
      this.isOpen[step] = true;
      return;
    }
    this.isEditable.push(step);
    this.isOpen[step] = true;

    this.updateProblems(this.model.reason.toUpperCase(), STEP2);
  }

  isReason(string: string) {
    if (this.form && this.form.get('reason') && this.form.get('reason').value) {
      return this.form.get('reason').value === string;
    }
    return false;
  }

  updateProblems(reason: string, step: string, clearStep: boolean = false, problemDetail: any = null) {
    if (!reason) {
      return;
    }
    if (clearStep) {
      this.form.get('problems').patchValue([]);
      this.form.get('solution').patchValue(null);
      this.form.get('comment').patchValue('');

      Object.entries(this.form.controls).filter((item: any) => {
        return String(item[0]).startsWith('problem_detail');
      }).forEach((item: any) => {
        item[1].patchValue(null);
      });
    }
    const selectValues: any[] = [];
    const problems = FORM_VALUES.map((value: any) => {
      if (step === STEP1) {
        const stepValues = value.REASON[reason][step];
        for (const [index, key] of Object.keys(stepValues).entries()) {
          selectValues.push({id: index, name: key, label: this.translate('PAGE.RELATION.DATA.' + String(key).replace(/ /g, '_'))});
        }
      }
      if (step === STEP2) {
        this.form.get('step3_problem').patchValue(null);
        const problem = this.form.get('problems').value;
        if (reason === 'SUPPLIER') {
          if (!problem) {
            return [];
          }
          problem.forEach((problemValue: any) => {
              const choice = [];
              if (!value.REASON[reason][STEP1][problemValue.name][STEP2]) {
                return;
              }
              const stepValues = value.REASON[reason][STEP1][problemValue.name][STEP2];
              for (const [index, key] of Object.values(stepValues).entries()) {
                choice.push({id: index, name: key, problem: problemValue.name, label: this.translate('PAGE.RELATION.DATA.' + String(key).replace(/ /g, '_'))});
              }
              selectValues.push(choice);
            }
          );

          this.getSolutions(reason, problem, false, true);
        } else {
          if (problemDetail !== null) {
            this.getSolutions(reason, problem, false, false, problemDetail['name']);
          } else if (this.form.get('problem_detail').value) {
            this.getSolutions(reason, problem, false, false, this.form.get('problem_detail').value);
          }

          if (
            !problem
            || !problem[0]
            || !value.REASON[reason][STEP1][problem[0].name][STEP2]
            || value.REASON[reason][STEP1][problem[0].name][STEP2][DEFAULT]
          ) {
            if (problem) {
              this.getSolutions(reason, problem, true, false);
            }
            return;
          }

          const stepValues = value.REASON[reason][STEP1][problem[0].name][STEP2];

          for (const key of Object.keys(stepValues)) {
            selectValues.push({id: key, name: key, label: this.translate('PAGE.RELATION.DATA.' + String(key).replace(/ /g, '_'))});
          }
        }
      }

      if (step === STEP3) {
        const problem = this.form.get('problems').value;
        const problem_detail = this.form.get('problem_detail');
        const step3problem = this.form.get('step3_problem');
        // @ts-ignore
        if (problem_detail['_value']) {
          // @ts-ignore
          const stepValues = value.REASON[reason][STEP1][problem[0].name][STEP2][problem_detail['_value']][STEP3];

          for (const key of Object.keys(stepValues)) {
            selectValues.push({id: key, name: key, label: this.translate('PAGE.RELATION.DATA.' + String(key).replace(/ /g, '_'))});
          }

          // @ts-ignore
          this.getSolutions(reason, problem, false, false, problem_detail['_value'], step3problem['_value']);
        }
      }
      return selectValues;
    });
    if (step === STEP1) {
      this.problemsStep1 = problems[0] ? problems[0] : null;
      this.problemsStep2 = this.problemsStep3 = null;
    } else if (step === STEP2) {
      this.problemsStep2 = problems[0] ? problems[0] : null;
      this.problemsStep3 = null;
    } else {
      this.problemsStep3 = problems[0] ? problems[0] : null;
    }
  }

  public getSolutions(
    reason: any,
    problem: any,
    isDefault: boolean = false,
    isLitigation: boolean = false,
    problemDetail: any = null,
    problemStep3: any = null
  ): void {
    FORM_VALUES.map((va: any) => {
      if (problemDetail && problemStep3) {
        this.solutions = Object.entries(va.REASON[reason.toUpperCase()][STEP1][problem[0].name][STEP2][problemDetail][STEP3][problemStep3]);
      }
      if (problemDetail && !problemStep3) {
        this.solutions = Object.entries(va.REASON[reason.toUpperCase()][STEP1][problem[0].name][STEP2][problemDetail]);
      }
      if (isDefault) {
        this.solutions = Object.entries(va.REASON[reason.toUpperCase()][STEP1][problem[0].name][STEP2][DEFAULT][SOLUTION]);
      }

      if (isLitigation) {
        const litigation = this.form.get('litigation').value === true ? 'LITIGATION' : 'NOTLITIGATION';
        this.solutions = Object.entries(va.REASON[reason.toUpperCase()][SOLUTION][litigation]);
      }
    });
  }

  getControlName(string: string, index: number) {
    string = index === 0 ? string : string + index;
    return this.form.controls[string];
  }

  public setCarrier(): void {
    this.form.get('orderItems').value.map((item: any) => this.carrier = item['carrier']);
    this.form.get('carrier').setValue(this.carrier);
  }

  getSupplierProblemDetails(separator: string = ',') {
    let problemDetails = [];
    problemDetails.push(this.form.get('problem_detail').value);
    problemDetails.push(this.form.get('problem_detail1').value);
    problemDetails.push(this.form.get('problem_detail2').value);
    problemDetails.push(this.form.get('problem_detail3').value);
    problemDetails = [... new Set(problemDetails)];
    problemDetails = problemDetails.filter(element => element);
    if (separator === ' / ') {
      problemDetails = problemDetails.map((element: any) => {
        return this.getDataTranslation(element);
      });
    }
    return problemDetails.length > 1 ? problemDetails.join(separator) : problemDetails[0];
  }

  selectSolution(value: any) {
    if (value === 'advice') {
      return;
    }
    const resp = this.submit(null, true);
    resp.then(() => {
      switch (value) {
        case 'semirefund':
        case 'refund_shipping_fee':
        case 'refund_shipping_fee_delay':
          this.state.go('order_manager.payment', {
            id: this.order.id,
            refund: 'partial',
            reason: this.form.get('reason').value
          });
          break;

        case 'deduct_fee_refund':
          this.state.go('order_manager.comment', {
            id: this.order.id
          });
          break;

        case 'totalrefund':
        case 'refund_order':
          this.state.go('order_manager.payment', {
            id: this.order.id,
            refund: 'total',
            reason: this.form.get('reason').value
          });
          break;

        case 'gift':
        case 'resend_order':
        case 'resent_total':
        case 'resent_semi':
        case 'resent_address':
          this.state.go('order.new', { type: 'order' });
          break;

        case 'sparepart':
          this.state.go('sav.order_new', {
            id: this.order.id,
          });
          break;

        case 'empty_sav_order':
          this.state.go('sav.order_new');
          break;

        case 'avoir':
        case 'commercial_gesture':
        case 'ask_commercial_gesture':
          this.state.go('discount.new', { type: 'credit' });
          break;

        case 'quotation':
          this.state.go('order.new', { type: 'sav' });
          break;

        case 'create_tma':
          window.open(`https://alicegarden.atlassian.net/servicedesk/customer/portal/8`, '_blank');
          break;

        case 'update_contact_info':
        case 'update_phone':
        case 'update_address':
        case 'update_postcode':
        case 'update_order':
        case 'no_invoice':
          this.state.go('order_manager.edit', {
            id: this.order.id
          });
          break;

        case 'move_delivery':
        case 'update_carrier':
          this.state.go('order_manager.shipping', {
            id: this.order.id
          });
          break;

        case 'send_user_guide':
          const superProductId = this.form.get('orderItems').value.map((item: any) => {
            return item['superProduct'].split('/').pop();
          });
          this.state.go('super-product.edit.user-guide', {
            id: superProductId[0]
          });
          break;

        case 'sku_available':
          window.open(`https://docs.google.com/spreadsheets/d/1_JMF2rdajlX3JODZZ4x3p5g-2lreIYvBffbNUGFWd1g/edit?usp=sharing`, '_blank');
          break;

        case 'create_reverse':
          this.state.go('reverses.new', {
            order: this.order.id
          });
          break;

        case 'create_litigation':
          this.state.go('order_manager.litigation', {
            id: this.order.id
          });
          break;

        case 'send_mail':
        case 'send_invoice':
          this.state.go('customers.detail.mails', {
            id: this.order.customer.id
          });
          break;

        case 'resend_tracking':
          this.state.go('order_manager.shipping', {
            id: this.order.id
          });
          break;

        default:
          this.state.go('order_manager.relation', {
            id: this.order.id
          });

          break;
      }
    });
  }

  getDataTranslation(problem: any): string {
    if (problem) {
      return this.translate('PAGE.RELATION.DATA.' + problem.toUpperCase()).toUpperCase();
    }
    return null;
  }
}
