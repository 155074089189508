import { ICarrierGroup } from '@interfaces';
import {
  ICarrierFamily,
  ICarrierFamilyCost,
  ICarrierFamilyConfig,
  ICarrierFamilyTranslation,
  ICarrierFamilyMarketplace
} from '@components/carrierFamily/interfaces/carrier-family.interface';
import { IHydraMember } from '@interfaces/hydra-resource.interface';

export class CarrierFamilyModel implements ICarrierFamily {
  public available: boolean;
  public carriers: ICarrierFamilyConfig[];
  public etaMax: number;
  public etaMin: number;
  public untouchable: boolean;
  public mutualized: boolean;
  public assignablePrimary: boolean;
  public highlightProductPage: boolean;
  public familyCosts: ICarrierFamilyCost;
  public formattedCarriers: string;
  public id: string;
  public main: boolean;
  public position: number;
  public reference: string;
  public shippingPrice: number;
  public shippingFee?: string;
  public status: boolean;
  public translations: {[keys: string]: ICarrierFamilyTranslation};
  public marketplaces: {[keys: string]: ICarrierFamilyMarketplace};
  public carrierGroups: ICarrierGroup[];
  public deliveryDays: number[];
  public etaCutTime: string;
  public rse: boolean;

  constructor(carrierFamily: IHydraMember, familyCosts?: { [keys: string]: ICarrierFamilyCost }) {
    this.carriers = carrierFamily.carriers;
    this.etaMax = carrierFamily.etaMax;
    this.etaMin = carrierFamily.etaMin;
    this.untouchable = carrierFamily.untouchable;
    this.mutualized = carrierFamily.mutualized;
    this.assignablePrimary = carrierFamily.assignablePrimary;
    this.highlightProductPage = carrierFamily.highlightProductPage;
    this.carrierGroups = carrierFamily.carrierGroups || [];
    this.formattedCarriers = this.carrierGroups.map(function (carrierGroup: any) { return carrierGroup.name; }).join(' | ');
    this.id = carrierFamily.id;
    this.position = carrierFamily.position;
    this.reference = carrierFamily.reference;
    this.shippingPrice = carrierFamily.shippingPrice;
    this.shippingFee = carrierFamily.shippingFee;
    this.translations = carrierFamily.translations;
    this.marketplaces = carrierFamily.marketplaces;
    this.deliveryDays = carrierFamily.deliveryDays;
    this.etaCutTime = carrierFamily.etaCutTime;
    this.rse = carrierFamily.rse;

    const familyCost: any = familyCosts && <ICarrierFamilyCost>familyCosts[this.id];
    if (familyCost) {
      this.available = familyCost.available;
      this.familyCosts = familyCost;
      this.main = 'primary' === familyCost.familyType;
      this.status = familyCost.isFamilyActive;
    }
  }
}
