import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { IProductForm } from '@components/product/interfaces/product-form.interface';
import { SessionHelper } from '@helpers/session.helper';
import { Observable } from 'rxjs/Observable';
import { CarrierFamilyModel, CarrierFamilyResource, ICarrierFamily } from '@components/carrierFamily';
import { CarrierGroupResource } from '@resources/carrier-group.resource';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { IHydraMember } from '@interfaces/hydra-resource.interface';

@Component({
  selector: 'app-product-carrier-family-list',
  template: require('./product-carrier-family-list.component.html'),
  styles: [require('./product-carrier-family-list.component.scss')],
})
export class ProductCarrierFamilyListComponent extends AbstractFormComponent implements OnInit {

  @Input() public model: IProductForm;

  public carrierFamilies: ICarrierFamily[] = [];
  public carrierGroupCodes: string[] = [];
  public excludeCarrierGroupCodes: string[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    resource: AbstractResource,
    private carrierFamilyResource: CarrierFamilyResource,
    private carrierGroupResource: CarrierGroupResource,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.carrierGroupCodes = this.model.carriers.map((item) => item.code);
    this.excludeCarrierGroupCodes = this.model.excludedCarrierCodes.map((item) => item.code);
    this.getCarrierFamily();
  }

  private getCarrierFamily(): void {
    this.carrierFamilies = [];

    const observableList: Observable<any>[] = [];
    observableList.push(this.carrierFamilyResource.cGet({ 'country.code': SessionHelper.getCountry().code }, {dontUseModel: true}));
    observableList.push(this.carrierGroupResource.getFamilyCosts(`/api/v2/products/${this.state.params.id}`));

    forkJoin(observableList)
      .subscribe((response: any[]) => {
        if (!this.model.secondaryCarrierFamilies || !Array.isArray(this.model.secondaryCarrierFamilies)) {
          this.model.secondaryCarrierFamilies = [];
        }
        Object.values(response[0]['hydra:member']).forEach((carrierFamily: IHydraMember) => {
          const carrierFamilyModel = new CarrierFamilyModel(carrierFamily, response[1]);
          this.carrierFamilies.push(carrierFamilyModel);
          if (carrierFamilyModel.main) {
            this.model.primaryCarrierFamily = `/api/v2/carrier_families/${carrierFamilyModel.id}`;
          } else if (
            carrierFamilyModel.status &&
            !this.model.secondaryCarrierFamilies.includes(`/api/v2/carrier_families/${carrierFamilyModel.id}`)
          ) {
            this.model.secondaryCarrierFamilies.push(`/api/v2/carrier_families/${carrierFamilyModel.id}`);
          }
        });
      }
    );
  }

  private toggleMainFamily(toggledCarrierFamily: ICarrierFamily): void {
    this.carrierFamilies.forEach((carrierFamily: ICarrierFamily) => {
      if (toggledCarrierFamily.id !== carrierFamily.id) {
        carrierFamily.main = false;
        if (carrierFamily.status && !this.model.secondaryCarrierFamilies.includes(`/api/v2/carrier_families/${carrierFamily.id}`)) {
          this.model.secondaryCarrierFamilies.push(`/api/v2/carrier_families/${carrierFamily.id}`);
        }
      } else {
        carrierFamily.main = true;
        this.model.primaryCarrierFamily = `/api/v2/carrier_families/${carrierFamily.id}`;
      }
    });

    if (this.model.secondaryCarrierFamilies.includes(this.model.primaryCarrierFamily)) {
      this.model.secondaryCarrierFamilies.splice(
        this.model.secondaryCarrierFamilies.indexOf(this.model.primaryCarrierFamily),
        1
      );
    }
  }

  private toggleFamilyStatus(clickedCarrierFamily: ICarrierFamily, index: number): void {
    const clickedCarrierFamilyIri = `/api/v2/carrier_families/${this.carrierFamilies[index].id}`;

    let productHasMainFamily: number = 0;
    this.carrierFamilies.forEach( (carrierFamily: ICarrierFamily) => {
        productHasMainFamily += +carrierFamily.main;
    });

    if (!productHasMainFamily) {
      this.carrierFamilies[index].main = true;
      this.model.primaryCarrierFamily = clickedCarrierFamilyIri;
      return;
    }

    if (clickedCarrierFamily.status) {
      this.model.secondaryCarrierFamilies.push(clickedCarrierFamilyIri);
      return;
    }

    if (!clickedCarrierFamily.status) {
      this.model.secondaryCarrierFamilies.splice(
        this.model.secondaryCarrierFamilies.indexOf(clickedCarrierFamilyIri),
        1
      );
      return;
    }
  }
}
