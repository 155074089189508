import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { FieldFactoryService } from '@components/generic/Form/dynamic/services/field-factory.service';
import { AbstractComponent } from '@components/generic/abstract.component';
import { IExportOptions } from '@interfaces';
import { AuthService, FormNotifierService } from '@services';
import { SessionHelper } from '@helpers/session.helper';
import {IImportOptions} from '@interfaces/ImportOptions.interface';
import {BASE_URL} from '@constants';
import { ObjectTrimmer } from './whitespace-fixer';
const trimmedObject = new ObjectTrimmer();

/**
 * DynamicFormComponent take a list of `AbstractFormFieldBase` in input then construct the form with the `FieldFactoryService`.
 * We must displaying different form style according to the `formType` option.
 *
 * Features:
 *  - Build the form according to the fields property,
 *  - Listen to the form to check if the form is dirty and notify the `formNotifier` that a form is in edition,
 *  - Notify the `formNotifier` than the form is resetting,
 *  - Notify the `formNotifier` than the form is destroy when it's destroyed itself,
 *  - Output `onFormSubmitted` event,
 *  - emit onExport event, TODO: see to deport it in `FilterListComponent`
 */
@Component({
  selector: 'app-dynamic-form',
  template: require('./dynamic-form.component.html'),
  styles: [require('./dynamic-form.component.scss')],
  providers: [FieldFactoryService]
})
export class DynamicFormComponent extends AbstractComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public formResetting: boolean;
  public baseUrl: string = BASE_URL;
  public showComplementaryFields: boolean = false;

  @Input() public fields: AbstractFormFieldBase<any>[] = [];
  @Input() public complementaryFields: AbstractFormFieldBase<any>[] = [];

  /**
   * Precises for which purpose the form stands and adapt the style accordingly. (ex: filters for generic list)
   */
  @Input() public formType: string;
  @Input() public exportOptions: IExportOptions[];
  @Input() public importOptions: IImportOptions[];
  @Input() public extraOptions: any;
  @Input() public exportHeaders: any;
  @Input() public submitLabel: string;
  @Input() public autoDisplayingComplementaryFields = true;

  @Output() public export: EventEmitter<any> = new EventEmitter();
  @Output() public formSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() public formCancelled: EventEmitter<any> = new EventEmitter();
  @Output() public importSuccessful: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formNotifier: FormNotifierService,
    private fieldFactory: FieldFactoryService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    // Init the formGroup
    this.form = this.fieldFactory.toFormGroup(this.fields.concat(this.complementaryFields));
    this.showComplementaryFields = this.complementaryFields
      .filter((field: AbstractFormFieldBase<any>) => !!field.value)
      .length > 0
    ;

    if (this.autoDisplayingComplementaryFields === false) {
      this.showComplementaryFields = false;
    }

    this.form.statusChanges.subscribe(() => {
      if (this.form.dirty) {
        this.formNotifier.notifyFormInEdition();
      }
    });
  }

  ngOnDestroy(): void {
    this.formNotifier.notifyFormIsDestroyed();
  }

  /**
   * TODO: to refactor and deport in `FilterListComponent`
   * Emits event to export
   * Emitted event is an object containing the form value (so a json) or false (in the case where options.filter is false)
   * and needed options to export of type IExportOptions
   */
  private exportData(option: IExportOptions): void {
    this.export.emit({ form: option.filters && this.form.value, exportOptions: option, headers: this.exportHeaders });
  }

  private submitForm(): void {
    this.formSubmitted.emit(trimmedObject.trimObjectValues(this.form.value));
  }

  private resetForm(): void {
    this.formResetting = false;

    if (this.state.current.name) {
      SessionHelper.removeFiltersForPage(this.state.current.name.split('.')[0]);
    }

    Object.entries(this.form.controls).forEach(([filter, control]) => control.patchValue(undefined));
    this.formResetting = true;
    this.formNotifier.notifyFormResetting();
  }

  /**
   * Resets editable fields
   *
   * @emits onFormCancelled - to return in readonly mode
   */
  private resetEditableFields(): void {
    this.formCancelled.emit();
  }

  onImportSuccess(event: any): void {
    this.importSuccessful.emit(event);
  }
}
