import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {WarehousesModel} from '@components/warehouses/models';

@Injectable()
@Resource({
  entryPoint: '/v2/commercial_entities',
  isHydra: true,
})
export class CommercialEntityResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getWarehousesByCommercialEntity(id: number): Observable<object> {
    return super.cGet(
      {
        pagination: false
      },
      {
        entryPoint: `${this.entryPoint}/${id}/warehouses/linked`,
        model: WarehousesModel,
        isHydra: true,
        returnHydraMembers: true,
      }
    );
  }
}
