import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/transactions_order_items',
})
export class TransactionsOrderItemsResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

  public createTransactionOrderItem(body: object): Observable<Object> {
    return this.create(body, {entryPoint: `${this.entryPoint}`});
  }
}
