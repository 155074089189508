import { Component, Inject, OnInit } from '@angular/core';
import { AbstractResource, CountryResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { SnackbarService } from '@components/snackbar';
import { CREATION_PAGE, EDITION_PAGE } from '@interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { ShippingFeeResource } from '@components/shipping-fee/resources/shipping-fee.resource';
import { ShippingFeeModel } from '@components/shipping-fee/models/shipping-fee.model';
import { INPUT_NUMBER_PATTERN_DEC } from '@constants';
import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { Observable } from 'rxjs/Observable';
import { from } from 'rxjs/observable/from';
import { catchError, concatMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

@Component({
  selector: 'shipping-fee-form',
  template: require('./shipping-fee-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ShippingFeeResource },
    CountryResource
  ],
})
export class ShippingFeeFormComponent extends AbstractPageComponent implements OnInit {
  public shippingFee: ShippingFeeModel;
  public countries: IHydraMember[] = [];

  public form: FormGroup;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private countryResource: CountryResource
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    const countrySubscriber: Subscription = this.countryResource.cGet({ 'pagination': false, 'active': true }, { returnHydraMembers: true, dontUseModel: true })
      .takeUntil(this.destroyed$)
      .subscribe((countries: IHydraMember[]): void => {
        countrySubscriber.unsubscribe();

        this.countries = countries;

        if (EDITION_PAGE === this.pageType) {
          const subscriber: Subscription = this.resource.get(this.state.params.id)
            .takeUntil(this.destroyed$)
            .subscribe((shippingFee: ShippingFeeModel): void => {
              subscriber.unsubscribe();

              this.shippingFee = shippingFee;

              this.buildForm();
            })
          ;
        } else if (CREATION_PAGE === this.pageType) {
          this.shippingFee = new ShippingFeeModel({
            '@id': null,
            '@type': 'ShippingFee'
          });

          this.buildForm();
        }
      })
    ;
  }

  public submit(): void {
    const body = this.prepareBody();

    const upsert: Observable<any> = EDITION_PAGE === this.pageType
      ? this.resource.update(this.shippingFee.id, body)
      : this.resource.create(body)
    ;

    const subscriber: Subscription = upsert
      .takeUntil(this.destroyed$)
      .subscribe((shippingFee: ShippingFeeModel) => {
        subscriber.unsubscribe();

        const observables$: Observable<any>[] = [];

        for (const [id, form] of Object.entries(this.form.value.countries)) {
          const countryBody: any = { 'postalCodeWithCarrierSurcharge': (form as FormGroup).value.postalCodeWithCarrierSurcharge };

          if (true === (form as FormGroup).value.active) {
            countryBody['shippingFee'] = this.shippingFee['@id'];
          } else {
            const country: IHydraMember = this.countries.find((model: any) => Number(id) === (model.id as number));

            if (country && country.shippingFee && country.shippingFee['@id'] === this.shippingFee['@id']) {
              countryBody['shippingFee'] = null;
            }
          }

          observables$.push(this.countryResource.update(id, countryBody, { cleanParams: false }).pipe(catchError((error) => of(error))));
        }

        from(observables$)
          .pipe(concatMap(obs => obs), takeUntil(this.destroyed$))
          .subscribe(
            () => {},
            () => {
              this.snackbar.alert(this.translate('ALERTS.ERROR.API'));
            },
            () => {
              if (EDITION_PAGE === this.pageType) {
                this.state.go(this.state.current, this.state.params, { reload: true });
              } else if (CREATION_PAGE === this.pageType) {
                this.state.go(`${this.resource.routeName}.edit`, { id: shippingFee.id }, { reload: true });
              }
            }
          )
        ;

        if (!observables$.length) {
          if (EDITION_PAGE === this.pageType) {
            this.state.go(this.state.current, this.state.params, { reload: true });
          } else if (CREATION_PAGE === this.pageType) {
            this.state.go(`${this.resource.routeName}.edit`, { id: shippingFee.id }, { reload: true });
          }
        }
      })
    ;
  }

  private buildForm(): void {
    const countries: any = {};

    for (const country of this.countries) {
      countries[country.id] = this.formBuilder.group({
        active: [(country.shippingFee ? country.shippingFee['@id'] : undefined) === this.shippingFee['@id']],
        postalCodeWithCarrierSurcharge: [country.postalCodeWithCarrierSurcharge]
      });
    }

    this.form = this.formBuilder.group({
      label: [this.shippingFee.label, Validators.required],
      exemptionLimit: [this.shippingFee.exemptionLimit, [Validators.required, Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.min(0)]],
      shippingPrice: [this.shippingFee.shippingPrice, [Validators.required, Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.min(0)]],
      active: [this.shippingFee.active],
      countries: countries
    });
  }

  private prepareBody(): any {
    return {
      label: this.form.value.label,
      exemptionLimit: this.form.value.exemptionLimit,
      shippingPrice: this.form.value.shippingPrice,
      active: this.form.value.active
    };
  }
}
