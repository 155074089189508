import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { TextField, MultiSearchField, DateField, ComboSearchField } from '@components/generic/Form/dynamic/fields';
import { ArrayHelper, SessionHelper } from '@helpers';
import { MarketplaceResource } from '@resources';
import * as moment from 'moment';
import { DATE_FULL_FORMAT } from '@constants';
import { FormNotifierService } from '@services';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { IMarketplace } from '@interfaces';
import {DropDownListField} from '@components/generic/Form/dynamic/fields/select-field.class';
import { setDates } from '@helpers/DateFilterHelper';

interface IDropdownData {
  label: string;
  value: string;
}

const ARCHIVING_STATUSES = {
  archived: '1',
  nonArchived: '0',
};

const STATE_STATUSES = {
  in_progress: 'in_progress',
  coming_up: 'coming_up',
  finished: 'finished',
};

@Injectable()
export class CommercialOperationsFiltersFormService extends AbstractFiltersFieldsService {

  /** Defined here to keep a reference to data in order to change it dynamically*/
  public marketplacesField: MultiSearchField = new MultiSearchField({
    fieldName: 'marketplace[]',
    label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.MARKETPLACES',
    data: null,
    textField: 'commercialName',
    valueField: 'code',
    value: null,
    order: 2
  });

  public startDateField: TextField = new TextField({
    fieldName: 'startDate',
    value: null,
    hidden: true
  });

  public endDateField: TextField = new TextField({
    fieldName: 'endDate',
    value: null,
    hidden: true
  });

  private archivingList: IDropdownData[] = [
    {
      label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.ARCHIVING_STATUS.DATA.NON_ARCHIVED'),
      value: ARCHIVING_STATUSES.nonArchived
    },
    {
      label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.ARCHIVING_STATUS.DATA.ARCHIVED'),
      value: ARCHIVING_STATUSES.archived
    },
  ];

  private statesList: IDropdownData[] = [
    {
      label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.STATE_STATUS.DATA.IN_PROGRESS'),
      value: STATE_STATUSES.in_progress,
    },
    {
      label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.STATE_STATUS.DATA.COMING_UP'),
      value: STATE_STATUSES.coming_up,
    },
    {
      label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.STATE_STATUS.DATA.FINISHED'),
      value: STATE_STATUSES.finished,
    },
  ];

  private typeList: IDropdownData[] = [
    { label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.TYPE.DATA.CLASSICAL'), value: 'classical' },
    { label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.TYPE.DATA.SALE'), value: 'sale' },
    { label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.TYPE.DATA.FLASH_SALE'), value: 'flash sale' },
    { label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.TYPE.DATA.BUNDLE'), value: 'bundle' },
    { label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.TYPE.DATA.CREDIT'), value: 'credit' },
    { label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.TYPE.DATA.GOOD_DEALS'), value: 'good_deals' },
    { label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.TYPE.DATA.NEW'), value: 'new' },
    { label: this.translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.TYPE.DATA.RED_PRICE'), value: 'red_price' },
  ];

  constructor(
    private marketplaceResource: MarketplaceResource,
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService
  ) {
    super(formNotifier, state);

    if (state.params.countries) {
      this.fetchMarketplaces((typeof state.params['country[]'] === 'string') ? [state.params['country[]']] : state.params['country[]']);
      return;
    }
    this.fetchMarketplaces(SessionHelper.getCountries().map((country) => country.code));
  }

  public fetchMarketplaces(countryCodes: string[]): void {
    countryCodes.forEach((countryCode: string) => {
      this.marketplaceResource.filterByCountryCode(countryCode)
        .subscribe((marketplaces: IMarketplace[]) => {
          this.marketplacesField.data = ArrayHelper.unique(this.marketplacesField.data.concat(marketplaces).sort((a: IMarketplace, b: IMarketplace) => {
            if (a.commercialName.toLowerCase() < b.commercialName.toLowerCase()) { return -1; }
            if (a.commercialName.toLowerCase() > b.commercialName.toLowerCase()) { return 1; }
            return 0;
          }));

          const itemsAvailable: any = [];
          const stateParams = typeof this.state.params['marketplace[]'] === 'string'
            ? [this.state.params['marketplace[]']]
            : this.state.params['marketplace[]'];
          marketplaces.forEach((marketplace: IMarketplace) => {
            if (stateParams && stateParams.includes(marketplace.code)) {
              itemsAvailable.push(marketplace.code);
            }
          });

          this.marketplacesField.value.push(...itemsAvailable);
        });
    });
  }

  public updateMarketplaces(newValues: any[]) {
    this.marketplacesField.data = [];
    this.marketplacesField.value = [];

    if (newValues.length) {
      this.fetchMarketplaces(newValues);
      return;
    }

    this.fetchMarketplaces(SessionHelper.getCountries().map((country) => country.code));
  }

  public getFields(): AbstractFormFieldBase<any>[] {
    this.marketplacesField.value = this.filters.getAll('marketplace[]').length !== 0 ?
      this.filters.getAll('marketplace[]') :
      []
    ;

    const startDate = this.filters.get('startDate');
    const endDate = this.filters.get('endDate');

    if (startDate && endDate) {
      this.startDateField.value = startDate;
      this.endDateField.value = endDate;
    }

    const fields: AbstractFormFieldBase<any>[] = [

      new MultiSearchField({
        fieldName: 'country[]',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.COUNTRIES',
        data: SessionHelper.getCountries(),
        textField: 'name',
        valueField: 'code',
        value: this.filters.getAll('country[]').length !== 0 ? this.filters.getAll('country[]') : undefined,
        valueChangedAction: this.updateMarketplaces.bind(this),
        order: 1
      }),

      new MultiSearchField({
        fieldName: 'translations.title',
        data: [],
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.KEYWORDS',
        value: this.filters.getAll('translations.title').length !== 0 ? this.filters.getAll('translations.title') : undefined,
        order: 4,
        allowCustom: true
      }),

      new DateField({
        fieldName: 'date',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.DATE',
        dateRange: true,
        value: this.filters.get('startDate') && this.filters.get('endDate') ? [new Date(startDate), new Date(endDate)] : undefined,
        valueChangedAction: setDates.bind(this),
        order: 3
      }),

      new ComboSearchField({
        fieldName: 'archived',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.ARCHIVING_STATUS.LABEL',
        data: this.archivingList,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('archived') ? this.filters.get('archived') : null,
        valuePrimitive: true,
        order: 5
      }),

      new ComboSearchField({
        fieldName: 'status',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.STATE_STATUS.LABEL',
        data: this.statesList,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('status') || null,
        valuePrimitive: true,
        order: 6
      }),

      new MultiSearchField({
        fieldName: 'id',
        data: [],
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.ID',
        value: this.filters.getAll('id').length !== 0 ? this.filters.getAll('id') : undefined,
        order: 7,
        allowCustom: true
      }),

      new MultiSearchField({
        fieldName: 'type[]',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.TYPE.LABEL',
        data: this.typeList,
        textField: 'label',
        valueField: 'value',
        value: this.filters.getAll('type[]').length !== 0 ? this.filters.getAll('type[]') : undefined,
        valuePrimitive: true,
        order: 8
      }),

      new DropDownListField({
        fieldName: 'hasParent',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.PARENT_AND_CHILDREN',
        data: [
          {label: this.translate.instant('BOOLEANS.YES'), value: 1},
          {label: this.translate.instant('BOOLEANS.NO'), value: 0},
        ],
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('hasParent') ? this.filters.get('hasParent') : null,
        defaultItem: {label: '', value: null},
        valuePrimitive: true,
        order: 9
      }),
      this.marketplacesField,
      this.startDateField,
      this.endDateField,
    ];
    return fields.sort((a, b) => a.order - b.order);
  }

}
