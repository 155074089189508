import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/transactions',
  cGetDefaultFilters: () => ({
    operation: 'debit',
    archived: false
  })
})
export class TransactionResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

  public getTransactionAmount(orderId: any, items: any): Observable<object> {
    return this.getMany({operation: 'credit', archived: false, order_orderId: orderId, items: items }, {
      entryPoint: `/v2/transactions/amount`,
      isHydra: false,
    });
  }

}
