import { IHydraMember } from '@interfaces/hydra-resource.interface';

export class ShippingFeeModel {
  '@id': string;
  id: number;
  label: string;
  exemptionLimit: number;
  shippingPrice: number;
  active: boolean;

  constructor(object: IHydraMember) {
    this['@id'] = object['@id'];
    this.id = object.id;
    this.label = object.label;
    this.exemptionLimit = object.exemptionLimit;
    this.shippingPrice = object.shippingPrice;
    this.active = object.active;
  }
}
