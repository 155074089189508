import { NgModule } from '@angular/core';
import { WarehousesResource } from './warehouses.resource';
import { WarehousesEditComponent } from './warehouses-edit.component';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components';
import { TabsModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { TooltipModule } from 'ngx-tooltip';
import { WarehouseListComponent } from '@components/warehouses/list/warehouse-list.component';
import { WarehouseCarrierGroupAgenciesResource } from '@components/warehouses/warehouse-carrier-group-agencies.resource';
import {DialogModule, WindowModule} from '@progress/kendo-angular-dialog';
import {EnhancedFieldsModule} from '@directives/enhanced-fields.module';

@NgModule({
  entryComponents: [
    WarehouseListComponent,
    WarehousesEditComponent,
  ],
  declarations: [
    WarehouseListComponent,
    WarehousesEditComponent,
  ],
  providers: [
    WarehousesResource,
    WarehouseCarrierGroupAgenciesResource,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GenericModule,
    GenericFormModule,
    TabsModule,
    SwitchModule,
    TooltipModule,
    DialogModule,
    WindowModule,
    EnhancedFieldsModule,
  ]
})

export class WarehousesModule {}
