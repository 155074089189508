import { DATE_FULL_FORMAT } from '@constants';
import moment = require('moment');

export function setDates(newValue: string) {
  if (newValue) {
    this.startDateField.value =
      null !== newValue[0] ? moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT) : undefined;

    this.endDateField.value =
      null !== newValue[0] ? moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT) : undefined;

    return;
  }

  this.startDateField.value = null;
  this.endDateField.value = null;
}
