import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { IExportOptions } from '@interfaces/exportOptions.interface';
import { AbstractResource } from '@resources/abstract.resource';
import { SnackbarService } from '@components/snackbar';
import { IImportOptions } from '@interfaces/ImportOptions.interface';

/**
 * Displays a form built by the `filtersFieldsService` and the `app-dynamic-form` component.
 * Handle the exports of a resource.
 *
 * The parent component must provide the `filtersFieldsService` that was injected here in a generic way.
 * The `filtersFieldsService` get the filters from the url or from the localstorage to populate the form fields.
 * When the `app-dynamic-form` component submit the form then we averts the `ListComponent` than a new request must be done
 * with new parameters.
 *
 * The listener (the list component) fetch the new filtered data from api then save the parameters in the storage and update the url.
 */
@Component({
  selector: 'app-filter-list',
  template: require('./filter-list.component.html'),
  styles: [require('./filter-list.component.scss')],
})
export class FilterListComponent extends AbstractComponent implements OnInit {

  public fields: AbstractFormFieldBase<any>[] = [];
  public complementaryFields: AbstractFormFieldBase<any>[] = [];
  @Input() public exportOptions: IExportOptions[];
  @Input() public importOptions: IImportOptions[];
  @Input() public extraOptions: any[];
  @Input() public autoDisplayingComplementaryFields = true;

  public exportHeaders: any;

  @Output() public onRefreshList: EventEmitter<any> = new EventEmitter();
  @Output() public importSuccessful: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    resource: AbstractResource,
    private filtersFieldsService: AbstractFiltersFieldsService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.fields = this.filtersFieldsService.getFields();
    this.complementaryFields = this.filtersFieldsService.getComplementaryFields();

    if (!this.exportOptions || this.exportOptions.length === 0) {
      this.exportOptions = this.resource.exportOptions;
    }

    if (!this.importOptions || this.importOptions.length === 0) {
      this.importOptions = this.resource.importOptions;
    }

    this.exportHeaders = this.resource.exportHeaders;
  }

  /**
   * Notify the `ListComponent` that we want to refresh the list.
   * (that way the list will fetch the new data and update url)
   *
   * @param {object} filters - a literal object returned by the form where keys are fields names from the form.
   */
  private refreshList(filters: {[key: string]: any} ): void {
    Object.entries(filters).forEach(([filter, value]: any) => {
      if ('' === value || null === value) {
        filters[filter] = undefined;
      }
    });

    this.onRefreshList.emit(filters);
  }

  /**
   * Export a file with filter form data, according to `exportOptions` defined in the resource.
   */
  public exportFile(options: { form: { [key: string]: any }, exportOptions: IExportOptions, headers?: any }): void {
    if (options.form) {
      Object.entries(options.form).forEach(([filter, value]: any) => {
        if ('' === value || null === value) {
          options.form[filter] = undefined;
        }
      });

      options.form = { ...this.state.params, ...options.form };
    }

    this.resource.exportFile(options.form, options.exportOptions, options.headers)
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        if (options.exportOptions.postOptions) {
          this.snackbar.validate(this.translate('ALERTS.EXPORT.SUCCESS'));
        }

        if ('BUTTON.EXPORT.CSV' === options.exportOptions.translationKey) {
          this.state.go('.', undefined, { reload: true });
        }
      }, (reject) => {
        if (400 === reject.status) {
          const violations = JSON.parse(reject.violations);

          violations.error.exception.forEach((exception: any) => this.snackbar.alert(exception.message));
        }
      })
    ;
  }

  onImportSuccess(event: any): void {
    this.importSuccessful.emit(event);
  }
}
