import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { SessionHelper, FormErrorHelper, CountryHelper } from '@helpers';
import { CarrierFamilyResource, ICarrierFamily } from '@components/carrierFamily';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { ICarrierFamilyConfig } from '@components/carrierFamily/interfaces/carrier-family.interface';
import { CarrierResource } from '@resources/carrier-configs.resource';
import { AuthService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';
import { FormService } from '@services/form.service';
import { ICountry } from '@interfaces';
import { forkJoin } from 'rxjs/observable/forkJoin';

@Component({
  selector: 'app-carrier-family-form',
  styles: [require('./carrier-family-form.component.scss')],
  template: require('./carrier-family-form.component.html'),
  providers: [FormService]
})
export class CarrierFamilyFormComponent extends AbstractComponent implements OnInit {

  public form: FormGroup;
  public allMarketplaces: any[] = SessionHelper.getMarketplaces();
  public carriers: any[];
  public deliveryDays = [1, 2, 3, 4, 5, 6, 7];
  public etaCutTimes = [{ 'value': '00:00' }, { 'value': '01:00' }, { 'value': '02:00' }, { 'value': '03:00' }, { 'value': '04:00' },
  { 'value': '05:00' }, { 'value': '06:00' }, { 'value': '07:00' }, { 'value': '08:00' }, { 'value': '09:00' }, { 'value': '10:00' },
  { 'value': '11:00' }, { 'value': '12:00' }, { 'value': '13:00' }, { 'value': '14:00' }, { 'value': '15:00' }, { 'value': '16:00' },
  { 'value': '17:00' }, { 'value': '18:00' }, { 'value': '19:00' }, { 'value': '20:00' }, { 'value': '21:00' }, { 'value': '22:00' },
  { 'value': '23:00' }];
  public currentCountriesCode: string[] = [SessionHelper.getCountry() && SessionHelper.getCountry().code];

  @Input() public carrierFamily: ICarrierFamily;
  @Input() public locale: string = SessionHelper.getLocale();

  @Output() public onSave: EventEmitter<object> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CarrierFamilyResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public carriersResource: CarrierResource,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
    private fb: FormBuilder,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.currentCountriesCode.push(...CountryHelper.getCountryChilrenByCountryCode(
      SessionHelper.getCountry() && SessionHelper.getCountry().code
    ).map((country: ICountry) => country.code));

    forkJoin(...this.currentCountriesCode.map((countryCode: string) => this.carriersResource.getMany({ 'country.code': countryCode })))
      .subscribe((responses: any) => {
        this.carriers = [].concat(...responses.map((response: any) => response['hydra:member']));
        this.carriers.sort((a: any, b: any) => {
          return a.carrierGroup.code.localeCompare(b.carrierGroup.code);
        });
      });

    const marketplacesFGs: any = {};

    this.allMarketplaces.forEach((marketplace: any) => {
      marketplacesFGs[marketplace.code] = this.fb.group({
        etaMin: this.carrierFamily &&
          this.carrierFamily.marketplaces[marketplace.code] &&
          this.carrierFamily.marketplaces[marketplace.code].etaMin || 0,
        etaMax: this.carrierFamily &&
          this.carrierFamily.marketplaces[marketplace.code] &&
          this.carrierFamily.marketplaces[marketplace.code].etaMax || 0
      });
    });

    this.form = this.fb.group({
      reference: [this.carrierFamily && this.carrierFamily.reference || '', Validators.required],
      title: [this.carrierFamily
        && this.carrierFamily.translations
        && this.carrierFamily.translations[this.locale]
        && this.carrierFamily.translations[this.locale].title || '', Validators.required],
      description: this.carrierFamily
        && this.carrierFamily.translations
        && this.carrierFamily.translations[this.locale]
        && this.carrierFamily.translations[this.locale].description || '',
      carriers: new FormArray([]),
      etaMin: this.carrierFamily && this.carrierFamily.etaMin || 0,
      etaMax: this.carrierFamily && this.carrierFamily.etaMax || 0,
      untouchable: this.carrierFamily && this.carrierFamily.untouchable,
      mutualized: this.carrierFamily && this.carrierFamily.mutualized,
      assignablePrimary: this.carrierFamily && this.carrierFamily.assignablePrimary,
      marketplaces: marketplacesFGs,
      deliveryDays: new FormArray([]),
      etaCutTime: this.carrierFamily && this.carrierFamily.etaCutTime,
      position: this.carrierFamily && this.carrierFamily.position || 0,
      highlightProductPage: this.carrierFamily && this.carrierFamily.highlightProductPage,
      highlightProductPageTitle: [
        this.carrierFamily
        && this.carrierFamily.translations
        && this.carrierFamily.translations[this.locale]
        && this.carrierFamily.translations[this.locale].highlightProductPageTitle || '',
        this.carrierFamily && this.carrierFamily.highlightProductPage ? Validators.required : null
      ],
      disableForCustomer: [
        this.carrierFamily
        && this.carrierFamily.translations
        && this.carrierFamily.translations[this.locale]
        && this.carrierFamily.translations[this.locale].disableForCustomer,
      ],
      rse: this.carrierFamily && this.carrierFamily.rse,
    });

    if (this.carrierFamily && this.carrierFamily.carriers) {
      const formArray: FormArray = this.form.get('carriers') as FormArray;

      this.carrierFamily.carriers.forEach((carrier: ICarrierFamilyConfig) => {
        formArray.push(new FormControl(carrier['@id']));
      });
    }

    if (this.carrierFamily && this.carrierFamily.deliveryDays) {
      const formArrayDeliveryDays: FormArray = this.form.get('deliveryDays') as FormArray;
      this.carrierFamily.deliveryDays.forEach((day: number) => {
        formArrayDeliveryDays.push(new FormControl(day));
      });
    }
  }

  public onCarriersCheckChange(event: any) {
    const formArray: FormArray = this.form.get('carriers') as FormArray;

    if (event.target.checked) {
      formArray.push(new FormControl(event.target.value));
    } else {
      formArray.controls.forEach((ctrl: FormControl, i: number) => {
        if (ctrl.value === event.target.value) {
          formArray.removeAt(i);
        }
      });
    }

    this.form.markAsDirty();
  }

  public onDeliveryDaysCheckChange(event: any) {
    const formArray: FormArray = this.form.get('deliveryDays') as FormArray;

    if (event.target.checked) {
      formArray.push(new FormControl(+event.target.value));
    } else {
      formArray.controls.forEach((ctrl: FormControl, i: number) => {
        if (ctrl.value === +event.target.value) {
          formArray.removeAt(i);
        }
      });
    }

    this.form.markAsDirty();
  }

  public onHighlightProductPageToggle(isActive: boolean) {
    if (isActive) {
      this.form.controls['highlightProductPageTitle'].setValidators(Validators.required);
    } else {
      this.form.controls['highlightProductPageTitle'].clearValidators();
    }
    this.form.controls['highlightProductPageTitle'].updateValueAndValidity();
  }

  /**
   * Prepares query to send data to API.
   */
  private prepareQuery(query: any): object {
    const marketpacesETAs: any = {};

    const translations: any = {};
    if (this.carrierFamily) {
      Object.keys(this.carrierFamily.translations).forEach((key: string) => {
        translations[key] = {
          id: this.carrierFamily.translations[key].id,
          title: this.carrierFamily.translations[key].title || '',
          description: this.carrierFamily.translations[key].description || '',
          highlightProductPageTitle: this.carrierFamily.translations[key].highlightProductPageTitle || '',
          disableForCustomer: this.carrierFamily.translations[key].disableForCustomer,
          locale: key
        };
      });
    }
    translations[this.locale] = translations[this.locale] || {};
    translations[this.locale].title = query.title || '';
    translations[this.locale].description = query.description || '';
    translations[this.locale].highlightProductPageTitle = query.highlightProductPageTitle || '';
    translations[this.locale].disableForCustomer = query.disableForCustomer;
    translations[this.locale].locale = this.locale;

    Object.keys(query.marketplaces).forEach((marketplaceKey: string) => {
      if (query.marketplaces[marketplaceKey].value.etaMin || query.marketplaces[marketplaceKey].value.etaMax) {
        marketpacesETAs[marketplaceKey] = query.marketplaces[marketplaceKey].value;
      }
    });

    const sentQuery = {
      country: `/api/v2/countries/${SessionHelper.getCountry().id}`,
      position: 0,
      ...query,
      marketplaces: marketpacesETAs,
      translations: translations
    };

    delete query.title;
    delete query.description;
    delete query.highlightProductPageTitle;
    delete query.disableForCustomer;

    if (this.carrierFamily && this.carrierFamily.translations[this.locale] && this.carrierFamily.translations[this.locale].id) {
      sentQuery.translations[this.locale].id = this.carrierFamily.translations[this.locale].id;
    }

    return sentQuery;
  }

  public submit(isRedirect: boolean = false): void {
    // clean form from API errors
    FormErrorHelper.removeFormControlsAPIErrors(this.form);
    if (this.form.dirty && this.form.valid) {
      this.onSave.emit({
        body: this.prepareQuery(this.form.value),
        form: this.form,
        redirect: isRedirect
      });
    } else if (this.form.dirty && !this.form.valid) {
      this.snackbar.warn(this.translate('ALERTS.ERROR.FORM'));
    } else {
      this.snackbar.warn(this.translate('ALERTS.NO_CHANGE.FORM'));
    }
  }

  /**
   * Cancels form update and redirect to list.
   */
  public cancel(): void {
    this.dialog.confirm(this.translate('DIALOG.TEXT.DONT_SAVE'))
      .then(() => this.actions.list.go());
  }
}
