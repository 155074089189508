import { Injectable } from '@angular/core';

@Injectable()
export class OrderDuplicateService {
    public paymentType: string;
    public marketplace: string;
    public billingAddress: any;
    public shippingAddress: any;
    public items: any;
    public orderId: any;
    public orderType: any;

    constructor() { }

    public setPaymentType(paymentType: string) {
        this.paymentType = paymentType;
    }

    public setMarketplace(marketplace: string) {
        this.marketplace = marketplace;
    }

    public setBillingAddress(billingAddress: any) {
        this.billingAddress = billingAddress;
    }

    public setShippingAddress(shippingAddress: any) {
        this.shippingAddress = shippingAddress;
    }

    public setOrderId(id: any) {
        this.orderId = id;
    }

    public getBillingAddress() {
        return this.billingAddress;
    }

    public getShippingAddress() {
        return this.shippingAddress;
    }

    public getPaymentType() {
        return this.paymentType;
    }

    public getMarketplace() {
        return this.marketplace;
    }

    public getOrderId() {
        return this.orderId;
    }

    public getItems() {
      return this.items;
    }

    public setItems(items: any) {
      this.items = items;
    }

    public getOrderType() {
      return this.orderType;
    }

    public setOrderType(orderType: any) {
      this.orderType = orderType;
    }

    public resetAllProperties() {
        this.paymentType = undefined;
        this.marketplace = undefined;
        this.billingAddress = undefined;
        this.shippingAddress = undefined;
        this.orderId = undefined;
        this.orderType = undefined;
    }
}
