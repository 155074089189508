import { AbstractResource } from '@resources';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { SessionHelper } from '@helpers';
import {Observable} from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/retail/orders',
  isHydra: true,
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 15,
    order: 'DESC',
    orderType: 'all',
    'marketplaces[]': 'allRetail',
    'countries[]': SessionHelper.getCountry().code,
  }),
})
export class RetailResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {

    if (params['marketplaces[]'] === undefined) {
      params['marketplaces[]'] = 'allRetail';
    }

    if (params['orderManagerStatuses[]'] === undefined) {
      localStorage.setItem('activeButtonIndex', null);
    }

    if (Array.isArray(params['marketplaces[]']) && params['marketplaces[]'] && params['marketplaces[]'].length > 1) {
      params['marketplaces[]'] = params['marketplaces[]'].filter((value: any) => value !== 'allRetail');
    }

    return super.cGet(params, options);
  }
}
