import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { ShippingFeeModel } from '@components/shipping-fee/models/shipping-fee.model';
import * as moment from 'moment';
import { DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants';
import { SessionHelper } from '@helpers';

@Injectable()
@Resource({
  routeName: 'shipping_fee',
  entryPoint: '/v2/shipping_fees',
  translationKey: 'SHIPPING_FEE',
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    'order[id]': 'DESC',
  }),
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'number' },
    { field: 'label', translationKey: 'LABEL', type: 'string' },
    { field: 'exemptionLimit', translationKey: 'EXEMPTION_LIMIT', type: 'number' },
    { field: 'shippingPrice', translationKey: 'SHIPPING_PRICE', type: 'number' },
    { field: 'active', translationKey: 'ACTIVE', type: 'boolean', switchable: true }
  ],
  listModel: ShippingFeeModel,
  createAvailable: true,
  deleteAvailable: false,
  isHydra: true,
  exportOptions: [
    {
      entryPoint: '/v2/export',
      responseType: 'text',
      type: 'text/plain',
      filename: `export-super-product-carrier_family-cost${moment().format(DATE_SHORT_INTERNATIONAL_FORMAT)}.csv`,
      translationKey: 'PAGE.PRODUCT.LIST.FILTER.EXPORT.SUPER_PRODUCT_CARRIER_FAMILY',
      filters: false,
      roles: ['ROLE_WALISOFT_AGENT'],
      name: 'exportSuperProductCarrierFamily',
      postOptions: {
        exportCode: 'super-product_carrier_family',
        formatCode: 'csv_excel',
        dryRun: false,
        deferred: false,
        split: false,
        serializationGroups: [],
        limit: SessionHelper.getCountry() ? SessionHelper.getCountry().code : null,
        async: false
      }
    }
  ],
  importOptions: [
    {
      entryPoint: '/api/v2/import',
      importButton: 'BUTTON.IMPORT.SHIPPING_PRICE_PER_SUPER_PRODUCT',
      businessObject: 'super-product-carrier-family'
    },
  ]
})
export class ShippingFeeResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
