import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import {OrderItemResource, OrderResource} from '@resources';
import { BASE_URL_INVOICE_ORDER } from '@constants/config.constants';
import { IndicatorComponent } from '@components/generic/indicator';
import { DialogService } from '@progress/kendo-angular-dialog';
import { AddressDialogComponent } from './address-dialog/address-dialog.component';
import { OrderItemDialogComponent } from './order-item-dialog/order-item-dialog.component';
import moment = require('moment');
import { DATE_FULL_FORMAT } from '@constants';
import {IOrderItem} from '@interfaces/IOrderItem';
import {
  OrderItemUpdateDialogComponent
} from '@components/order-manager/form/tab-general/order-item-dialog/order-item-update-dialog.component';
import {Subscription} from 'rxjs/Subscription';
import {CountryHelper, SessionHelper} from '@helpers';

@Component({
    selector: 'app-order-manager-general-form',
    template: require('./order-manager-general-form.component.html'),
    styles: [require('./order-manager-general-form.component.scss')],
    providers: [
        { provide: AbstractResource, useClass: OrderResource },
    ],
})
export class OrderManagerGeneralFormComponent extends AbstractComponent implements OnInit {

    @Input() order: any;

    @Output() indicatorUpdate = new EventEmitter();
    @Output() reloadOrder = new EventEmitter();

    public orderItems: any = [];
    public BASE_URL_INVOICE_ORDER = BASE_URL_INVOICE_ORDER;
    public shippingAddressId: number;
    public addressDialogOpened = false;
    public orderEntityCommercialCurrency: string = '';

    public locales: string[] = [];

    public orderItemDialogOpened: boolean = false;

    @ViewChild(IndicatorComponent)
    private indicatorComponent: IndicatorComponent;

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        resource: AbstractResource,
        private orderItemResource: OrderItemResource,
        @Inject('StateService') state: ng.ui.IStateService,
        private dialogService: DialogService,
        @Inject('DialogService') private dialog: any,
    ) {
        super($translate, authService, resource, state);
    }

    ngOnInit() {
        this.fetchOrderItems();
        this.orderEntityCommercialCurrency = SessionHelper.getCountryByMarketplaceCode(this.order.marketplace).commercialEntity.currencyCode;
      this.shippingAddressId = this.getAddressId(this.order.shippingAddress);

      const locale = this.order.locale;

      this.locales = CountryHelper.getLocales([CountryHelper.getCountryByCountryCode(CountryHelper.getCountryCodeFromLocale(locale).toUpperCase())]);

      this.locales.sort(function (a: string, b: string ) {
        if (a === locale) {
            return -1;
        }

        return 0;
      });
    }

    public onIndicatorUpdateEvent() {
        this.indicatorUpdate.emit();
    }

    public reloadIndicator() {
        this.indicatorComponent.fetchData();
    }

  private fetchOrderItems() {
    this.orderItemResource.getOrderItem(this.order.id).subscribe(
      response => {
        this.orderItems = response as any;
        this.orderItems.items.sort((a: any, b: any) => {
          const statusA = a.stockStatus ? a.stockStatus : '';
          const statusB = b.stockStatus ? b.stockStatus : '';

          return (statusB === 'oversold' ? 1 : 0) - (statusA === 'oversold' ? 1 : 0);
        });
      }
    );
  }
    public openAddressDialog(addressType: 'billing' | 'shipping') {
        const dialogRef = this.dialogService.open({
            content: AddressDialogComponent,
            width: 0.8 * window.innerWidth,
            height: 0.82 * window.innerHeight,
            minWidth: 800,
        });

        const addressComponentInstance = dialogRef.content.instance;

        if (addressType === 'billing') {
            addressComponentInstance.address = this.order.billingAddress;
            addressComponentInstance.addressIdToRecopy = this.getAddressId(this.order.shippingAddress);
        } else {
            addressComponentInstance.activeCityAutocomplete = this.activeCityAutocomplete();
            addressComponentInstance.address = this.order.shippingAddress;
            addressComponentInstance.addressIdToRecopy = this.getAddressId(this.order.billingAddress);
        }

        addressComponentInstance.addressType = addressType;
        addressComponentInstance.marketplace = this.order.marketplace;

        dialogRef.result.subscribe((r: any) => {
            if (r.type === 'submit') {
                this.reloadOrder.emit();
            }
        });
    }

  public openOrderItemEditDialog(orderItem: IOrderItem) {
    const dialogRef = this.dialogService.open({
      content: OrderItemUpdateDialogComponent,
      width: 0.8 * window.innerWidth,
      height: 0.82 * window.innerHeight,
      minWidth: 800,
    });

    const orderItemDialogComponentInstance = dialogRef.content.instance;
    orderItemDialogComponentInstance.orderId = this.order.id;
    orderItemDialogComponentInstance.marketplace = this.order.marketplace;
    orderItemDialogComponentInstance.quantity = orderItem.quantity;
    orderItemDialogComponentInstance.unitPrice = orderItem.unitPrice;
    orderItemDialogComponentInstance.discount = orderItem.discount;
    orderItemDialogComponentInstance.orderItemId = orderItem.id;

    dialogRef.result.subscribe((r: any) => {
      if (r.type === 'submit') {
        this.fetchOrderItems();
      }
    });
  }

  public deleteAction(orderId: any , id: any) {
    this.dialog.confirm(this.translate('PAGE.ATTRIBUTE.CONFIRM.DELETE'))
      .then(() => {
        const subscriber: Subscription = this.orderItemResource.removeOrderItemv2(id)
          .subscribe(() => {
            this.state.go('.', { id: this.state.params.id }, { reload: true });
          });
      });
  }
  private activeCityAutocomplete(): boolean {
        let _activeCityAutocomplete = false;
        this.orderItems.items.forEach((item: any) => {
            if (item.carrier === 'relaiscolis-eco' || item.carrier === 'relaiscolis-confort') {
                _activeCityAutocomplete = true;
                return;
            }
        });
        return _activeCityAutocomplete;
    }

    private getAddressId(address: any) {
        try {
            return address['@id'].split('/').pop();
        } catch (error) {
            console.error(error);
        }
    }

    public isWebsiteOrderType(): boolean {
        return this.order.marketplace.includes('site') || this.order.marketplace.includes('showroom') || this.order.marketplace.includes('b2b');
    }

    public openOrderItemDialog() {
        const dialogRef = this.dialogService.open({
            content: OrderItemDialogComponent,
            width: 0.8 * window.innerWidth,
            height: 0.82 * window.innerHeight,
            minWidth: 800,
        });

        const orderItemDialogComponentInstance = dialogRef.content.instance;
        orderItemDialogComponentInstance.orderId = this.order.id;
        orderItemDialogComponentInstance.orderLocale = 'en_GB';
        orderItemDialogComponentInstance.marketplace = this.order.marketplace;

        dialogRef.result.subscribe((r: any) => {
            if (r.type === 'submit') {
                this.fetchOrderItems();
            }
        });
    }

    public onUpdateIndicator(event: any) {
        this.reloadIndicator();
    }

    public goToProductEdit(event: MouseEvent, productUri: string) {
        const id = productUri.split('/').pop();
        let state = 'product.edit';

        if (this.order.detached) {
            state = 'spare-part.edit';
        }
    }

    public checkWarranty(maxWarrantyDate: string): boolean {
        return moment(maxWarrantyDate, DATE_FULL_FORMAT).isAfter(moment(), 'day');
    }

    public getWarranty(maxWarrantyDate: string): Object {
        return moment(maxWarrantyDate, DATE_FULL_FORMAT);
    }

    public downloadFile(id: number): void {
      (<OrderResource>this.resource).downloadEstimate(id)
        .takeUntil(this.destroyed$)
        .subscribe(
          (response: Blob | null) => {
            if (null === response) {
              return;
            }

            const fileURL = URL.createObjectURL(response);
            window.open(fileURL, '_blank');
          });
  }
}
