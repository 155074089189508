import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionHelper } from '@helpers';
import { AuthService, IJwt } from '@services';
import { ICredentials, AuthResource } from '@components/login/resources/auth.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { DASHBOARD_REVENUES } from '@constants/route.constants';
import { UserResource } from '@resources';
import { IUser } from '@models';
import { ICarrierGroup, ICountry, IMarketplace, IMarketplacesByCountry } from '@interfaces';
import { IWarehouses } from '@components/warehouses/models';
import { ITaskStatus } from '@components/generic/task-manager/interfaces';
import { OAUTH_GOOGLE_CLIENT_ID } from '@constants';
import {DatadogLoggerService} from '@services/datadog.service';

@Component({
  selector: 'app-login',
  template: require('./login.component.html'),
})
export class LoginComponent extends AbstractComponent implements OnInit {
  public form: FormGroup;
  public loginRejectionMessage: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('LocationService') private location: ng.ILocationService,
    private fb: FormBuilder,
    private loginCheckResource: AuthResource,
    private userResource: UserResource,
    private sessionHelper: SessionHelper,
    private logger: DatadogLoggerService
  ) {
    super($translate, authService, null, state);

    SessionHelper.clearStorage();

    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: true
    });
  }

  ngOnInit(): void {
    // @ts-ignore
    const {code, platform, scope} = this.state.params;

    if (undefined !== code && undefined !== platform && undefined !== scope) {
      this.processLogin({code, provider: platform, scope, redirect_uri: this.getRedirectUrlForPlatform('google')}, false);
    }
  }

  public login(): void {
    // @ts-ignore
    const {username, password}: ICredentials = this.form.value;
    const rememberUser: boolean = !!this.form.value.rememberMe;

    if (username && password) {
      this.processLogin({username, password}, rememberUser);
    }
  }

  private processLogin(body: ICredentials, rememberUser: boolean): void {
    this.loginCheckResource.create(body)
      .switchMap((jwt: IJwt) => {
        AuthService.setTokenInStorage(jwt, rememberUser);

        const decodedToken: any = this.authService.getDecodedToken();

        return this.userResource.get(decodedToken.user_id);
      })
      .switchMap((user: IUser) => {
        if (0 === user.countries.length) {
          throw new Error('A user must be linked to at least one country');
        }
        SessionHelper.setCurrentUser(user);
        SessionHelper.setCurrentUserDataInStorage(user);

        return this.sessionHelper.callRequiredDataForApplication();
      })
      .takeUntil(this.destroyed$)
      .subscribe((requiredData: [
        IMarketplace[],
        ICarrierGroup[],
        IWarehouses[],
        string[],
        string[],
        ITaskStatus[],
        ICountry[],
        ICountry[],
        IMarketplace[],
        ICarrierGroup[],
        IWarehouses[],
        string[],
        IWarehouses[]
      ]) => {
        this.sessionHelper.setRequiredDataInStorage(requiredData);
        SessionHelper.setMarketplacesByCountry(this.getMarketplacesByCountry());
        this.logger.info('Login success for user', {user: SessionHelper.getUser().username});

        this.state.go(DASHBOARD_REVENUES);

      }, (reject) => {
        this.logger.error('Login failed', {message: reject.message});
        SessionHelper.clearStorage();
        this.loginRejectionMessage = reject.message;
        this.location.search({});
      })
    ;
  }

  private getMarketplacesByCountry(): IMarketplacesByCountry {
    const marketplacesByCountry: IMarketplacesByCountry = {};
    const allMarketplaces = SessionHelper.getAllMarketplaces();

    SessionHelper.getCountries().map((country: ICountry) => {
      marketplacesByCountry[country.code] = [];

      country.marketplaces.map((code: string) => {
        const marketplaceObject = allMarketplaces.find((marketplace: IMarketplace) => {
          return marketplace.code === code;
        });

        if (undefined !== marketplaceObject) {
          marketplacesByCountry[country.code].push(marketplaceObject);
        }
      });
    });

    return marketplacesByCountry;
  }

  public getRedirectUrlForPlatform(platform: string): string {
    return this.state.href('anonymous.oauth-validate', {platform}, {absolute: true}).replace('/#!', '');
  }

  public getAuthorizationUrlForPlatform(platform: string): string {
    let baseUrl: string;
    const urlParams: any = {
      redirect_uri: this.getRedirectUrlForPlatform(platform),
      scope: 'email profile',
      response_type: 'code'
    };

    switch (platform) {
      case 'google':
        baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
        urlParams['client_id'] = OAUTH_GOOGLE_CLIENT_ID;
        break;
      default:
        return '#';
    }

    return baseUrl + '?' + (new URLSearchParams(urlParams)).toString();
  }
}
