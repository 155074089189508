import { Injectable } from '@angular/core';
import {APP_ENV, DATADOG_API_KEY} from '@constants';

@Injectable()
export class DatadogLoggerService {

  constructor() {
    this.datadogLogs = (window as any).DD_LOGS || require('@datadog/browser-logs').datadogLogs;
    if (!DatadogLoggerService.instance) {
      DatadogLoggerService.instance = this;

      this.datadogLogs.init({
        clientToken: DATADOG_API_KEY,
        site: 'datadoghq.eu',
        forwardErrorsToLogs: false,
        sessionSampleRate: 100,
        service: 'walisoft',
        env: APP_ENV
      });
    }
  }
  private static instance: DatadogLoggerService;
  private readonly datadogLogs: any;

  log(message: string, context?: any): void {
    this.datadogLogs.logger.log(message, context);
  }

  info(message: string, context?: any): void {
    this.datadogLogs.logger.info(message, context);
  }

  warn(message: string, context?: any): void {
    this.datadogLogs.logger.warn(message, context);
  }

  error(message: string, context?: any): void {
    this.datadogLogs.logger.error(message, context);
  }
}
