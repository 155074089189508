export default shippingFeeRoutes;

/** @ngInject */
function shippingFeeRoutes($stateProvider: any): void {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'shippingFeeList',
    title: '',
  };

  $stateProvider
    .state('shipping_fee', {
      url: '/shipping-fees',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.SHIPPING_FEE',
        stateName: 'shipping_fee.list'
      },
    })
    .state('shipping_fee.list', {
      url: '?page&byPage',
      views: {
        'page@layout': {
          template: '<app-shipping-fee-list></app-shipping-fee-list>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SHIPPING_FEE.LIST.TITLE'
      })
    })
    .state('shipping_fee.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      params: {
        supplierId: null,
      },
      views: {
        'page@layout': {
          template: '<app-shipping-fee-form></app-shipping-fee-form>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SHIPPING_FEE.NEW.TITLE',
      })
    })
    .state('shipping_fee.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': {
          template: '<app-shipping-fee-form></app-shipping-fee-form>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SHIPPING_FEE.EDIT.TITLE',
      })
    })
  ;
}
