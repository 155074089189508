import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { ICountry } from '@interfaces/ICountry';

@Directive({
  selector: '[flag]'
})
export class FlagDirective implements OnInit {
  @Input() country: ICountry;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.renderer.setAttribute(this.el.nativeElement, 'src', `images/flags/${this.country.code.toLowerCase()}.png`);
    this.renderer.setAttribute(this.el.nativeElement, 'name', this.country.name);
    this.renderer.setAttribute(this.el.nativeElement, 'alt', this.country.name);
    this.renderer.setAttribute(this.el.nativeElement, 'height', '10px');
  }
}
