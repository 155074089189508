import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { TextField, DateField, ComboSearchField, SkuSearchField } from '@components/generic/Form/dynamic/fields';
import * as moment from 'moment';
import { DATE_FULL_FORMAT } from '@constants';
import { FormNotifierService } from '@services';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { setDates } from '@helpers/DateFilterHelper';

@Injectable()
export class QualityExportFiltersFormService extends AbstractFiltersFieldsService {

  public readonly statusStatuses = [
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.STATUSES.NEW'), value: 'new' },
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.STATUSES.OPEN'), value: 'open' },
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.STATUSES.PENDING'), value: 'pending' },
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.STATUSES.HOLD'), value: 'hold' },
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.STATUSES.SOLVED'), value: 'solved' },
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.STATUSES.CLOSED'), value: 'closed' },
  ];

  public readonly formIdStatuses = [
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FORM_ID.TRADE'), value: 360000109718 },
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FORM_ID.GRC'), value: 360000096217 },
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FORM_ID.SAV'), value: 360000096817 },
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FORM_ID.DELIVERY'), value: 360000097157 },
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FORM_ID.DELIVERY_COMMUNICATION'), value: 360000104337 },
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FORM_ID.OUTPUT'), value: 360000110278 },
    { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FORM_ID.WORKSHOP'), value: 360000111437 },
  ];

  public startDateField: TextField = new TextField({
    fieldName: 'startDate',
    value: null,
    type: 'hidden',
    order: 4
  });

  public endDateField: TextField = new TextField({
    fieldName: 'endDate',
    value: null,
    type: 'hidden',
    order: 5,
  });

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService
  ) {
    super(formNotifier, state);
  }

  public getFields(): AbstractFormFieldBase<any>[] {
    this.startDateField.value = this.filters.get('startDate') ? this.filters.get('startDate') : undefined;
    this.endDateField.value = this.filters.get('endDate') ? this.filters.get('endDate') : undefined;

    const fields: AbstractFormFieldBase<any>[] = [
      new DateField({
        fieldName: 'date',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.DATE',
        dateRange: true,
        value: this.filters.get('startDate') && this.filters.get('endDate') ?
          new Date(this.filters.get('endDate')) :
          undefined,
        valueChangedAction: setDates.bind(this),
        order: 1
      }),
      new ComboSearchField({
        fieldName: 'status',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.STATUS',
        data: this.statusStatuses,
        textField: 'label',
        valueField: 'value',
        value: null,
        order: 2,
        valuePrimitive: true
      }),
      new ComboSearchField({
        fieldName: 'formId',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.FORM_ID',
        data: this.formIdStatuses,
        textField: 'label',
        valueField: 'value',
        value: null,
        order: 3,
        valuePrimitive: true
      }),
      new SkuSearchField({
        fieldName: 'sku',
        productType: 'product',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.SKU',
        value: this.filters.getAll('sku').length !== 0 ? this.filters.getAll('sku') : undefined,
        order: 4,
        limit: 1
      }),
      this.startDateField,
      this.endDateField,
    ];

    return fields.sort((a, b) => a.order - b.order);
  }

}
