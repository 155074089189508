import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { TextField, ComboSearchField, MultiSearchField, SkuSearchField, DateField } from '@components/generic/Form/dynamic/fields';
import { MarketplaceResource } from '@resources';
import { FormNotifierService } from '@services';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { SessionHelper } from '@helpers/session.helper';
import { ICatalogs } from '@components/catalogs/models';
import { CatalogsResource } from '@components/catalogs';
import { takeUntil } from 'rxjs/operators';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { SuperProductResource } from '@components/super-product/super-product.resource';
import moment = require('moment');
import { DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import { EavAttributeResource } from '@components/eav-attribute/resources/eav-attribute.resource';
import { WarehousesResource } from '@components/warehouses/warehouses.resource';
import { IWarehouses } from '@components/warehouses/models';

@Injectable()
export class ProductFiltersFormService extends AbstractFiltersFieldsService {
  public catalogsField: ComboSearchField = new ComboSearchField({
    fieldName: 'catalog',
    label: 'PAGE.PRODUCT.LIST.FILTER.CATALOG.LABEL',
    data: null,
    textField: 'label',
    valueField: 'id',
    // tslint:disable-next-line:radix
    value: this.filters.get('catalog') ? parseInt(this.filters.get('catalog')) : undefined,
    valuePrimitive: true,
    order: 1
  });

  private superProductsField: ComboSearchField = new ComboSearchField({
    fieldName: 'superProduct',
    label: 'PAGE.PRODUCT.LIST.FILTER.SUPER_PRODUCT.LABEL',
    data: null,
    textField: 'label',
    valueField: 'id',
    value: this.filters.get('superProduct') ? this.filters.get('superProduct') : undefined,
    valuePrimitive: true,
    order: 2
  });

  public marketplacesField: MultiSearchField = new MultiSearchField({
    fieldName: 'marketplaces[]',
    label: 'PAGE.PRODUCT.LIST.FILTER.MARKETPLACES.LABEL',
    data: null,
    textField: 'commercialName',
    valueField: 'code',
    value: this.filters.getAll('marketplaces[]') ? this.filters.getAll('marketplaces[]') : [],
    order: 4
  });

  private startArrivalDateField: TextField = new TextField({
    fieldName: 'startArrivalDate',
    value: null,
    hidden: true,
    order: 13
  });

  private endArrivalDateField: TextField = new TextField({
    fieldName: 'endArrivalDate',
    value: null,
    hidden: true,
    order: 14
  });
  private eavFields: ComboSearchField[] = [new ComboSearchField({
      fieldName: 'purchase_category',
      label: 'PAGE.PRODUCT.LIST.FILTER.PURCHASE_CATEGORY.LABEL',
      data: null,
      textField: 'label',
      valueField: 'id',
      value: this.filters.get('purchase_category') ? this.filters.get('purchase_category') : undefined,
      valuePrimitive: true,
      order: 17
    }),
    new ComboSearchField({
      fieldName: 'purchase_family',
      label: 'PAGE.PRODUCT.LIST.FILTER.PURCHASE_FAMILY.LABEL',
      data: null,
      textField: 'label',
      valueField: 'id',
      value: this.filters.get('purchase_family') ? this.filters.get('purchase_family') : undefined,
      valuePrimitive: true,
      order: 18
    }),
    new ComboSearchField({
      fieldName: 'purchase_subfamily',
      label: 'PAGE.PRODUCT.LIST.FILTER.PURCHASE_SUBFAMILY.LABEL',
      data: null,
      textField: 'label',
      valueField: 'id',
      value: this.filters.get('purchase_subfamily') ? this.filters.get('purchase_subfamily') : undefined,
      valuePrimitive: true,
      order: 19
    }),
    new ComboSearchField({
      fieldName: 'universe',
      label: 'PAGE.PRODUCT.LIST.FILTER.UNIVERSE.LABEL',
      data: null,
      textField: 'label',
      valueField: 'id',
      value: this.filters.get('universe') ? this.filters.get('universe') : undefined,
      valuePrimitive: true,
      order: 20
    })
  ];

  private mutualizableWarehouses: MultiSearchField = new MultiSearchField({
    fieldName: 'sharedWarehouses',
    label: 'PAGE.PRODUCT.LIST.FILTER.MUTUALIZED.LABEL',
    data: null,
    textField: 'name',
    valueField: 'code',
    value: this.filters.get('sharedWarehouses') ? this.filters.get('sharedWarehouses') : undefined,
    valuePrimitive: true,
    order: 20
  });

  readonly hidden: any[];
  readonly hasLogistic: any[];
  readonly productStatuses: any[];
  readonly packageType: any[];
  readonly stockStatus: any[];
  public eavAttributes: any;
  public warehouses: any[] = [];

  constructor(
    private marketplaceResource: MarketplaceResource,
    @Inject('StateService') protected state: ng.ui.IStateService,
    formNotifier: FormNotifierService,
    @Inject('TranslationService') private $translate: ng.translate.ITranslateService,
    private catalogsResource: CatalogsResource,
    private superProductResource: SuperProductResource,
    private eavAttributeResource: EavAttributeResource,
    private warehouseResource: WarehousesResource,

  ) {
    super(formNotifier, state);

    this.getEavAttributes();
    this.getMutualizableWarehouses();
    const observablesList$ = [];
    observablesList$.push(this.catalogsResource.cGet({ locale: SessionHelper.getLocale() }));
    observablesList$.push(this.superProductResource.getSkus({ locale: SessionHelper.getLocale() }));

    forkJoin(observablesList$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]) => {
        this.catalogsField.data = response[0]['hydra:member'].filter((catalog: ICatalogs) => !!catalog.label);
        this.superProductsField.data = response[1];
      });

    this.fetchMarketplaces(SessionHelper.getCountry().code);

    this.hidden = [
      {
        key: null,
        label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.HIDDEN.DATA.0'),
      },
      {
        key: 0,
        label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.HIDDEN.DATA.1'),
      },
      {
        key: 1,
        label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.HIDDEN.DATA.2'),
      }
    ];

    this.hasLogistic = [
      {
        key: '',
        label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.LOGISTIC.DATA.0'),
      },
      {
        key: 0,
        label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.LOGISTIC.DATA.1'),
      },
      {
        key: 1,
        label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.LOGISTIC.DATA.2'),
      }
    ];

    this.productStatuses = [
      { label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.ALL_FILTERS'), status: null },
      { label: this.$translate.instant('PAGE.PRODUCT.STATUSES.STATUS_NEW'), status: 'new' },
      { label: this.$translate.instant('PAGE.PRODUCT.STATUSES.STATUS_PENDING_CONTENT'), status: 'pending_content' },
      { label: this.$translate.instant('PAGE.PRODUCT.STATUSES.STATUS_PENDING_TRANSLATION_CHECK'), status: 'pending_translation_check' },
      { label: this.$translate.instant('PAGE.PRODUCT.STATUSES.STATUS_CONTENT_VALID'), status: 'content_valid' },
      { label: this.$translate.instant('PAGE.PRODUCT.STATUSES.STATUS_READY_FOR_SALE'), status: 'ready_for_sale' },
      { label: this.$translate.instant('PAGE.PRODUCT.STATUSES.STATUS_END_OF_LIFE'), status: 'end_of_life' },
    ];

    this.packageType = [
      {
        key: 'all',
        label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.PACKAGE_TYPE.DATA.ALL'),
      },
      {
        key: 'multi',
        label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.PACKAGE_TYPE.DATA.YES'),
      },
      {
        key: 'mono',
        label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.PACKAGE_TYPE.DATA.NO'),
      }
    ];

    this.stockStatus = [
      {
        key: 'active',
        label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.STOCK_STATUS.DATA.ACTIVE'),
      },
      {
        key: 'preorder',
        label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.STOCK_STATUS.DATA.PREORDER'),
      },
      {
        key: 'inactive',
        label: this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.STOCK_STATUS.DATA.INACTIVE'),
      }
    ];
  }

  public getFields(): AbstractFormFieldBase<any>[] {

    const startArrivalDate = this.filters.get('startArrivalDate');
    const endArrivalDate = this.filters.get('endArrivalDate');

    if (startArrivalDate && endArrivalDate) {
      this.startArrivalDateField.value = startArrivalDate;
      this.endArrivalDateField.value = endArrivalDate;
    }

    const fields = [
      this.catalogsField,
      this.superProductsField,
      this.marketplacesField,

      new SkuSearchField({
        fieldName: 'sku[]',
        label: 'PAGE.PRODUCT.LIST.FILTER.SKU.LABEL',
        productType: 'product',
        value: this.filters.getAll('sku[]') ? this.filters.getAll('sku[]') : [],
        order: 3,
        allowCustom: true,
        withSpareParts: false,
      }),

      new TextField({
        fieldName: 'stockMin',
        label: 'PAGE.PRODUCT.LIST.FILTER.SP_MIN.LABEL',
        value: this.filters.get('stockMin') ? this.filters.get('stockMin') : undefined,
        order: 5
      }),

      new TextField({
        fieldName: 'stockMax',
        label: 'PAGE.PRODUCT.LIST.FILTER.SP_MAX.LABEL',
        value: this.filters.get('stockMax') ? this.filters.get('stockMax') : undefined,
        order: 6
      }),

      new ComboSearchField({
        fieldName: 'status',
        label: 'PAGE.PRODUCT.LIST.FILTER.PRODUCT_STATUS.LABEL',
        data: this.productStatuses,
        textField: 'label',
        valueField: 'status',
        value: this.filters.get('status') ? this.filters.get('status') : undefined,
        valuePrimitive: true,
        order: 7
      }),

      new ComboSearchField({
        fieldName: 'hidden',
        label: 'PAGE.PRODUCT.LIST.FILTER.HIDDEN.LABEL',
        data: this.hidden,
        textField: 'label',
        valueField: 'key',
        // tslint:disable-next-line:radix
        value: this.filters.get('hidden') ? parseInt(this.filters.get('hidden')) : undefined,
        valuePrimitive: true,
        order: 8
      }),

      new TextField({
        fieldName: 'reference',
        label: 'PAGE.PRODUCT.LIST.FILTER.REFERENCE.LABEL',
        value: this.filters.get('reference') ? this.filters.get('reference') : undefined,
        order: 9
      }),

      new ComboSearchField({
        fieldName: 'hasLogistic',
        label: 'PAGE.PRODUCT.LIST.FILTER.LOGISTIC.LABEL',
        data: this.hasLogistic,
        textField: 'label',
        valueField: 'key',
        // tslint:disable-next-line:radix
        value: this.filters.get('hasLogistic') ? parseInt(this.filters.get('hasLogistic')) : undefined,
        valuePrimitive: true,
        order: 10
      }),

      new ComboSearchField({
        fieldName: 'packageType',
        label: 'PAGE.PRODUCT.LIST.FILTER.PACKAGE_TYPE.LABEL',
        data: this.packageType,
        textField: 'label',
        valueField: 'key',
        value: this.filters.get('packageType') ? this.filters.get('packageType') : undefined,
        valuePrimitive: true,
        order: 11
      }),

      new ComboSearchField({
        fieldName: 'stockStatus',
        label: 'PAGE.PRODUCT.LIST.FILTER.STOCK_STATUS.LABEL',
        data: this.stockStatus,
        textField: 'label',
        valueField: 'key',
        value: this.filters.get('stockStatus') ? this.filters.get('stockStatus') : undefined,
        valuePrimitive: true,
        order: 11
      }),

      new DateField({
        label: 'Date d\'arrivage',
        fieldName: 'arrivalDates',
        dateRange: true,
        value: startArrivalDate && endArrivalDate ? [new Date(startArrivalDate), new Date(endArrivalDate)] : undefined,
        valueChangedAction: this.setArrivalDates.bind(this),
        order: 12
      }),

      this.startArrivalDateField,
      this.endArrivalDateField,
      this.mutualizableWarehouses,

      new TextField({
        fieldName: 'ean',
        label: 'PAGE.PRODUCT.LIST.FILTER.EAN.LABEL',
        value: this.filters.get('ean') ? this.filters.get('ean') : undefined,
        order: 16
      }),
    ];
    this.eavFields.forEach((field: ComboSearchField) => {
      fields.push(field);
    });

    return fields.sort((a, b) => a.order - b.order);
  }

  public fetchMarketplaces(countryCode: string): void {
    this.marketplaceResource.filterByCountryCode(countryCode)
      .subscribe((marketplaces: any) => {
        this.marketplacesField.data.push(...marketplaces);

        const itemsAvailable: any = [];
        const stateParams = typeof this.state.params.marketplaces === 'string' ?
          [this.state.params.marketplaces]
          :
          this.state.params.marketplaces;

        marketplaces.forEach((marketplace: any) => {
          if (stateParams && stateParams.includes(marketplace.code)) {
            itemsAvailable.push(marketplace.code);
          }
        });

        this.marketplacesField.value = itemsAvailable;
        this.state.params.marketplaces = this.marketplacesField.value;

        this.marketplacesField.value = this.filters.getAll('marketplaces[]').length !== 0 ?
          this.filters.getAll('marketplaces[]') :
          undefined
          ;
      });
  }

  private setArrivalDates(newValue: string): void {
    if (newValue && null !== newValue[0]) {
      this.startArrivalDateField.value = moment(newValue[0]).startOf('day').format(DATE_SHORT_INTERNATIONAL_FORMAT);
      this.endArrivalDateField.value = moment(newValue[1]).endOf('day').format(DATE_SHORT_INTERNATIONAL_FORMAT);
    }
  }
  private getEavAttributes(): void {
    try {
      this.eavAttributeResource.getMany(
        {
          'code[]': ['purchase_category', 'purchase_family', 'purchase_subfamily', 'universe'],
        }
      ).subscribe(
        (data: any) => {
          const AllAttributes = data['hydra:member'].map((item: any) => {
            return {
              code: item.code,
              options: item.attributeOptions.map((option: any) => {
                return {id: option.id, label: option.option};
              })
            };
          });
          this.eavFields.forEach((field: ComboSearchField) => {
            AllAttributes.find((item: any) => {
              if (item.code === field.fieldName) {
                field.data = item.options;
              }
            });
          });
        });
    } catch (error) {
      console.error('Cannot fetch EAV attributes:', error);
    }
  }

  public getMutualizableWarehouses(): void {
    this.warehouseResource.cGet(
      { 'active': true, 'pagination': false, 'mutualizable': true }
    )
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.warehouses = response['hydra:member'].map((warehouse: IWarehouses): any => {
          return {
            code: warehouse.code,
            name: warehouse.name,
          };
        });
        this.mutualizableWarehouses.data = this.warehouses;
      });

  }

}
