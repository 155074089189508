import { Component, Inject } from '@angular/core';
import { AbstractResource } from '@resources';
import { AuthService } from '@services';
import { ContainerResource } from '@components/container/resources/container.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { ContainerListFiltersService } from '@components/container/list/container-list-filters.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import {DeliveryNoteResource} from '@components/delivery-note/resources/delivery-note.resource';
import {DeliveryNoteListFiltersService} from '@components/delivery-note/list/delivery-note-list-filters.service';

@Component({
  selector: 'app-delivery-note-list',
  template: require('./delivery-note-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: DeliveryNoteResource },
    { provide: AbstractFiltersFieldsService, useClass: DeliveryNoteListFiltersService }
  ],
})
export class DeliveryNoteListComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, resource, state);
  }
}
