import { Component, Inject } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import {RetailResource} from '@components/retail/retail.resource';

@Component({
  selector: 'app-retail-orders-result-list',
  template: require('./retail-orders-result-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: RetailResource },
  ]
})
export class RetailOrdersResultListComponent extends AbstractComponent {
  private _items: any[];

  set items(items: any[]) {
    this._items = items;
  }

  get items(): any[] {
    return this._items;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private dialogService: DialogService,
    @Inject('StateService') state: ng.ui.IStateService) {
    super($translate, authService, resource, state);
  }

  goToEdit(orderId: any) {
    this.state.go(`order_manager.edit`, { id: orderId });
  }
}
