import { WarehousesResource } from './../warehouses/warehouses.resource';
import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import { SessionHelper } from '@helpers';
import { DateField, TextField } from '@components/generic/Form/dynamic/fields';
import { setDates } from '@helpers/DateFilterHelper';

@Injectable()
export class StockMovementListFiltersService extends AbstractFiltersFieldsService {
  public startDateField: TextField = new TextField({
    fieldName: 'startDate',
    value: null,
    hidden: true,
  });

  public endDateField: TextField = new TextField({
    fieldName: 'endDate',
    value: null,
    hidden: true,
  });

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    public warehousesResource: WarehousesResource
  ) {
    super(formNotifier, state);
  }

  public createdDateField: TextField = new TextField({
    fieldName: 'createdAt',
    value: null,
    hidden: true,
  });

  public getFields(): any[] {
    const startDate = this.filters.get('startDate');
    const endDate = this.filters.get('endDate');
    const firstFields = ['ecospace', 'alicedeals', 'lsl'];

    return [
      new DropDownListField({
        fieldName: 'fromWarehouse',
        label: 'PAGE.STOCK_MOVEMENT.FILTERS.FROM_WAREHOUSE',
        data: SessionHelper.getWarehouses().map((warehouse: any): any => {
          return {
            value: warehouse.id,
            name: warehouse.name,
          };
        }),
        textField: 'name',
        valueField: 'value',
        value: this.filters.get('fromWarehouse') ? this.filters.get('fromWarehouse') : null,
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),
      new DropDownListField({
        fieldName: 'toWarehouse',
        label: 'PAGE.STOCK_MOVEMENT.FILTERS.TO_WAREHOUSE',
        data: SessionHelper.getWarehouses().map((warehouse: any): any => {
          return {
            value: warehouse.id,
            name: warehouse.name,
          };
        }).sort((a, b) => firstFields.indexOf(a.name) - firstFields.indexOf(b.name)),
        textField: 'name',
        valueField: 'value',
        value: this.filters.get('toWarehouse') ? this.filters.get('toWarehouse') : null,
        defaultItem: { name: '', value: null },
        valuePrimitive: true,
      }),
      new DateField({
        fieldName: 'date',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.DATE',
        dateRange: true,
        value:
          this.filters.get('startDate') && this.filters.get('endDate')
            ? [new Date(startDate), new Date(endDate)]
            : undefined,
        valueChangedAction: setDates.bind(this),
      }),
      new DropDownListField({
        fieldName: 'status',
        label: 'PAGE.STOCK_MOVEMENT.FILTERS.STATUS',
        data: [
          { label: this.$translate.instant('PAGE.STOCK_MOVEMENT.FILTERS.STATUS_IMPORTED'), value: 'done' },
          { label: this.$translate.instant('PAGE.STOCK_MOVEMENT.FILTERS.STATUS_NOT_IMPORTED'), value: 'processing' },
        ],
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('status') ? this.filters.get('status') : null,
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),
      this.startDateField,
      this.endDateField,
    ];
  }
}
