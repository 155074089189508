import { IHydraMember } from '@interfaces/hydra-resource.interface';
import * as moment from 'moment';
import { DATE_SHORT_FORMAT } from '@constants';

export class DeliveryNoteListModel {
  id: number;
  date: string;
  sageIdentifier?: string;
  supplierLabel?: string;
  containerNumber?: string;

  constructor(deliveryNote: IHydraMember) {
    this.id = deliveryNote.id;
    this.date = deliveryNote.date ? moment(deliveryNote.date).format(DATE_SHORT_FORMAT) : null;
    this.sageIdentifier = deliveryNote.sageIdentifier;
    this.supplierLabel = deliveryNote.supplier ? deliveryNote.supplier.label : null;
    this.containerNumber = deliveryNote.containerNumber;
  }
}
