import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {SessionHelper, StringHelper} from '@helpers';
import { AuthService, FormNotifierService } from '@services';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { CommercialOperationsResource } from '../resources/commercial-operations.resource';
import { FormService } from './form.service';
import {ICountry, IFormViolation, IMarketplace, ISku} from '@interfaces';
import { ICommercialOperations, ICommercialOperationsTranslation } from '../models/commercial-operations.interface';
import { Subscription } from 'rxjs/Subscription';
import { AbstractResource, MarketplaceResource } from '@resources';
import { SnackbarService } from '../../snackbar/snackbar.service';
import {
  COMMERCIAL_OPERATION_BUNDLE,
  COMMERCIAL_OPERATION_CLASSICAL,
  COMMERCIAL_OPERATION_CREDIT,
  COMMERCIAL_OPERATION_FLASH_SALE,
  COMMERCIAL_OPERATION_SALE,
  COMMERCIAL_OPERATION_NEW,
  COMMERCIAL_OPERATION_GOOD_DEALS,
  COMMERCIAL_OPERATION_RED_PRICE,
} from '../constants/commercial-operations.constants';
import { DATE_FULL_FORMAT, MARKETPLACES_AG } from '@constants';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { Observable } from 'rxjs/Observable';
import moment = require('moment');
import { NO_INDEX_NO_FOLLOW } from '@components/categories';
import {HydraHelper} from '@helpers/HydraHelper';
import {ICatalogs} from '@components/catalogs/models';

interface IOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-commercial-operation-form',
  template: require('./commercial-operation-form.component.html'),
  styles: [require('./commercial-operation-form.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CommercialOperationsResource },
  ],
})
export class CommercialOperationFormComponent extends AbstractFormComponent implements OnInit, OnDestroy {

  public showMarketplace = false;
  public form: FormGroup;
  public countries: ICountry[];
  public marketplaces: any[] = [];
  public operationTypes: any[] = [];
  public sortingOptions: IOption[] = [];
  public hideField: boolean;
  public violations: IFormViolation[] | any = [];
  public marketplacesChecked: any[] = [];
  public currentMarketplaces: any[] = [];
  public childToArchive: any[] = [];

  @Input() public model: ICommercialOperations;
  @Input() public inCreation: boolean;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    public formNotifier: FormNotifierService,
    public resource: AbstractResource,
    private marketplaceResource: MarketplaceResource,
    private formService: FormService,
    private snackbar: SnackbarService
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.countries = SessionHelper.getCountries();
    this.form = this.formService.getForm(this.model);

    if (!this.inCreation && this.form.get('marketplace').value) {
      this.showMarketplace = true;
    }

    if (this.isParent()) {
      this.resource.cGet({'parent': this.state.params.id})
        .takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          response['hydra:member'].forEach((market: any) => {
            this.marketplaces.forEach((marketplace: IMarketplace) => {
              if (marketplace.commercialName === market.marketplace && !market.archived) {
                marketplace.checked = true;
                this.currentMarketplaces.push(market);
              }
            });
          });
        });
    }

    if (!this.inCreation) {
      const arrayControls = this.form.get('translations') as FormArray;
      this.model.translations.forEach((translation: ICommercialOperationsTranslation) => {
        arrayControls.push(this.formService.createTranslationForm(translation));
      });

      this.fetchMarketplaces(this.model.country.code);
    } else {
      this.form.get('country').setValue(SessionHelper.getCountry());
      this.updateMarketplaceFieldData(SessionHelper.getCountry());
    }

    this.form.statusChanges.subscribe(() => {
      if (this.form.dirty) {
        this.formNotifier.notifyFormInEdition();
      }
    });

    if (this.form.controls.archived.value) {
      this.form.disable();
    }
  }

  ngOnDestroy(): void {
    this.formNotifier.notifyFormIsDestroyed();
  }

  public isParent(): boolean {
    return !this.inCreation && null === this.form.get('marketplace').value;
  }

  /**
   * Fired at component init then when switching country.
   */
  public updateMarketplaceFieldData(country: ICountry): void {
    this.prepareTranslationsForForm(country);
    this.fetchMarketplaces(country.code);
  }

  /**
   * When the user select another country, we have to clean then prepare translations form array.
   */
  private prepareTranslationsForForm(country: ICountry): void {
    const arrayControls = this.form.get('translations') as FormArray;

    while (arrayControls.length !== 0) {
      arrayControls.removeAt(0);
    }

    country.locales.forEach((locale: string) => {

      const translation: ICommercialOperationsTranslation = {
        title: '',
        description: '',
        locale: locale,
      };

      arrayControls.push(this.formService.createTranslationForm(translation));
    });
  }

  private fetchMarketplaces(countryCode: string): void {
    const subscriber: Subscription = this.marketplaceResource.filterByCountryCode(countryCode)
      .subscribe((response: any) => {
        this.marketplaces = response.sort((a: any, b: any) => a.code.localeCompare(b.code));
        if (!this.inCreation && this.currentMarketplaces.length === 0) {
          const marketplace = response.find((item: any) => item.code === this.model.marketplace);
          this.currentMarketplaces.push(marketplace);
        }
        this.updateOperationTypeFieldData(this.currentMarketplaces);
      }, undefined, () => subscriber.unsubscribe());
  }

  public toggleCheck(): void {
    const checkedValue = !this.hasSomeChecked();

    this.marketplaces.forEach((marketplace: IMarketplace) => {
      marketplace.checked = checkedValue && !marketplace.checked;
    });
  }

  public hasSomeChecked(): boolean {
    return this.marketplaces.some(marketplace => marketplace.checked);
  }

  private updateOperationTypeFieldData(marketplaces: any): void {
    if (marketplaces.length === 1 && MARKETPLACES_AG.includes(marketplaces[0].code)) {
      this.operationTypes = [COMMERCIAL_OPERATION_CLASSICAL, COMMERCIAL_OPERATION_FLASH_SALE, COMMERCIAL_OPERATION_SALE,
        COMMERCIAL_OPERATION_BUNDLE, COMMERCIAL_OPERATION_CREDIT, COMMERCIAL_OPERATION_NEW, COMMERCIAL_OPERATION_GOOD_DEALS, COMMERCIAL_OPERATION_RED_PRICE];
      this.form.get('defaultSorting').setValidators(Validators.required);
      this.form.get('defaultSorting').updateValueAndValidity();
      this.hideField = false;
    } else {
      this.operationTypes = [COMMERCIAL_OPERATION_CLASSICAL, COMMERCIAL_OPERATION_SALE];
      this.form.get('defaultSorting').clearValidators();
      this.form.get('defaultSorting').updateValueAndValidity();
      this.hideField = true;
    }

    let type = this.operationTypes[0];

    if (!this.inCreation) {
      type = this.operationTypes.find((item) => item.value === this.model.type);
    }

    this.form.get('type').setValue(type);
    this.initDefaultSortingChoice(type.value);
  }

  public updateMarketplace(marketplace: any): void {
    this.currentMarketplaces.forEach((current: any) => {
      if (current.marketplace === marketplace.commercialName && !marketplace.checked) {
        this.childToArchive.push(marketplace);
      } else if (marketplace.checked && this.childToArchive[marketplace]) {
        this.childToArchive = this.childToArchive.filter((item: any) => item !== marketplace);
      }
    });

    if (!this.childToArchive[marketplace]) {
      if (marketplace.checked) {
        this.marketplacesChecked.push(marketplace);
      } else {
        this.marketplacesChecked = this.marketplacesChecked.filter((item: any) => item !== marketplace);
      }
    }

    this.updateOperationTypeFieldData(this.marketplacesChecked);
  }

  /**
   * Checks if country chosen has several locales.
   */
  public hasSeveralTranslation(): boolean {
    return this.form.get('country').value.locales.length > 1;
  }

  /**
   * Gets translation form necessary for FormTabTranslation.
   */
  public getTranslation(key?: string): FormGroup {
    const translations: FormArray = this.form.get('translations') as FormArray;

    if (this.hasSeveralTranslation() && undefined !== key) {
      return translations.controls.find((group: FormGroup) => key === group.get('locale').value) as FormGroup;
    }

    return translations.controls[0] as FormGroup;
  }

  public cancel(): void {
    this.dialog.confirm(this.translate('PAGE.CATEGORY.CONFIRM.BACK_TO_LIST'))
      .then(() => this.state.go(`${this.resource.routeName}.list`));
  }

  public setColorValue(color: Event, name: string): void {
    this.form.get(name).setValue(color);
    this.form.markAsDirty();
  }

  public isFlashSale(): boolean {
    return COMMERCIAL_OPERATION_FLASH_SALE.value === this.form.controls.type.value.value;
  }

  public isCredit(): boolean {
    return COMMERCIAL_OPERATION_CREDIT.value === this.form.controls.type.value.value;
  }

  public isNew(): boolean {
    return COMMERCIAL_OPERATION_NEW.value === this.form.controls.type.value.value;
  }

  public isBundle(): boolean {
    return COMMERCIAL_OPERATION_BUNDLE.value === this.form.controls.type.value.value;
  }

  public isGoodDeals(): boolean {
    return COMMERCIAL_OPERATION_GOOD_DEALS.value === this.form.controls.type.value.value;
  }

  public getTranslatableString(): string {
      const type = this.form.get('defaultSorting').value;
      if (undefined === type) {
        return '';
      }

      return this.translate(`PAGE.COMMERCIAL_OPERATIONS.EMERCH.DESCRIPTION.${type.replace(' ', '_').toUpperCase()}`);
    }

  public saveChild(marketplaces: any[], body: ICommercialOperations, parentId: number): void {
    if (!marketplaces.length) {
      return;
    }

    const marketplace = marketplaces.shift();

    body.marketplace = marketplace ? marketplace.code : null;
    body.parent = parentId ? HydraHelper.buildIri(parentId, 'commercial_operations') : null;

    this.save(body, parentId)
      .takeUntil(this.destroyed$)
      .subscribe(() => {
          this.saveChild(marketplaces, body, parentId);
        }
      );
  }

  public async submit(): Promise<void> {
    this.form.get('marketplaces').setValue(this.marketplacesChecked);
    const body: ICommercialOperations = this.formService.prepareBody(this.form.value);

    this.archiveChild();
    await this.unArchiveChild(body);

    if (this.inCreation && body.marketplaces.length > 1) {
      this.save(body)
        .takeUntil(this.destroyed$)
        .subscribe(
          (response: any) => {
            const marketplaces: any = body.marketplaces;
            this.saveChild(marketplaces, body, response.id);
            this.state.go(`${this.resource.routeName}.edit`, { id: response.id }, { reload: true });
          }
        );
    } else if (!this.inCreation && body.marketplace === null) {
      if (body.marketplaces.length > 0) {
        body.marketplaces.forEach((marketplace: any) => {
          this.updateCommercialOperation(body, marketplace, this.state.params.id);
        });
      } else {
        this.save(body).takeUntil(this.destroyed$).subscribe();
      }
      this.updateChildCommercialOperation(body);
    } else {
      this.updateCommercialOperation(body, body.marketplaces[0]);
    }
  }

  public updateCommercialOperation(body: ICommercialOperations, marketplace: any = null, parentId: number = null): void {
    body.marketplace = marketplace ? marketplace.code : null;
    body.parent = parentId ? HydraHelper.buildIri(parentId, 'commercial_operations') : null;

    this.save(body, parentId)
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: any) => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
          if (!this.inCreation) {
            this.formNotifier.notifyFormSubmitted();
          }
          if (this.state.params.id) {
            this.state.go(`${this.resource.routeName}.edit`, { id: this.state.params.id }, { reload: true });
          } else {
            this.state.go(`${this.resource.routeName}.edit`, { id: response.id }, { reload: true });
          }
        }
      );
  }

  public save(body: ICommercialOperations, hasParent: number = null): Observable<any> {
    body.startDate = moment(body.startDate, DATE_FULL_FORMAT).isValid()
      ? moment(body.startDate, DATE_FULL_FORMAT).seconds(0).format(DATE_FULL_FORMAT)
      : null;
    body.endDate = moment(body.endDate, DATE_FULL_FORMAT).isValid()
      ? moment(body.endDate, DATE_FULL_FORMAT).seconds(59).format(DATE_FULL_FORMAT)
      : null;

    for (const locale in body.translations) {
      if (body.translations.hasOwnProperty(locale)) {
        if (hasParent) {
          delete body.translations[locale].id;
        }
        body.translations[locale].seoMetaRobot = NO_INDEX_NO_FOLLOW;
      }
    }

    return !this.inCreation && null === body.marketplace ?
      this.resource.update(this.state.params.id, body) :
      this.resource.create(body)
    ;
  }

  public archiveChild(): void {
    if (this.childToArchive && this.state.params.id) {
      this.childToArchive.forEach((child: any) => {
        this.resource.cGet({'parent': this.state.params.id, 'marketplace': child.code})
          .takeUntil(this.destroyed$)
          .subscribe((response: any) => {
            this.resource.update(response['hydra:member'][0].id, {'archived': true})
              .takeUntil(this.destroyed$)
              .subscribe(() => {});
          });
      });
    }
  }

  public async unArchiveChild(body: any): Promise<void> {
    const updatePromises = body.marketplaces.map((marketplace: any) => {
      return new Promise<void>((resolve, reject) => {
        if (this.state.params.id) {
          this.resource.cGet({'parent': this.state.params.id, 'marketplace': marketplace.code, 'archived': true})
            .takeUntil(this.destroyed$)
            .subscribe((response: any) => {
              if (response['hydra:member'].length > 0) {
                this.resource.update(response['hydra:member'][0].id, {'archived': false})
                  .takeUntil(this.destroyed$)
                  .subscribe(() => {
                    body.marketplaces = body.marketplaces.filter((item: any) => item !== marketplace);
                    resolve();
                  }, error => reject(error));
              } else {
                resolve();
              }
            }, error => reject(error));
        } else {
          resolve();
        }
      });
    });

    await Promise.all(updatePromises);
  }

  public updateChildCommercialOperation(body: any): void {
    this.currentMarketplaces.forEach((marketplace: any) => {
      body.startDate = moment(body.startDate, DATE_FULL_FORMAT).isValid()
        ? moment(body.startDate, DATE_FULL_FORMAT).seconds(0).format(DATE_FULL_FORMAT)
        : null;
      body.endDate = moment(body.endDate, DATE_FULL_FORMAT).isValid()
        ? moment(body.endDate, DATE_FULL_FORMAT).seconds(59).format(DATE_FULL_FORMAT)
        : null;

      this.resource.update(marketplace.id, {
        'startDate': body.startDate,
        'endDate': body.endDate
      }).takeUntil(this.destroyed$).subscribe();
    });
  }

  public isArchived(): boolean {
    return this.form.controls.archived.value;
  }

  public getTypeTooltip(): string {
    const type = this.form.get('type').value.value;

    if (undefined === type) {
      return '';
    }

    return this.translate(`PAGE.COMMERCIAL_OPERATIONS.FORM.HELP.${type.replace(' ', '_').toUpperCase()}`);
  }

  public initDefaultSortingChoice(type: any) {
    this.sortingOptions = [
      { label: this.translate('PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.DEFAULT_SORTING.VALUES.MANUAL'), value: 'manual' },
      { label: this.translate('PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.DEFAULT_SORTING.VALUES.PRICE_ASC'), value: 'price asc' },
      { label: this.translate('PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.DEFAULT_SORTING.VALUES.PRICE_DESC'), value: 'price desc' },
    ];

    switch (type) {
      case COMMERCIAL_OPERATION_CLASSICAL.value:
      case COMMERCIAL_OPERATION_FLASH_SALE.value:
      case COMMERCIAL_OPERATION_SALE.value:
        this.sortingOptions.push(
          {label: this.translate('PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.DEFAULT_SORTING.VALUES.DEFAULT_MERCHANDISING'), value: 'default_merchandising' }
        );
        break;
      case COMMERCIAL_OPERATION_NEW.value:
        this.sortingOptions.push(
          { label: this.translate('PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.DEFAULT_SORTING.VALUES.NOVELTY_MERCHANDISING'), value: 'novelty_merchandising' },
        );
        break;
      case COMMERCIAL_OPERATION_GOOD_DEALS.value:
        this.sortingOptions.push(
          { label: this.translate('PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.DEFAULT_SORTING.VALUES.GOOD_DEAL_MERCHANDISING'), value: 'good_deal_merchandising' },
        );
        break;
    }
  }
}
