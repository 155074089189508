import {Component, ComponentFactoryResolver, Inject, Input} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { GenericResultListComponent } from '@components/generic/List';
import { UrlHelper } from '@helpers';
import { SnackbarService } from '@components/snackbar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { FormService } from '@services/form.service';
import { StockMovementLineResource, StockMovementResource } from '@resources';
import { StockMovementModel } from './stock-movement.model';

@Component({
  selector: 'app-stock-movement-result-list',
  template: require('./stock-movement-result-list.component.html'),
  providers: [
    FormService,
    { provide: AbstractResource, useClass: StockMovementLineResource },
  ],
})
export class StockMovementResultListComponent extends GenericResultListComponent {
  public transferFormData: any = null;
  public transferForm: FormGroup = new FormGroup({
    receivedQuantity: new FormControl(null, Validators.required)
  });

  @Input() public type: string = 'repatriation';
  @Input() public route: string = 'stock-movement.detail';


  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('DialogService') dialog: any,
    urlHelper: UrlHelper,
    componentFactoryResolver: ComponentFactoryResolver,
    public stockMovementResource: StockMovementResource,
    private snackbar: SnackbarService,
  ) {
    super($translate, state, authService, resource, dialog, urlHelper, componentFactoryResolver);
  }

  openTransferModal(id: number, receivedQuantity: number): void {
    this.transferFormData = { id: id, receivedQuantity: receivedQuantity };
    this.transferForm.get('receivedQuantity').setValue(receivedQuantity);
  }

  closeTransferModal(): void {
    this.transferFormData = null;
    this.transferForm.get('receivedQuantity').setValue(null);
  }

  refreshMovement(id: number): void {
    const observable: Observable<object> = this.stockMovementResource.get(null, { entryPoint: this.stockMovementResource.entryPoint + '/' + id });
    observable.takeUntil(this.destroyed$).subscribe((response: any): void => {
      const index: number = this.items.findIndex((item: any): boolean => item.id === id);
      this.items[index].status = response.status;
      this.items[index].received = response.received;
      this.items[index].transferred = response.transferred;
    });
  }

  transfer(id: number): void {
    const observable: Observable<object> = this.resource.update(
      null,
      { received: true, transferred: true, receivedQuantity: this.transferForm.value.receivedQuantity },
      { entryPoint: '/v2/stock_movement_lines/' + id }
    );

    observable
      .takeUntil(this.destroyed$)
      .subscribe((): void => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        setTimeout((): void => {
          this.state.go(this.state.current, this.state.params, {reload: true});
        }, 100);
      })
    ;
  }

  goToOrder(orderId: string): void {
    this.state.go('order_manager.edit', { id: orderId });
  }

  editMovement(id: number): void {
    this.state.go(this.route, { id: id });
  }
}
