import { AbstractResource } from '@resources';
import { Injectable } from '@angular/core';
import { CMSListModel, CMSModel } from '@components/cms/models';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';
import {SessionHelper} from '@helpers';

@Injectable()
@Resource({
  routeName: 'cms',
  entryPoint: '/v2/content_blocks',
  translationKey: 'CMS',
  listModel: CMSListModel,
  formModel: CMSModel,
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    'translations.active': true,
  }),
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'name', translationKey: 'TITLE', type: 'string' },
    { field: 'slug', translationKey: 'SLUG', type: 'string' },
    { field: 'status', translationKey: 'STATUS', type: 'string' },
    { field: 'hasTranslation', translationKey: 'HASTRANSLATION', type: 'string' },
  ],
  createAvailable: true,
  deleteAvailable: true,
  customDeleteAvailable: true,
  updateAvailable: true,
  isHydra: true,
  buttonActionMultipleAvailable: true,
})
export class CMSResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
