export class ObjectTrimmer {

  // Trim off whitespaces from the form before submitting it (recursively trimming if necessary)
  trimObjectValues(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map(item => this.trimObjectValues(item));
    }
    const trimmedObj: any = {};

    for (const key of Object.keys(obj)) {
      const value = obj[key];
      if (value instanceof Date) {
        trimmedObj[key] = value.toISOString().slice(0, -5);
      } else if (typeof value === 'object' && value !== null) {
        trimmedObj[key] = this.trimObjectValues(value);
      } else if (typeof value === 'string') {
        trimmedObj[key] = value.trim();
      } else {
        trimmedObj[key] = value;
      }
    }
    return trimmedObj;
  }
}
