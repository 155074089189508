export default deliveryNoteRoutes;

/** @ngInject */
function deliveryNoteRoutes($stateProvider: any): void {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'delivery_note',
    title: '',
  };

  $stateProvider
    .state('delivery_note', {
      url: '/delivery-notes',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'PAGE.DELIVERY_NOTE.BREADCRUMB',
        stateName: 'delivery_note.list'
      },
    })
    .state('delivery_note.list', {
      url: `?page
        &byPage
        &commercialEntity
        &sageIdentifier[]
        &supplier.label
        &containerNumber[]
        &startDate
        &endDate
      `,
      views: {
        'page@layout': {
          template: '<app-delivery-note-list></app-delivery-note-list>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DELIVERY_NOTE.LIST.TITLE'
      })
    })
    .state('delivery_note.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      params: {
        supplierId: null,
      },
      views: {
        'page@layout': {
          template: '<app-delivery-note-form></app-delivery-note-form>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DELIVERY_NOTE.FORM.TITLE_WITHOUT_ID',
      })
    })
    .state('delivery_note.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': {
          template: '<app-delivery-note-form></app-delivery-note-form>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DELIVERY_NOTE.FORM.TITLE',
      })
    })
  ;
}
