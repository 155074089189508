export default containerRoutes;

/** @ngInject */
function containerRoutes($stateProvider: any): void {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'containerList',
    title: '',
  };

  $stateProvider
    .state('container', {
      url: '/containers',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.CONTAINER',
        stateName: 'container.list'
      },
    })
    .state('container.list', {
      url: `?page
        &byPage
        &commercialEntity
        &containerNumber[]
        &sageIdentifier[]
        &supplier.label
        &hasTracking
      `,
      views: {
        'page@layout': {
          template: '<app-container-list></app-container-list>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CONTAINER.LIST.TITLE'
      })
    })
    .state('container.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      params: {
        supplierId: null,
      },
      views: {
        'page@layout': {
          template: '<app-container-form></app-container-form>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CONTAINER.FORM.TITLE_WITHOUT_ID',
      })
    })
    .state('container.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': {
          template: '<app-container-form></app-container-form>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CONTAINER.FORM.TITLE',
      })
    })
  ;
}
