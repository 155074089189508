import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AbstractResource, IRequestOptions} from '@resources';
import { DeliveryNoteModel } from '@components/delivery-note/models/delivery-note.model';
import { Observable } from 'rxjs/Observable';
import { DeliveryNoteListModel } from '@components/delivery-note/models/delivery-note-list.model';

@Injectable()
@Resource({
  routeName: 'delivery_note',
  entryPoint: '/v2/delivery_notes',
  translationKey: 'DELIVERY_NOTE',
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    'order[id]': 'DESC'
  }),
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id', sortable: true },
    { field: 'supplierLabel', translationKey: 'SUPPLIER_LABEL', type: 'string', sortable: false },
    { field: 'date', translationKey: 'DATE', type: 'date', sortable: true },
    { field: 'sageIdentifier', translationKey: 'SAGE_IDENTIFIER', type: 'string', sortable: false },
    { field: 'containerNumber', translationKey: 'CONTAINER_NUMBER', type: 'string', sortable: false }
  ],
  listModel: DeliveryNoteListModel,
  formModel: DeliveryNoteModel,
  createAvailable: true,
  deleteAvailable: true,
  isHydra: true,
})
export class DeliveryNoteResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public cGet(params: any = {}, options: IRequestOptions = {}, headers?: HttpHeaders): Observable<object> {
    params['order[id]'] = params.sort_id;
    params['order[date]'] = params.sort_date;

    const cleanParams: any = Object.keys(params)
      .filter((key) => !key.startsWith('sort_') && !key.startsWith('exists_'))
      .filter((key) => typeof params[key] !== 'undefined')
      .reduce((obj, key) => {
        return Object.assign(obj, { [key]: params[key] });
      }, {})
    ;

    if (0 === Object.keys(cleanParams).filter((key: string) => key.startsWith('order[')).length) {
      cleanParams['order[id]'] = 'desc';
    }

    const parameters: {[key: string]: any} = cleanParams;

    if (parameters && parameters.hasOwnProperty('startDate')) {
      Object.assign(parameters, { 'date[after]': parameters['startDate'] });
      delete parameters['startDate'];
      delete parameters['createdAt'];
    }

    if (parameters && parameters.hasOwnProperty('endDate')) {
      Object.assign(parameters, { 'date[before]': parameters['endDate'] });
      delete parameters['endDate'];
      delete parameters['createdAt'];
    }

    return super.cGet(parameters, options, headers);
  }
}
