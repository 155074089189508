import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormNotifierService } from '@services/form-notifier.service';
import {
  IFieldOptions,
  IFileFieldOptions,
  IRteFieldOptions,
  ISelectFieldOptions,
} from '@components/generic/Form/form-control/interfaces/field-options.interface';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { WysiwygResource } from '@resources/wysiwyg.resource';
import { BASE_URL } from '@constants/config.constants';
import * as moment from 'moment';
import { DATE_SHORT_FORMAT } from '@constants/date';
import { FORM_STREAMS } from '../../../../enums/form-notifier-streams.enum';
import { FormService } from '@services/form.service';
import { CENTER_TITLE, CENTER_TEXT, INSERT_LEFT_AND_TEXT_RIGHT, IMAGE_LEFT_AND_TEXT_RIGHT,
  IMAGE_RIGHT_AND_TEXT_LEFT, TRIPLE_IMAGES, TRIPLE_TEXT, FULL_WIDTH_IMAGE, BLOCK_TITLE_WITH_TEXT, FULL_WIDTH_VIDEO } from './templates';
import { EditorComponent } from '@tinymce/tinymce-angular';

/**
 * Handles the form control.
 *
 * Use a default template to display the form control with a label and a field.
 * When the form is submitted but there are errors ui form validation or there are errors from api validation,
 * we mark the field and the form as invalid in the ui then display an alert text.
 */
@Component({
  selector: 'app-form-control',
  template: require('./form-control.component.html'),
  styles: [require('./form-control.component.scss')]
})
export class FormControlComponent extends AbstractComponent implements OnInit, OnChanges {

  /**
   * Is the form control has Validators.required.
   */
  public isFormControlRequired = false;

  /**
   *  Options required to build the field.
   */
  public options: IFieldOptions | ISelectFieldOptions | IRteFieldOptions | IFileFieldOptions;

  public validationErrors: ValidationErrors | undefined;

  private uiViolationForRequiredField: boolean;
  private uiViolationFieldFromApi: boolean;


  @ViewChild(EditorComponent) editorComponent: EditorComponent;

  /**
   * The form control is required for binding control value and handle validation.
   * (It would be named formControl, but angular use it in the reactive form)
   */
  @Input() public control: AbstractControl;

  /**
   * Options sets according to the type of the form control.
   */
  @Input() public fieldOptions?: IFieldOptions;
  @Input() public selectFieldOptions?: ISelectFieldOptions;
  @Input() public rteFieldOptions?: IRteFieldOptions;
  @Input() public fileFieldOptions?: IFileFieldOptions;

  /**
   * Add the locale when you want to get error translations from api.
   */
  @Input() public locale?: string;
  /**
   * Bypass the standard validation from FormControlComponent (in case like discount form where a form is present everywhere)
   */
  @Input() public invalidField: boolean;
  @Input() public warningField: boolean = false;

  @Output() public valueChange: EventEmitter<object> = new EventEmitter();

  get isControlValid(): boolean {
    if (this.isFormControlRequired && !this.control.value && this.control.dirty) {
      return false;
    }

    if (this.isFormControlRequired && !this.control.value && this.uiViolationForRequiredField) {
      return false;
    }

    if (this.isFormControlRequired && !this.control.value) {
      return true;
    }

    if (this.uiViolationFieldFromApi) {
      return false;
    }

    return this.control.valid;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formNotifier: FormNotifierService,
    private rteResource: WysiwygResource,
    private formService: FormService,
  ) {
    super($translate, authService, null, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    if (!this.control && (!this.fieldOptions || !this.selectFieldOptions)) {
      throw new Error('Attributes \'control\' and \'field options\' are required. Check in your form and template');
    }

    this.initFieldOptions();

    const validators = this.control.validator;

    if (validators && validators({} as AbstractControl)) {
      this.isFormControlRequired = validators({} as AbstractControl).required;
    }

    this.listenFormEvents();
  }

  /**
   * @inheritDoc
   */
  ngOnChanges(changes: SimpleChanges) {

    if (this.editorComponent && this.rteFieldOptions && changes.control && changes.control.currentValue.value) {
      this.editorComponent.writeValue(changes.control.currentValue.value);
    }

    if (this.selectFieldOptions && this.options && changes.selectFieldOptions) {
      if (this.selectFieldOptions.data) {
        (<ISelectFieldOptions>this.options).data = changes.selectFieldOptions.currentValue.data;
      }

      if (this.selectFieldOptions.asyncData && this.options) {
        (<ISelectFieldOptions>this.options).asyncData = changes.selectFieldOptions.currentValue.asyncData;
      }

      if (this.selectFieldOptions.countryCode && this.options) {
        (<ISelectFieldOptions>this.options).countryCode = changes.selectFieldOptions.currentValue.countryCode;
      }
    }

    if (this.fieldOptions && this.options) {
      if (this.fieldOptions.hasOwnProperty('readonly') && changes.fieldOptions) {
        (<IFieldOptions>this.options).readonly = changes.fieldOptions.currentValue.readonly;
      }
    }

    if (this.selectFieldOptions && this.options) {
      if (this.selectFieldOptions.hasOwnProperty('readonly') && changes.selectFieldOptions) {
        (<ISelectFieldOptions>this.options).readonly = changes.selectFieldOptions.currentValue.readonly;
      }
    }

    if (this.fileFieldOptions && this.options) {
      if (this.fileFieldOptions.hasOwnProperty('imageUrl') && changes.fileFieldOptions) {
        (<IFileFieldOptions>this.options).imageUrl = changes.fileFieldOptions.currentValue.imageUrl;
      }
    }
  }

  /**
   * When user update the field value, we update the form control value.
   */
  public updateControlValue(value: any, options?: any): void {
    this.control.markAsDirty();

    if (options && options.hasOwnProperty('type') && 'single-date' === options.type) {
      value = value ? moment(value).format(options.dateInputFormat) : null;
    }

    if (options && options.hasOwnProperty('valuePrimitive') && options.hasOwnProperty('valueField')) {
      this.control.patchValue(options.valuePrimitive && options.valueField ? value[options.valueField] : value);
    } else {
      if (options && options.control) {
        options.control.patchValue(value);
        if ('rte' === this.options.type) {
          this.editorComponent.setDisabledState(!value);
        } else {
          this.options.readonly = !value;
        }

      } else {
        this.control.patchValue(value);
      }
    }

    if (this.uiViolationFieldFromApi) {
      this.uiViolationFieldFromApi = false;
    }

    if (this.validationErrors) {
      this.validationErrors = null;
    }

    if (this.invalidField) {
      this.invalidField = false;
    }

    this.valueChange.emit(value);
  }

  public computeComponentClasses(): string {
    let labelColumn = !this.options.label || this.options.hideLabel ? 0 : 2;
    if (this.options.labelColumn === 0) {
      labelColumn = this.options.labelColumn;
    }
    let classes = `col-sm-${12 - (labelColumn)}`;

    if (!this.options.label && !this.options.hideLabel) {
      classes += ` col-sm-offset-${labelColumn}`;
    }

    return classes;
  }

  /**
   * Init the options for the html field.
   */
  private initFieldOptions(): void {
    if (this.fieldOptions) {
      this.options = {
        controlName: '',
        type: 'text',
        dateInputFormat: DATE_SHORT_FORMAT,
        ...this.fieldOptions,
      };
    }

    if (this.selectFieldOptions) {
      this.options = {
        controlName: '',
        type: 'comboBox',
        allowCustom: false,
        valuePrimitive: false,
        ...this.selectFieldOptions,
      };
    }

    if (this.fileFieldOptions) {
      this.options = {
        type: 'file',
        multipleFiles: false,
        ...this.fileFieldOptions,
      };
    }

    if (this.rteFieldOptions) {
      const initOptions = {
        base_url: '/vendor/tinymce',
        plugins: ['code image media fullscreen imagetools template link'],
        menubar: false,
        // tslint:disable-next-line:max-line-length
        toolbar: 'formatselect | bold italic | image media | alignleft aligncenter alignright alignjustify | outdent indent | code | link | fullscreen | template',
        block_formats: 'Paragraphe=p;Titre 3=h3',
        height: 800,
        content_css: false,
        templates: [
          { title: 'Titre', description: '', content: CENTER_TITLE },
          { title: 'Texte centré', description: '', content: CENTER_TEXT },
          { title: 'Encart à gauche et texte à droite', description: '', content: INSERT_LEFT_AND_TEXT_RIGHT },
          { title: 'Triples images avec titres et légendes', description: '', content: TRIPLE_IMAGES },
          { title: 'Bloc information avec titre', description: '', content: BLOCK_TITLE_WITH_TEXT },
          { title: 'Image simple à droite avec titre et texte à gauche', description: '', content: IMAGE_RIGHT_AND_TEXT_LEFT },
          { title: 'Image simple à gauche avec titre et texte à droite', description: '', content: IMAGE_LEFT_AND_TEXT_RIGHT },
          { title: 'Triple colonnes avec titres', description: '', content: TRIPLE_TEXT },
          { title: 'Image pleine largeur', description: '', content: FULL_WIDTH_IMAGE },
          { title: 'Vidéo pleine largeur', description: '', content: FULL_WIDTH_VIDEO},
        ],
        images_upload_handler: (blobInfo: any, success: (url: string) => {}, failure: (message: string) => {}) => {
          this.rteResource.upload(blobInfo.blob())
            .takeUntil(this.destroyed$)
            .subscribe((response: { path: string; }) => {
              success(`${BASE_URL}/${response.path}`);
            }, (reject: any) => {
              failure(`HTTP Error: ${reject.status}`);
              return;
            })
          ;
        },
        ...this.rteFieldOptions.initOptions
      };

      delete this.rteFieldOptions.initOptions;

      this.options = {
        controlName: '',
        type: 'rte',
        ...this.rteFieldOptions,
        initOptions,
      };
    }
  }

  public valueNormalizer = (text: Observable<string>) => text.pipe(map(() => ({ id: undefined })));

  private listenFormEvents(): void {
    this.formNotifier.observable
      .takeUntil(this.destroyed$)
      .subscribe((stream: any) => {

        if (!stream || !this.formService.formIsSubmitted) {
          return;
        }

        if (stream.hasOwnProperty('event') && FORM_STREAMS.formUiHasErrors === stream.event) {
          if (this.control.errors && this.control.errors.hasOwnProperty('required')) {
            this.uiViolationForRequiredField = true;
          }
        }

        if (stream.hasOwnProperty('apiErrors')) {
          if (!stream['apiErrors']) {
            return;
          }

          const apiErrors = stream['apiErrors'];

          if (Array.isArray(apiErrors)) {
            this.validationErrors = {
              apiErrors: apiErrors.map((error) => {
                if (error.propertyPath === this.options.controlName) {
                  this.uiViolationFieldFromApi = true;

                  return error.message;
                }

                if (`translations[${this.locale}].${this.options.controlName}` === error.propertyPath) {
                  this.uiViolationFieldFromApi = true;

                  return error.message;
                }
              })
            };

            return;
          }

          if (apiErrors.hasOwnProperty(this.options.controlName)) {
            this.validationErrors = { apiErrors: apiErrors[this.options.controlName].errors };
            this.uiViolationFieldFromApi = true;
          }
        }
      })
    ;
  }
  public isVideoOrImage(filename: string): string {
    const isVideo = new RegExp(/.*\.(mp4|webm)/).test(filename);
    const isImage = new RegExp(/.*\.(png|jpg|jpeg|tiff)/).test(filename);

    return isVideo ? 'video' : isImage ? 'image' : 'UNKNOWN';
  }
  public isImage(filename: string): boolean {
    return new RegExp(/.*\.(png|jpg|jpeg|tiff)/).test(filename);
  }
  customMap(value: any) {
    return value.map((item: any) => {
      if (item.hasOwnProperty('originalPath')) {
      return {
        '@id': item['@id'],
        'id': item['id'],
        fileName: item['fileName'],
        typeOfFile: item['typeOfFile'],
        originalPath: item['originalPath'],
        src: item['originalPath'],
        name: item['originalPath'].split('/').pop(),
        uid: item['id'],
        state: 3,
        size: 0,
        other: item
      };
      }
      if (item.hasOwnProperty('name')) {
        item.typeOfFile = this.isVideoOrImage(item.name);
      }
      return item;
    });
  }
}
