import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { ArrivalModel } from '@components/container/models/arrival.model';

export class DeliveryNoteItemModel {
  '@id': string;
  id: string;
  sku: string;
  arrival?: ArrivalModel;
  quantity: number;
  quantityHistory: DeliveryNoteItemQuantityHistory[];
  cmup?: number;
  unitPrice?: number;
  totalExcludedTaxes?: number;

  constructor(deliveryNoteItem: IHydraMember) {
    this['@id'] = deliveryNoteItem['@id'];
    this.id = deliveryNoteItem.id;
    this.sku = deliveryNoteItem.masterProduct ? deliveryNoteItem.masterProduct.sku : null;
    this.arrival = deliveryNoteItem.arrival ? deliveryNoteItem.arrival : null;
    this.quantity = deliveryNoteItem.quantity;
    this.quantityHistory = deliveryNoteItem.quantityHistory
      ? deliveryNoteItem.quantityHistory.map((quantityHistory: any) => new DeliveryNoteItemQuantityHistory(quantityHistory))
      : null
    ;
    this.cmup = deliveryNoteItem.cmup;
    this.unitPrice = deliveryNoteItem.unitPrice;
    this.totalExcludedTaxes = deliveryNoteItem.totalExcludedTaxes;
  }
}

export class DeliveryNoteItemQuantityHistory {
  quantity: number;
  updatedAt: Date;
  updatedBy: string;

  constructor(quantityHistory: any) {
    this.quantity = quantityHistory.quantity;
    this.updatedAt = quantityHistory.updatedAt;
    this.updatedBy = quantityHistory.updatedBy;
  }
}
