import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { TextField, ComboSearchField, DateField } from '@components/generic/Form/dynamic/fields';
import * as moment from 'moment';
import { DATE_FULL_FORMAT } from '@constants';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { FormNotifierService } from '@services/form-notifier.service';
import { setDates } from '@helpers/DateFilterHelper';

/**
 * Constructs all filters field for the redirects list.
 */
@Injectable()
export class RedirectsFiltersFieldsService extends AbstractFiltersFieldsService {

  public redirectAutomaticFilterList = [
    {
      value: true,
      text: this.$translate.instant('PAGE.REDIRECTS.LIST.FILTERS.AUTOMATIC.AUTOMATIC')
    }, {
      value: false,
      text: this.$translate.instant('PAGE.REDIRECTS.LIST.FILTERS.AUTOMATIC.MANUAL')
    }, {
      value: undefined,
      text: this.$translate.instant('PAGE.REDIRECTS.LIST.FILTERS.AUTOMATIC.ALL')
    }
  ];

  public startDateField: TextField = new TextField({
    fieldName: 'startDate',
    value: null,
    type: 'hidden',
    order: 99
  });

  public endDateField: TextField = new TextField({
    fieldName: 'endDate',
    value: null,
    type: 'hidden',
    order: 100,
  });

  public statusCodes: number[] = [200, 301, 302, 307, 404, 500];

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  /**
   * @inheritDoc.
   */
  public getFields(): AbstractFormFieldBase<any>[] {
    const fields: AbstractFormFieldBase<any>[] = [
      new TextField({
        label: 'PAGE.REDIRECTS.LIST.FILTERS.FROM_ROUTE',
        fieldName: 'fromRoute',
        value: this.filters.get('fromRoute'),
        order: 1
      }),
      new TextField({
        label: 'PAGE.REDIRECTS.LIST.FILTERS.TO_ROUTE',
        fieldName: 'toRoute',
        value: this.filters.get('toRoute'),
        order: 2
      }),
      new ComboSearchField({
        label: 'PAGE.REDIRECTS.LIST.FILTERS.AUTOMATIC.LABEL',
        fieldName: 'automatic',
        data: this.redirectAutomaticFilterList,
        textField: 'text',
        valueField: 'value',
        valuePrimitive: true,
        value: (() => {
          const automaticFilterValue = this.filters.get('automatic');

          return 'true' === automaticFilterValue ? this.redirectAutomaticFilterList[0].value :
            'false' === automaticFilterValue ? this.redirectAutomaticFilterList[1].value :
              this.redirectAutomaticFilterList[2].value
          ;
        })(),
        order: 3,
      }),
      new DateField({
        label: 'PAGE.REDIRECTS.LIST.FILTERS.UPDATED_AT',
        fieldName: 'date',
        dateRange: true,
        value: this.filters.get('startDate') && this.filters.get('endDate') ?
          [new Date(this.filters.get('startDate')), new Date(this.filters.get('endDate'))] :
          undefined,
        valueChangedAction: setDates.bind(this),
        order: 4
      }),
      this.startDateField,
      this.endDateField,
      new ComboSearchField({
        fieldName: 'fromStatusCode',
        label: 'PAGE.REDIRECTS.LIST.FILTERS.FROM_STATUS_CODE',
        data: this.statusCodes,
        valuePrimitive: true,
        value: this.filters.get('fromStatusCode') ? +this.filters.get('fromStatusCode') : null,
        order: 5
      }),
      new ComboSearchField({
        fieldName: 'toStatusCode',
        label: 'PAGE.REDIRECTS.LIST.FILTERS.TO_STATUS_CODE',
        data: this.statusCodes,
        valuePrimitive: true,
        value: this.filters.get('toStatusCode') ? +this.filters.get('toStatusCode') : null,
        order: 6
      }),
      new ComboSearchField({
        fieldName: 'finalStatusCode',
        label: 'PAGE.REDIRECTS.LIST.FILTERS.FINAL_STATUS_CODE',
        data: this.statusCodes,
        valuePrimitive: true,
        value: this.filters.get('finalStatusCode') ? +this.filters.get('finalStatusCode') : null,
        order: 7
      }),
    ];
    return fields.sort((a, b) => a.order - b.order);
  }

}
