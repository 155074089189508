import {Component, Inject, OnInit, ViewEncapsulation, DoCheck} from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources';
import { RetailResource } from '@components/retail/retail.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { RetailFilters } from '@components/retail/retail.filters';
import { OrderStatusCountResource } from '@components/order-manager/resources/order-status-count.resource';
@Component({
  selector: 'app-retail-orders-list',
  template: require('./retail-orders-list.component.html'),
  styles: [require('./retail-orders-list.component.scss')],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: AbstractResource, useClass: RetailResource },
    { provide: AbstractFiltersFieldsService, useClass: RetailFilters },
    OrderStatusCountResource,
  ],
})
export class RetailOrdersListComponent extends AbstractPageComponent  implements OnInit, DoCheck {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: AbstractResource,
    private orderStatusCountResource: OrderStatusCountResource,
  ) {
    super($translate, authService, resource, state);
  }

  public  inProgressItems: any = '';
  public  terminateItems: any = '';
  public  canceledItems: any = '';
  public activeButtonIndex: number | null = null;

  ngDoCheck() {
    const storedIndex = localStorage.getItem('activeButtonIndex');
    this.activeButtonIndex = +storedIndex;
  }

  ngOnInit() {
    this.fetchRetailOrders();
  }

  fetchRetailOrders() {
   this.orderStatusCountResource.cGet({ 'marketplaces': ['allRetail'], 'orderManagerStatuses': ['canceled'] }).subscribe(
     (response: any) => {
       this.canceledItems = response;
     });

    this.orderStatusCountResource.cGet({ 'marketplaces': ['allRetail'], 'orderManagerStatuses': ['shipped' , 'delivered'] }).subscribe(
      (response: any) => {
        this.terminateItems = response;
      });

    this.orderStatusCountResource.cGet({ 'marketplaces': ['allRetail'], 'orderManagerStatuses': ['new', 'accepted' , 'preparing'] }).subscribe(
      (response: any) => {
        this.inProgressItems = response;
      });
  }

  getRetailOrdersFiltered(marketplace: string, status: any[], index: number) {
    localStorage.setItem('activeButtonIndex', index.toString());
    this.state.go(`retail.order_list`, { 'marketplaces[]': [marketplace], 'orderManagerStatuses[]': status}
    );
  }

}
