import { Resource } from '@decorators/Resource';
import { Injectable } from '@angular/core';
import { AbstractResource, IRequestOptions } from '@resources/abstract.resource';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StockMovementModel } from '@components/stock-movement/stock-movement.model';
import {Observable} from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/stock_movements',
  isHydra: true,
  listModel: StockMovementModel,
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    'order[id]': 'DESC'
  }),
})
export class StockMovementResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  cGet(params: object | null = null, options: IRequestOptions = {}, headers?: HttpHeaders): Observable<object> {
    const parameters: {[key: string]: any} = params;

    if (parameters && parameters.hasOwnProperty('startDate')) {
      Object.assign(parameters, { 'createdAt[after]': parameters['startDate'] });
      delete parameters['startDate'];
      delete parameters['date'];
    }

    if (parameters && parameters.hasOwnProperty('endDate')) {
      Object.assign(parameters, { 'createdAt[before]': parameters['endDate'] });
      delete parameters['endDate'];
      delete parameters['date'];
    }

    return super.cGet(parameters, options, headers);
  }
}
