import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';


@Injectable()
@Resource({
  entryPoint: '/v2/orders/status/count',
  isHydra: false,
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    'marketplaces[]': ['allRetail'],
  }),

})

export class OrderStatusCountResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }
}
