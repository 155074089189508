import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { ICommercialEntity } from '@interfaces';
import { IWarehouses } from '@components/warehouses/models';
import { SupplierModel } from '@components/supplier/models/supplier.model';
import {DeliveryNoteItemModel} from '@components/delivery-note/models/delivery-note-item.model';

export class DeliveryNoteModel {
  '@id': string;
   id: string;
   commercialEntity: ICommercialEntity;
   warehouse: IWarehouses;
   supplier: any;
   deliveryNoteItems: DeliveryNoteItemModel[];
   sageIdentifier?: string;
   date: Date;
   note?: string;
   containerNumber?: string;

  constructor(deliveryNote: IHydraMember) {
    this['@id'] = deliveryNote['@id'];
    this.id = deliveryNote.id;
    this.commercialEntity = deliveryNote.commercialEntity;
    this.warehouse = deliveryNote.warehouse;
    this.supplier = deliveryNote.supplier ? deliveryNote.supplier : null;
    this.deliveryNoteItems = deliveryNote.deliveryNoteItems.map((deliveryNoteItem: IHydraMember) => new DeliveryNoteItemModel(deliveryNoteItem));
    this.sageIdentifier = deliveryNote.sageIdentifier;
    this.date = deliveryNote.date;
    this.note = deliveryNote.note;
    this.containerNumber = deliveryNote.containerNumber;
  }
}
