import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import {MultiSearchField, TextField} from '@components/generic/Form/dynamic/fields';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import { Subscription } from 'rxjs/Subscription';
import { ICommercialEntity } from '@interfaces';
import { CommercialEntityResource } from '@resources';

@Injectable()
export class ContainerListFiltersService extends AbstractFiltersFieldsService {
  private commercialEntityField: DropDownListField = new DropDownListField({
    fieldName: 'commercialEntity',
    label: 'PAGE.CONTAINER.LIST.FILTER.COMMERCIAL_ENTITY',
    data: [],
    textField: 'label',
    valueField: 'id',
    value: this.filters.get('commercialEntity') ? this.filters.get('commercialEntity') : null,
    valuePrimitive: true
  });

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private commercialEntityResource: CommercialEntityResource
  ) {
    super(formNotifier, state);

    const subscriber: Subscription = this.commercialEntityResource.cGet({ 'pagination': false }, { returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((commercialEntities: ICommercialEntity[]) => {
        subscriber.unsubscribe();

        this.commercialEntityField.data = commercialEntities;
      })
    ;
  }

  public getFields(): any[] {
    const hasTracking = [
      { label: this.$translate.instant('PAGE.CONTAINER.LIST.FILTER.WITH_TRACKING'), value: '1' },
      { label: this.$translate.instant('PAGE.CONTAINER.LIST.FILTER.WITHOUT_TRACKING'), value: '0' },
    ];

    return [
      this.commercialEntityField,
      new MultiSearchField({
        fieldName: 'reference[]',
        label: 'PAGE.CONTAINER.LIST.FILTER.REFERENCE',
        data: [],
        value: this.filters.getAll('reference[]').length !== 0 ? this.filters.getAll('reference[]') : undefined,
        allowCustom: true
      }),
      new MultiSearchField({
        fieldName: 'containerNumber[]',
        label: 'PAGE.CONTAINER.LIST.FILTER.CONTAINER_NUMBER',
        data: [],
        value: this.filters.getAll('containerNumber[]').length !== 0 ? this.filters.getAll('containerNumber[]') : undefined,
        allowCustom: true
      }),
      new MultiSearchField({
        fieldName: 'sageIdentifier[]',
        label: 'PAGE.CONTAINER.LIST.FILTER.SAGE_IDENTIFIER',
        data: [],
        value: this.filters.getAll('sageIdentifier[]').length !== 0 ? this.filters.getAll('sageIdentifier[]') : undefined,
        allowCustom: true
      }),
      new TextField({
        fieldName: 'supplier.label',
        label: 'PAGE.CONTAINER.LIST.FILTER.SUPPLIER_LABEL',
        value: this.filters.getAll('supplier.label').length !== 0 ? this.filters.getAll('supplier.label') : undefined,
        valuePrimitive: true,
      }),
      new DropDownListField({
        fieldName: 'hasTracking',
        label: 'PAGE.CONTAINER.LIST.FILTER.HAS_TRACKING',
        data: hasTracking,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('hasTracking') ? this.filters.get('hasTracking') : null,
        defaultItem: { label: '', value: '' },
        valuePrimitive: true,
      }),
    ];
  }
}
