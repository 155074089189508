import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import {
  ComboSearchField,
  DateField,
  MultiSearchField,
  SkuSearchField,
  TextField
} from '@components/generic/Form/dynamic/fields';
import * as moment from 'moment';
import { CODE_LU, DATE_FULL_FORMAT } from '@constants';
import { SessionHelper } from '@helpers';
import { SuperProductResource } from '@components/super-product/super-product.resource';
import { CODE_BE, CODE_ES, CODE_FR, CODE_GB, CODE_IT, CODE_NL, CODE_PT, CODE_DE, CODE_AT, CODE_PL } from '@constants';
import {CustomerResource} from '@components';

interface IDropdownData {
  label: string;
  value: string;
}

@Injectable()
export class RetailFilters extends AbstractFiltersFieldsService {

  COUNTRIES = [
      { label: 'France', value: CODE_FR },
      { label: 'Belgique', value: CODE_BE },
      { label: 'Espagne', value: CODE_ES },
      { label: 'Belgique', value: CODE_BE },
      { label: 'Angleterre', value: CODE_GB },
      { label: 'Pays-Bas', value: CODE_NL },
      { label: 'Portugal', value: CODE_PT },
      { label: 'Italie', value: CODE_IT },
      { label: 'Allemagne', value: CODE_DE },
      { label: 'Luxembourg', value: CODE_LU },
      { label: 'Autriche', value: CODE_AT },
      { label: 'Pologne', value: CODE_PL },
    ];

  private dateStartField: TextField = new TextField({
    fieldName: 'dateStart',
    value: null,
    hidden: true
  });

  private dateEndField: TextField = new TextField({
    fieldName: 'dateEnd',
    value: null,
    hidden: true
  });

  private dateExportLogisticStartField: TextField = new TextField({
    fieldName: 'dateExportLogisticStart',
    value: null,
    hidden: true
  });

  private dateExportLogisticEndField: TextField = new TextField({
    fieldName: 'dateExportLogisticEnd',
    value: null,
    hidden: true
  });

  private dateExportSageStartField: TextField = new TextField({
    fieldName: 'dateExportSageStart',
    value: null,
    hidden: true
  });

  private dateExportSageEndField: TextField = new TextField({
    fieldName: 'dateExportSageEnd',
    value: null,
    hidden: true
  });

  private skuParentField: ComboSearchField = new ComboSearchField({
    fieldName: 'skuParent',
    label: 'PAGE.PRODUCT.LIST.FILTER.SUPER_PRODUCT.LABEL',
    data: null,
    textField: 'label',
    valueField: 'skuParent',
    value: this.filters.get('skuParent') ? this.filters.get('skuParent') : undefined,
    valuePrimitive: true,
  });

  private countryCodeField: MultiSearchField = new MultiSearchField({
    fieldName: 'countries[]',
    label: 'PAGE.ORDER.LIST.FILTER.COUNTRY.LABEL',
    data: null,
    textField: 'label',
    valueField: 'value',
    value: null,
    defaultItem: { label: this.translate.instant('PAGE.ORDER.LIST.FILTER.COUNTRY.DATA.0'), value: null },
    valuePrimitive: true,
    readonly: false,
  });

  private hipayPaymentProductField: DropDownListField = new DropDownListField({
    fieldName: 'hipayPaymentProduct',
    label: 'PAGE.ORDER.LIST.FILTER.HIPAY_PAYMENT_PRODUCT.LABEL',
    // See: https://github.com/hipay/hipay-fullservice-sdk-php/tree/2.9.2/lib/HiPay/Fullservice/PaymentConfigFiles
    data: [
      {
        value: '',
        label: '',
      },
      {
        value: 'american-express',
        label: 'American Express'
      },
      {
        value: 'bcmc',
        label: 'Bancontact / Mister Cash'
      },
      {
        value: 'cb',
        label: 'Carte Bancaire'
      },
      {
        value: 'maestro',
        label: 'Maestro'
      },
      {
        value: 'mastercard',
        label: 'MasterCard'
      },
      {
        value: 'multibanco',
        label: 'Multibanco'
      },
      {
        value: 'visa',
        label: 'Visa'
      }
    ],
    textField: 'label',
    valueField: 'value',
    value: this.filters.get('hipayPaymentProduct') ? this.filters.get('hipayPaymentProduct') : null,
    valuePrimitive: true,
    readonly: false,
    hidden: this.filters.get('paymentType') !== 'credit_card'
  });
  private collaboratorsField: DropDownListField = new DropDownListField({
    fieldName: 'collaborators',
    label: 'PAGE.ORDER.NEW.BLOCK_ADDRESS.COLLABORATOR',
    data: null,
    textField: 'label',
    valueField: 'value',
    value: this.filters.get('collaborators') ? this.filters.get('collaborators') : null,
    valuePrimitive: true,
    defaultItem: {
      label: '',
      value: '' },
  });
  private static setDates(newValue: string, startField: TextField, endField: TextField): void {
    if (!newValue || null === newValue[0]) {
      return;
    }

    startField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
    endField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
  }
  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private superProductResource: SuperProductResource,
    private customerResource: CustomerResource,
  ) {
    super(formNotifier, state);

    this.superProductResource.getSkus({ locale: SessionHelper.getLocale() })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.skuParentField.data = response;
      })
      ;
    this.customerResource.getCollaborators()
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.collaboratorsField.data = response;
        this.collaboratorsField.data.unshift(
          {label: this.translate.instant('PAGE.ORDER.LIST.FILTER.COLLABORATORS.ALL'),
            value: response.map((collaborator: any) => collaborator.value).join(',')}
        );
      });
  }

  public getFields(): AbstractFormFieldBase<any>[] {
    const indicatorFilterOptions = [
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.CUSTOMER_DATA'), value: 'customerData' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.OVERSOLD'), value: 'oversold' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.WAREHOUSE_PREPARATION'), value: 'warehousePreparation' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.DUPLICATE'), value: 'duplicate' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.LOGISTIC_SUPPLY'), value: 'logisticSupply' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.RED_MARGIN'), value: 'redMargin' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.ORANGE_MARGIN'), value: 'orangeMargin' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.MARKETPLACE_SYNCHRONIZATION'), value: 'marketplaceSynchronization' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.WAITING_PAYMENTS'), value: 'waitingPayments' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.SAGE_EXPORT_IN_PROGRESS'), value: 'sageExporting' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.SHIPPING_DELAY'), value: 'shippingDelay' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.WOOP'), value: 'woop' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.MARKETPLACE_STOCK_STATUS'), value: 'marketplaceStockStatus' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.FRAUD'), value: 'fraud' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.SUPPLY_OPTIMIZATION'), value: 'supplyOptimization' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.WAITING_CANCELLATION'), value: 'waitingCancellations' }
    ];

    const countriesList: IDropdownData[] = [...this.COUNTRIES];
    this.countryCodeField.value = 0 < this.filters.getAll('countries[]').length ? this.filters.getAll('countries[]') : undefined,
    this.countryCodeField.data = countriesList;

    return [
      new TextField({
        fieldName: 'search',
        label: '',
        icon: 'fa fa-search',
        placeholder: 'PAGE.ORDER_MANAGER.LIST.FILTER.SEARCH',
        value: this.filters.get('search') ? this.filters.get('search') : undefined,
      }),
    ];
  }

  getComplementaryFields(): AbstractFormFieldBase<any>[] {
    const ordersTypeOptions = [
      { label: this.translate.instant('PAGE.ORDER.LIST.FILTER.ORDERS_TYPE.SAV'), value: 'sav' },
      { label: this.translate.instant('PAGE.ORDER.LIST.FILTER.ORDERS_TYPE.NO_SAV'), value: 'nosav' },
      { label: this.translate.instant('PAGE.ORDER.LIST.FILTER.ORDERS_TYPE.UNPAIRED'), value: 'unpaired' },
      { label: this.translate.instant('PAGE.ORDER.LIST.FILTER.ORDERS_TYPE.REPLACEMENT'), value: 'replacement' },
      { label: this.translate.instant('PAGE.ORDER.LIST.FILTER.ORDERS_TYPE.SECOND_HAND'), value: 'second_hand' },
    ];

    const hasCommentOptions = [
      { label: this.translate.instant('PAGE.ORDER.LIST.FILTER.COMMENTS.DATA.1'), value: '1' },
      { label: this.translate.instant('PAGE.ORDER.LIST.FILTER.COMMENTS.DATA.2'), value: '0' },
    ];

    const paymentTypesOptions = [
      { label: '', value: '' },
      ...SessionHelper.getCountry().paymentTypes.map((key) => {
        return { label: this.translate.instant(`DATA.PAYMENT_TYPES.${key.toUpperCase()}`), value: key };
      })
    ];

    if (paymentTypesOptions.filter(el => el.value === 'klarna').length <= 0) {
      paymentTypesOptions.push({label: this.translate.instant('DATA.PAYMENT_TYPES.KLARNA'), value: 'klarna'});
    }

    const dateStart = this.filters.get('dateStart');
    const dateEnd = this.filters.get('dateEnd');

    if (dateStart && dateEnd) {
      this.dateStartField.value = dateStart;
      this.dateEndField.value = dateEnd;
    }

    const dateExportLogisticStart = this.filters.get('dateExportLogisticStart');
    const dateExportLogisticEnd = this.filters.get('dateExportLogisticEnd');

    if (dateExportLogisticStart && dateExportLogisticEnd) {
      this.dateExportLogisticStartField.value = dateExportLogisticStart;
      this.dateExportLogisticEndField.value = dateExportLogisticEnd;
    }

    const dateExportSageStart = this.filters.get('dateExportSageStart');
    const dateExportSageEnd = this.filters.get('dateExportSageEnd');

    if (dateExportSageStart && dateExportSageEnd) {
      this.dateExportSageStartField.value = dateExportSageStart;
      this.dateExportSageEndField.value = dateExportSageEnd;
    }

    const indicatorStatusesOptions = [
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.INDICATOR_STATUS.NONE'), value: 'none' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.INDICATOR_STATUS.RED'), value: 'red' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.INDICATOR_STATUS.ORANGE'), value: 'orange' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.INDICATOR_STATUS.GREEN'), value: 'green' },
    ];

    const booleanOptions = [
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.WITH'), value: '1' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.WITHOUT'), value: '0' },
    ];

    const yesNoOptions = [
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.YES'), value: '1' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.NO'), value: '0' },
    ];

    return [
      new MultiSearchField({
        fieldName: 'marketplaces[]',
        label: 'PAGE.ORDER.LIST.FILTER.MARKETPLACES.LABEL',
        data: SessionHelper.getAllMarketplaces(),
        textField: 'commercialName',
        valueField: 'code',
        value: 0 < this.filters.getAll('marketplaces[]').length ? this.filters.getAll('marketplaces[]') : undefined,
      }),

      new DateField({
        label: 'PAGE.ORDER.LIST.FILTER.ORDER_DATE.LABEL',
        fieldName: 'date',
        dateRange: true,
        value: dateStart && dateEnd ? [new Date(dateStart), new Date(dateEnd)] : undefined,
        valueChangedAction: this.setDateValue.bind(this),
      }),

      this.dateStartField,
      this.dateEndField,

      new MultiSearchField({
        fieldName: 'orderManagerStatuses[]',
        label: 'PAGE.ORDER.LIST.FILTER.STATUSES.LABEL',
        data: SessionHelper.getOrderManagerStatuses().map((status) => {
          return {
            label: this.translate.instant(`DATA.ORDER_MANAGER_STATUS.${status.toUpperCase()}`),
            value: status,
          };
        }),
        textField: 'label',
        valueField: 'value',
        value: 0 < this.filters.getAll('orderManagerStatuses[]').length ? this.filters.getAll('orderManagerStatuses[]') : undefined,
        valuePrimitive: true,
      }),

      new DropDownListField({
        fieldName: 'paymentType',
        label: 'PAGE.ORDER.LIST.FILTER.PAYMENT_TYPE.LABEL',
        data: paymentTypesOptions,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('paymentType') ? this.filters.get('paymentType') : null,
        valuePrimitive: true,
        readonly: false,
        valueChangedAction: this.showHipayPaymentProduct.bind(this)
      }),
      this.hipayPaymentProductField,

      new TextField({
        fieldName: 'postcode',
        label: 'PAGE.ORDER.LIST.FILTER.POSTAL_CODE.LABEL',
        value: this.filters.get('postcode') ? this.filters.get('postcode') : null,
        readonly: false,
      }),

      new MultiSearchField({
        fieldName: 'carriers[]',
        label: 'PAGE.ORDER.LIST.FILTER.CARRIERS.LABEL',
        data: SessionHelper.getAllCarrierGroups(),
        textField: 'name',
        valueField: 'code',
        value: 0 < this.filters.getAll('carriers[]').length ? this.filters.getAll('carriers[]') : undefined,
        valuePrimitive: true,
      }),

      new MultiSearchField({
        fieldName: 'warehouses[]',
        label: 'PAGE.ORDER.LIST.FILTER.WAREHOUSES.LABEL',
        data: SessionHelper.getAllWarehouses(),
        textField: 'name',
        valueField: 'code',
        value: 0 < this.filters.getAll('warehouses[]').length ? this.filters.getAll('warehouses[]') : undefined,
        valuePrimitive: true,
      }),

      new DropDownListField({
        fieldName: 'hasRefund',
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.REFUND',
        data: booleanOptions,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('hasRefund') ? this.filters.get('hasRefund') : null,
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),

      this.skuParentField,

      new SkuSearchField({
        fieldName: 'skus[]',
        label: 'PAGE.ORDER.LIST.FILTER.SKU.LABEL',
        productType: 'product',
        value: 0 < this.filters.getAll('skus[]').length ? this.filters.getAll('skus[]') : undefined,
        valueField: 'sku',
        valuePrimitive: true
      }),

      new SkuSearchField({
        fieldName: 'spareparts[]',
        label: 'PAGE.ORDER.LIST.FILTER.SPAREPARTS.LABEL',
        productType: 'product',
        value: 0 < this.filters.getAll('spareparts[]').length ? this.filters.getAll('spareparts[]') : undefined,
        valueField: 'sku',
        valuePrimitive: true,
        isSparePart: true
      }),

      new DropDownListField({
        fieldName: 'hasComment',
        label: 'PAGE.ORDER.LIST.FILTER.COMMENTS.LABEL',
        data: hasCommentOptions,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('hasComment') ? this.filters.get('hasComment') : null,
        defaultItem: { label: this.translate.instant('PAGE.ORDER.LIST.FILTER.COMMENTS.DATA.0'), value: '' },
        valuePrimitive: true,
      }),

      new DropDownListField({
        fieldName: 'indicatorStatuses[deliverable]',
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.DELIVERABLE',
        data: indicatorStatusesOptions,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('indicatorStatuses[deliverable]') ? this.filters.get('indicatorStatuses[deliverable]') : null,
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),

      new DateField({
        label: 'PAGE.ORDER.LIST.FILTER.DATE_EXPORT_LOGISTIC.LABEL',
        fieldName: 'dateExportLogistic',
        dateRange: true,
        value: dateExportLogisticStart && dateExportLogisticEnd
          ? [new Date(dateExportLogisticStart), new Date(dateExportLogisticEnd)]
          : undefined,
        valueChangedAction: this.setDateExportLogisticValue.bind(this),
      }),
      this.dateExportLogisticStartField,
      this.dateExportLogisticEndField,

      new DateField({
        label: 'PAGE.ORDER.LIST.FILTER.DATE_EXPORT_SAGE.LABEL',
        fieldName: 'dateExportSage',
        dateRange: true,
        value: dateExportSageStart && dateExportSageEnd
          ? [new Date(dateExportSageStart), new Date(dateExportSageEnd)]
          : undefined,
        valueChangedAction: this.setDateExportSageValue.bind(this),
      }),
      this.dateExportSageStartField,
      this.dateExportSageEndField,


      new DropDownListField({
        fieldName: 'isSageExported',
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.SAGE',
        data: yesNoOptions,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('isSageExported') ? this.filters.get('isSageExported') : null,
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),

      new TextField({
        fieldName: 'marginMin',
        label: 'PAGE.ORDER.LIST.FILTER.MARGIN_PERCENTAGE.MIN',
        value: this.filters.get('marginMin') ? this.filters.get('marginMin') : null,
        readonly: false,
      }),

      new TextField({
        fieldName: 'marginMax',
        label: 'PAGE.ORDER.LIST.FILTER.MARGIN_PERCENTAGE.MAX',
        value: this.filters.get('marginMax') ? this.filters.get('marginMax') : null,
        readonly: false,
      }),

      new DropDownListField({
        fieldName: 'ordersType',
        label: 'PAGE.ORDER.LIST.FILTER.ORDERS_TYPE.LABEL',
        data: ordersTypeOptions,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('ordersType') ? this.filters.get('ordersType') : null,
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),
      this.collaboratorsField,
      this.countryCodeField
    ];
  }

  private setDateValue(newValue: string): void {
    RetailFilters.setDates(newValue, this.dateStartField, this.dateEndField);
  }

  private setDateExportLogisticValue(newValue: string, startField: TextField, endField: TextField): void {
    RetailFilters.setDates(newValue, this.dateExportLogisticStartField, this.dateExportLogisticEndField);
  }

  private setDateExportSageValue(newValue: string, startField: TextField, endField: TextField): void {
    RetailFilters.setDates(newValue, this.dateExportSageStartField, this.dateExportSageEndField);
  }

  private showHipayPaymentProduct(value: string): void {
    const show = value === 'credit_card';
    const paymentTypeField = this.getComplementaryFields().find((e: any) => e.fieldName === 'paymentType');
    this.hipayPaymentProductField.shouldBeDisplayed = paymentTypeField.shouldBeDisplayed && show;
    this.hipayPaymentProductField.hidden = !show;
  }
}
