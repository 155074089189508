import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import {StockMovementResource} from '@resources';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { StockMovementListFiltersService } from './stock-movement-list-filters.service';

@Component({
  selector: 'app-stock-movement-list',
  template: require('./stock-movement-list.component.html'),
  styles: [require('./stock-movement-list.component.scss')],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: AbstractResource, useClass: StockMovementResource },
    { provide: AbstractFiltersFieldsService, useClass: StockMovementListFiltersService },
  ],
})
export class StockMovementListComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public add(): void {
    this.state.go('stock-movement.new');
  }
}
